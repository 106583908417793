<template>
  <v-container>
    <v-form
        ref="formConfirm"
        v-model="valid"
        lazy-validation
    >
      <!-- 検索エリア -->
      <div>
        <v-row class="mt-2">
          <v-col cols="6">
            <h2>{{ surveyType.filter((e) => (e.value == targetSurveyType))[0].text }} 回答データチェック</h2>
          </v-col>
          <v-spacer></v-spacer>
          <!-- <v-col v-if="attribute == 2">
            <v-btn-toggle
              v-model="is12City"
              color="primary"
              group
              outlined
            >
              <v-btn :value="true" width="150" outlined>12都市</v-btn>
              <v-btn :value="false" width="150">府県別</v-btn>
            </v-btn-toggle>
          </v-col> -->
          <!-- <v-col v-if="attribute == 2" cols="auto" class="pr-0">
            <v-btn class="" :color="is12City ? 'primary' : 'info_hover'" width="150" :disabled="loading || editMode" @click="selectTab12City()">12都市</v-btn>
          </v-col>
          <v-col v-if="attribute == 2" cols="3" sm="3" md="2" lg="2">
            <v-btn class="" :color="!is12City ? 'primary' : 'info_hover'" width="150" :disabled="loading || editMode" @click="selectTabPrefSum()">府県別</v-btn>
          </v-col> -->
        </v-row>
        <v-row>
          <v-col cols="3" sm="3" md="3" lg="2">
            <DatePicker
              label="対象年月" 
              v-model="searchCondition.targetMonth" 
              format="YYYY年MM月" 
              selectType="month" 
              :clearable="false" 
              suffix="分" 
              :changeEvt="search" 
              :readonly="loading" 
              :disabled="editMode"
              :rules="[checkDateLower(cityDataCheckDate, searchCondition.targetMonth)]"
            ></DatePicker>
          </v-col>
          <v-col v-if="attribute == 2">
            <v-btn-toggle
              v-model="is12City"
              color="primary"
              group
              dense
            >
              <v-btn :value="true" width="150" class="ma-0 pa-0" :disabled="isCheckDate">12都市</v-btn>
              <v-btn :value="false" width="150" class="ma-0 pa-0" :disabled="isCheckDate">府県別</v-btn>
            </v-btn-toggle>
          </v-col>
          <v-spacer></v-spacer>
          <!-- <v-col v-if="attribute == 2" cols="2" sm="2" md="2" lg="2">
            <v-radio-group 
              v-model="dbAll"
              dense
              row
              :readonly="false"
              :rules="[]"
              :loading="loading"
              class="mt-0 pt-0"
            >
              <v-radio
                label="単月"
                :value="0"
              ></v-radio>
              <v-radio
                label="全月"
                :value="1"
              ></v-radio>
            </v-radio-group>
          </v-col> -->
          <v-col v-if="attribute == 1" cols="3" sm="3" md="2" lg="2">
            <v-btn v-if="existsSum" class="" color="secondary" width="150" :disabled="loading || editMode || isCheckDate" @click="goToSummary()">再集計</v-btn>
            <v-btn v-else class="" color="primary" width="150" :disabled="loading || editMode || isCheckDate" @click="goToSummary()">集計</v-btn>
          </v-col>
          <v-col v-if="(attribute == 2) && (is12City)" cols="3" sm="3" md="2" lg="2">
            <v-btn class="" color="primary" width="150" :disabled="loading || editMode || isCheckDate" @click="get12cityDB()"><v-icon :small="true">mdi-download</v-icon>12都市集計</v-btn>
          </v-col>
          <v-col v-if="(attribute == 2) && (!is12City)" cols="3" sm="3" md="2" lg="2">
            <v-btn class="" color="primary" width="150" :disabled="loading || editMode || isCheckDate" @click="getPrefSum()"><v-icon :small="true">mdi-download</v-icon>府県別集計</v-btn>
          </v-col>
        </v-row>
      </div>

      <!-- 回答一覧(事業所/12都市) -->
      <div v-if="!isCheckDate">
        <v-row class="mb-n7">
          <v-col v-if="attribute == 1" cols="12" sm="12" md="12" lg="12">
            <input type='checkbox' id="select-all-checkbox" v-model="selectAll" /><label for="select-all-checkbox">回答済みのみを選択</label>
          </v-col>
          <v-col v-else cols="12" sm="12" md="12" lg="12">
            <input type='checkbox' id="select-all-checkbox" v-model="selectAll" /><label for="select-all-checkbox">すべて選択</label>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-data-table
              :headers="comHeaders"
              :items="answerList"
              :loading="loading"
              :dense='true'
              :items-per-page="10"
              :mobile-breakpoint="0"
              :disable-pagination='true'
              :hide-default-footer='true'
              :fixed-header='true'
              disable-sort
              locale="ja-jp"
              loading-text="読込中"
              no-data-text="データがありません。"
              class="table-border hover-effect"
              height="160"
            >
              <!-- 事業所 -->
              <template v-if="attribute == 1" v-slot:item="{ item, index }">
                <tr :key="index" @click="selectRows(item.memberNo, index)" :class="[selected12City == index ? 'selected-color' : '']">
                  <td class="center">
                    <input type='checkbox' v-model="innerSelectedList" :value="item.memberNo" @click.stop="changeSelected" />
                  </td>
                  <td class="center">
                    {{(index+1)}}
                  </td>
                  <td class="center">
                    {{item.memberNo}}
                  </td>
                  <td class="left">
                    {{item.companyId}}
                  </td>
                  <td class="left">
                    {{item.companyName}}
                  </td>
                  <td class="left">
                    {{item.officeName}}
                  </td>
                  <td class="left">
                    {{item.prefName}}
                  </td>
                  <td class="left">
                    {{item.address}}
                  </td>
                  <td class="right">
                    {{Number(item.cVolume).toLocaleString()}}
                  </td>
                  <td class="center">
                    {{item.status == 0 ? '未回答' : item.status == 1 ? '一時保存中' : item.status == 3 ? '回答済み' : ''}}
                  </td>
                </tr>
              </template>
              <!-- 12都市 -->
              <template v-else-if="is12City" v-slot:item="{ item, index }">
                <tr :key="index" @click="selectRows(item.cityCode, index)" :class="[selected12City == index ? 'selected-color' : '']">
                  <td class="center">
                    <input type='checkbox' v-model="innerSelectedList" :value="item.cityCode" @click.stop="changeSelected" />
                  </td>
                  <td class="center">
                    {{(index+1)}}
                  </td>
                  <td class="center">
                    {{item.cityCode}}
                  </td>
                  <td class="left">
                    {{item.cityName}}
                  </td>
                  <td class="left">
                    {{item.name}}
                  </td>
                  <td class="left">
                    {{item.officeName}}
                  </td>
                  <td class="right">
                    {{Number(item.cVolume).toLocaleString()}}
                  </td>
                  <td class="center">
                    {{item.status == 0 ? '未集計' : '集計済み'}}
                  </td>
                </tr>
              </template>
              <!-- 府県別 -->
              <template v-else v-slot:item="{ item, index }">
                <tr :key="index" @click="selectRows(item.prefSumCd, index)" :class="[selected12City == index ? 'selected-color' : '']">
                  <td class="center">
                    <input type='checkbox' v-model="innerSelectedList" :value="item.prefSumCd" @click.stop="changeSelected" />
                  </td>
                  <td class="center">
                    {{(index+1)}}
                  </td>
                  <td class="center">
                    {{item.prefSumCd}}
                  </td>
                  <td class="left">
                    {{item.prefName}}
                  </td>
                  <td class="left">
                    {{item.block}}
                  </td>
                  <td class="left">
                    {{item.officeName}}
                  </td>
                  <td class="right">
                    {{Number(item.cVolume).toLocaleString()}}
                  </td>
                  <td class="center">
                    {{item.status == 0 ? '未集計' : '集計済み'}}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </div>

      <!-- データチェック -->
      <div v-if="loadingData && !isCheckDate">
        <v-progress-linear :active="loadingData" indeterminate></v-progress-linear>
      </div>
      <div v-if="!loading && !loadingData && !isCheckDate" class="mt-2">
        <h3 class="mb-5">データチェック</h3>
        <v-row>
          <v-col cols="3" sm="3" md="2" lg="2">
            <v-text-field
              v-if="attribute == 1 || ((attribute == 2) && (!is12City))"
              label="都道府県"
              v-model="memberData.prefName"
              dense
              :readonly="true"
              :rules="[]"
            ></v-text-field>
            <v-text-field
              v-else
              label="都道府県"
              v-model="memberData.cityName"
              dense
              :readonly="true"
              :rules="[]"
            ></v-text-field>
          </v-col>
          <v-col v-if="attribute == 1" cols="3" sm="3" md="2" lg="2">
            <v-text-field
              label="氏名又は名称"
              v-model="answerData.companyName"
              dense
              :readonly="true"
              :rules="[]"
            ></v-text-field>
          </v-col>
          <v-col v-if="attribute == 1" cols="3" sm="3" md="2" lg="2">
            <v-text-field
              label="営業所の名称"
              v-model="answerData.officeName"
              dense
              :readonly="true"
              :rules="[]"
            ></v-text-field>
          </v-col>
          <v-col v-if="attribute == 2" cols="3" sm="3" md="2" lg="2">
            <v-text-field
              label="団体名"
              v-model="answerData.officeName"
              dense
              :readonly="true"
              :rules="[]"
            ></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
          <v-col v-if="editMode" cols="3" sm="3" md="2" lg="2">
            <v-btn class="" color="primary" width="150" :disabled="loading" @click="cancel()">キャンセル</v-btn>
          </v-col>
          <v-col v-if="editMode" cols="3" sm="3" md="2" lg="2">
            <v-btn class="" color="primary" width="150" :disabled="loading" @click="registData()"><v-icon :small="true">mdi-check-bold</v-icon>回答データ保存</v-btn>
          </v-col>
          <v-col v-else cols="3" sm="3" md="2" lg="2">
            <v-btn class="" color="primary" width="150" :disabled="loading" @click="editData()"><v-icon :small="true">mdi-border-color</v-icon>回答データ編集</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2" sm="2" md="2" lg="2">
            <NumberTextField
              label="在貨容積確認"
              :value="chkVolume"
              dense
              :readonly="true"
              :rules="[]"
              background-color='input'
              :propClass="[chkVolume < 0 ? 'input-color-red' : '']"
              suffix="㎥"
            ></NumberTextField>
          </v-col>
          <v-col cols="2" sm="2" md="2" lg="2">
            <NumberTextField
              :value="Number(chkVolumeDiff.toFixed(1))"
              dense
              :readonly="true"
              :rules="[]"
              background-color='input'
              :propClass="[Number(chkVolumeDiff.toFixed(1)) < 0 ? 'input-color-red' : '']"
              prefix="("
              suffix=")"
            ></NumberTextField>
          </v-col>
          <v-col cols="2" sm="2" md="2" lg="2">
            <NumberTextField
              label="受寄物在貨容積"
              :value="chkVolumeReceipt"
              dense
              :readonly="true"
              :rules="[]"
              background-color='input'
              :propClass="[chkVolumeReceipt < 0 ? 'input-color-red' : '']"
            ></NumberTextField>
          </v-col>
          <v-col cols="2" sm="2" md="2" lg="2">
            <NumberTextField
              :value="Number(chkVolumeReceiptDiff.toFixed(1))"
              dense
              :readonly="true"
              :rules="[]"
              background-color='input'
              :propClass="[Number(chkVolumeReceiptDiff.toFixed(1)) < 0 ? 'input-color-red' : '']"
              prefix="(差異"
              suffix=")"
            ></NumberTextField>
          </v-col>
          <v-col cols="2" sm="2" md="2" lg="2">
            <NumberTextField
              label="在庫トン数確認"
              :value="chkStock"
              dense
              :readonly="true"
              :rules="[]"
              background-color='input'
              :propClass="[chkStock < 0 ? 'input-color-red' : '']"
            ></NumberTextField>
          </v-col>
          <v-col cols="2" sm="2" md="2" lg="2">
            <NumberTextField
              :value="Number(chkStockDiff.toFixed(1))"
              dense
              :readonly="true"
              :rules="[]"
              background-color='input'
              :propClass="[Number(chkStockDiff.toFixed(1)) < 0 ? 'input-color-red' : '']"
              prefix="("
              suffix=")"
            ></NumberTextField>
          </v-col>
        </v-row>
        <div class="table-wrap d-flex">
          <div class="table-inner">
            <v-row class="justify-space-between" style="width: 100%">
              <v-col cols="4" class="ml-5">
                <h4>8号様式 前月増減</h4>
              </v-col>
            </v-row>
            <v-data-table 
              :dense='true'
              :items-per-page="10"
              :mobile-breakpoint="0"
              :disable-pagination='true'
              :hide-default-header='true'
              :hide-default-footer='true'
              :fixed-header='false'
              :disable-sort='false'
              locale="ja-jp"
              loading-text="読込中"
              no-data-text="データがありません。"
              class="table-border no-hover-effect no8-diff-table"
            >
              <template v-slot:body="{}">
                <thead>
                  <tr>
                    <th class="text-center" rowspan="2" ></th>
                    <th class="text-center" rowspan="2" >所管容積</th>
                    <th class="text-center" colspan="3" style="width: 420px">使用状況</th>
                    <th class="text-center" rowspan="2" >備考</th>
                  </tr>
                  <tr>
                    <th class="text-center" >受寄物<br>在貨容積</th>
                    <th class="text-center" >自家貨物<br>在貨容積</th>
                    <th class="text-center" >空容積</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td rowspan="4" class="text-center" >冷蔵倉庫</td>
                    <td class="right no-border-bottom" >m3</td>
                    <td class="right no-border-bottom" >m3</td>
                    <td class="right no-border-bottom" >m3</td>
                    <td class="right no-border-bottom" >m3</td>
                    <td class="text-center" >製氷トン</td>
                  </tr>
                  <tr>
                    <td class="no-border-top no-border-bottom" ><NumberTextField :innerTable="true" :value="calcVolumeTotalDiff" background-color='input' :propClass="[calcVolumeTotalDiff < 0 ? 'input-color-red' : '']" :readonly="true" ></NumberTextField></td>
                    <td class="no-border-top" ><NumberTextField :innerTable="true" :value="calcVolumeReceiptDiff" background-color='input' :propClass="[calcVolumeReceiptDiff < 0 ? 'input-color-red' : '']" :readonly="true" ></NumberTextField></td>
                    <td class="no-border-top" ><NumberTextField :innerTable="true" :value="calcVolumePrivateDiff" background-color='input' :propClass="[calcVolumePrivateDiff < 0 ? 'input-color-red' : '']" :readonly="true" ></NumberTextField></td>
                    <td class="no-border-top" ><NumberTextField :innerTable="true" :value="calcVolumeAvailableDiff" background-color='input' :propClass="[calcVolumeAvailableDiff < 0 ? 'input-color-red' : '']" :readonly="true" ></NumberTextField></td>
                    <td ></td>
                  </tr>
                  <tr>
                    <td rowspan="2" class="no-border-top" ></td>
                    <td rowspan="2" class="text-center">内容積建</td>
                    <td class="right no-border-bottom" >m3</td>
                    <td rowspan="2" ></td>
                    <td class="text-center" >凍結トン</td>
                  </tr>
                  <tr>
                    <td class="no-border-top" ><NumberTextField :innerTable="true" :value="calcVolumeMeasureDiff" background-color='input' :rules="[setDetailMessage]" :propClass="['detail-info', calcVolumeMeasureDiff < 0 ? 'input-color-red' : '']" :readonly="true"></NumberTextField></td>
                    <td ></td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </div>
        </div>
      </div>

    </v-form>

    <!-- 編集フォーム -->
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <!-- 8号様式（旧7号様式） -->
      <div v-if="!loading && !loadingData && !isCheckDate" class="mt-2">
        <h3>8号様式（旧7号様式）</h3>
        <div class="table-wrap d-flex">
          <div class="table-inner">
            <v-row class="justify-space-between" style="width: 100%">
              <v-col cols="2" class="ml-5">
                <h4>当月データ</h4>
              </v-col>
              <v-col v-if="attribute == 2" cols="4">
                <NumberTextField
                  v-model="answerData.targetCnt"
                  dense
                  :readonly="!editMode"
                  :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                  propClass="centered-input"
                  prefix="事業所数"
                  suffix="事業所"
                ></NumberTextField>
              </v-col>
            </v-row>
            <v-data-table 
              :dense='true'
              :items-per-page="10"
              :mobile-breakpoint="0"
              :disable-pagination='true'
              :hide-default-header='true'
              :hide-default-footer='true'
              :fixed-header='false'
              :disable-sort='false'
              locale="ja-jp"
              loading-text="読込中"
              no-data-text="データがありません。"
              class="table-border no-hover-effect no8-table"
            >
              <template v-slot:body="{}">
                <thead>
                  <tr>
                    <th class="text-center" rowspan="2" ></th>
                    <th class="text-center" rowspan="2" >所管容積</th>
                    <th class="text-center" colspan="3" style="width: 420px">使用状況</th>
                    <th class="text-center" rowspan="2" >備考</th>
                  </tr>
                  <tr>
                    <th class="text-center" >受寄物<br>在貨容積</th>
                    <th class="text-center" >自家貨物<br>在貨容積</th>
                    <th class="text-center" >空容積</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td rowspan="4" class="text-center" >冷蔵倉庫</td>
                    <td class="right no-border-bottom" >m3</td>
                    <td class="right no-border-bottom" >m3</td>
                    <td class="right no-border-bottom" >m3</td>
                    <td class="right no-border-bottom" >m3</td>
                    <td class="text-center" >製氷トン</td>
                  </tr>
                  <tr>
                    <td class="no-border-top no-border-bottom" >
                      <NumberTextField 
                        :innerTable="true" 
                        v-model.number="answerDataNo8.volumeTotal" 
                        :readonly="!editMode"
                        :rules="[required, maxValLocaleJP(9999999), minValLocaleJP(0)]"
                        :orgRoundStep="1" 
                      ></NumberTextField>
                    </td>
                    <td class="no-border-top" >
                      <NumberTextField 
                        :innerTable="true" 
                        v-model.number="answer8VolumeReceiptCalc" 
                        :readonly="true"
                        :changeEvt="updateValue"
                      ></NumberTextField>
                    </td>
                    <td class="no-border-top" >
                      <NumberTextField 
                        :innerTable="true" 
                        v-model.number="answerDataNo8.volumePrivate" 
                        :readonly="!editMode"
                        :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                        :orgRoundStep="1" 
                      ></NumberTextField>
                    </td>
                    <td class="no-border-top" >
                      <NumberTextField 
                        :innerTable="true" 
                        v-model.number="answer8VolumeAvailableCalc" 
                        :readonly="true"
                        :changeEvt="updateValue"
                      ></NumberTextField>
                      <span v-if="!chkValueIsMin(answer8VolumeAvailableCalc, 0)" class="yellow text-caption">0よりも大きい値を設定してください</span>
                    </td>
                    <td >
                      <NumberTextField 
                        :innerTable="true" 
                        v-model.number="answerDataNo8.weightIce" 
                        :readonly="!editMode"
                        :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                        :orgRoundStep="1" 
                      ></NumberTextField>
                    </td>
                  </tr>
                  <tr>
                    <td rowspan="2" class="no-border-top" ></td>
                    <td rowspan="2" class="text-center">内容積建</td>
                    <td class="right no-border-bottom" >m3</td>
                    <td rowspan="2" ></td>
                    <td class="text-center" >凍結トン</td>
                  </tr>
                  <tr>
                    <td class="no-border-top" >
                      <NumberTextField 
                        :innerTable="true" 
                        v-model.number="answerDataNo8.volumeMeasure" 
                        :readonly="!editMode"
                        :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                        :orgRoundStep="1" 
                      ></NumberTextField>
                    </td>
                    <td >
                      <NumberTextField 
                        :innerTable="true" 
                        v-model.number="answerDataNo8.weightFrozen" 
                        :readonly="!editMode"
                        :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                        :orgRoundStep="1" 
                      ></NumberTextField>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </div>
          <div class="table-inner">
            <v-row class="justify-space-between" style="width: 100%">
              <v-col cols="2" class="ml-5">
                <h4>前月データ</h4>
              </v-col>
              <v-col v-if="attribute == 2" cols="4">
                <NumberTextField
                  v-model="prevAnswerData.targetCnt"
                  dense
                  :readonly="!editMode"
                  :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                  propClass="centered-input"
                  prefix="事業所数"
                  suffix="事業所"
                ></NumberTextField>
              </v-col>
            </v-row>
            <v-data-table 
              :dense='true'
              :items-per-page="10"
              :mobile-breakpoint="0"
              :disable-pagination='true'
              :hide-default-header='true'
              :hide-default-footer='true'
              :fixed-header='false'
              :disable-sort='false'
              locale="ja-jp"
              loading-text="読込中"
              no-data-text="データがありません。"
              class="table-border no-hover-effect no8-table"
            >
              <template v-slot:body="{}">
                <thead>
                  <tr>
                    <th class="text-center" rowspan="2" ></th>
                    <th class="text-center" rowspan="2" >所管容積</th>
                    <th class="text-center" colspan="3" style="width: 420px">使用状況</th>
                    <th class="text-center" rowspan="2" >備考</th>
                  </tr>
                  <tr>
                    <th class="text-center" >受寄物<br>在貨容積</th>
                    <th class="text-center" >自家貨物<br>在貨容積</th>
                    <th class="text-center" >空容積</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td rowspan="4" class="text-center" >冷蔵倉庫</td>
                    <td class="right no-border-bottom" >m3</td>
                    <td class="right no-border-bottom" >m3</td>
                    <td class="right no-border-bottom" >m3</td>
                    <td class="right no-border-bottom" >m3</td>
                    <td class="text-center" >製氷トン</td>
                  </tr>
                  <tr>
                    <td class="no-border-top no-border-bottom" >
                      <NumberTextField 
                        :innerTable="true" 
                        v-model.number="prevAnswerDataNo8.volumeTotal" 
                        :readonly="!editMode"
                        :rules="[required, maxValLocaleJP(9999999), minValLocaleJP(0)]"
                        :orgRoundStep="1" 
                      ></NumberTextField>
                    </td>
                    <td class="no-border-top" >
                      <NumberTextField 
                        :innerTable="true" 
                        v-model.number="prevAnswer8VolumeReceiptCalc" 
                        :readonly="true"
                        :changeEvt="updateValue"
                      ></NumberTextField>
                    </td>
                    <td class="no-border-top" >
                      <NumberTextField 
                        :innerTable="true" 
                        v-model.number="prevAnswerDataNo8.volumePrivate" 
                        :readonly="!editMode"
                        :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                        :orgRoundStep="1" 
                      ></NumberTextField>
                    </td>
                    <td class="no-border-top" >
                      <NumberTextField 
                        :innerTable="true" 
                        v-model.number="prevAnswer8VolumeAvailableCalc" 
                        :readonly="true"
                        :changeEvt="updateValue"
                      ></NumberTextField>
                      <span v-if="!chkValueIsMin(prevAnswer8VolumeAvailableCalc, 0)" class="yellow text-caption">0よりも大きい値を設定してください</span>
                    </td>
                    <td >
                      <NumberTextField 
                        :innerTable="true" 
                        v-model.number="prevAnswerDataNo8.weightIce" 
                        :readonly="!editMode"
                        :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                        :orgRoundStep="1" 
                      ></NumberTextField>
                    </td>
                  </tr>
                  <tr>
                    <td rowspan="2" class="no-border-top" ></td>
                    <td rowspan="2" class="text-center">内容積建</td>
                    <td class="right no-border-bottom" >m3</td>
                    <td rowspan="2" ></td>
                    <td class="text-center" >凍結トン</td>
                  </tr>
                  <tr>
                    <td class="no-border-top" >
                      <NumberTextField 
                        :innerTable="true" 
                        v-model.number="prevAnswerDataNo8.volumeMeasure" 
                        :readonly="!editMode"
                        :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                        :orgRoundStep="1" 
                      ></NumberTextField>
                    </td>
                    <td >
                      <NumberTextField 
                        :innerTable="true" 
                        v-model.number="prevAnswerDataNo8.weightFrozen" 
                        :readonly="!editMode"
                        :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                        :orgRoundStep="1" 
                      ></NumberTextField>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </div>
        </div>
      </div>

      <!-- 9号様式（旧8号様式） -->
      <div v-if="!loading && !loadingData && !isCheckDate" class="mt-2">
        <h3>9号様式（旧8号様式）</h3>
        <div class="table-wrap d-flex">
          <div class="table-inner">
            <v-row class="justify-space-between" style="width: 100%">
              <v-col cols="2" class="ml-5">
                <h4>当月データ</h4>
              </v-col>
              <v-col v-if="attribute == 2" cols="4">
                <NumberTextField
                  v-model="answerData.targetCnt"
                  dense
                  :readonly="true"
                  :rules="[]"
                  propClass="centered-input"
                  prefix="事業所数"
                  suffix="事業所"
                ></NumberTextField>
              </v-col>
            </v-row>
            <v-data-table
              :items="answerDataNo9"
              :dense='true'
              :items-per-page="10"
              :mobile-breakpoint="0"
              :disable-pagination='true'
              :hide-default-header='true'
              :hide-default-footer='true'
              :fixed-header='false'
              :disable-sort='false'
              locale="ja-jp"
              loading-text="読込中"
              no-data-text="データがありません。"
              class="table-border no-hover-effect no9-table"
              height="100%"
            >
              <template v-slot:body="{items}">
                <thead>
                  <tr>
                    <th class="center" rowspan="2">
                      品目
                    </th>
                    <th class="center">
                      前月末保管残高
                    </th>
                    <th class="center">
                      当月中入庫高
                    </th>
                    <th class="center">
                      当月中出庫高
                    </th>
                    <th class="center">
                      当月末保管残高
                    </th>
                    <th class="center" rowspan="2">
                      備考
                    </th>
                  </tr>
                  <tr>
                    <th class="center">
                      数量（トン）
                    </th>
                    <th class="center">
                      数量（トン）
                    </th>
                    <th class="center">
                      数量（トン）
                    </th>
                    <th class="center">
                      数量（トン）
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in items" :key="index" >
                    <td>
                      {{ getCityDataNo9Item(item.type).text }}
                    </td>
                    <td>
                      <NumberTextField
                        :innerTable="true"
                        v-model="item.prevStorage"
                        dense
                        :readonly="!editMode"
                        :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                        :orgRoundStep="1" 
                      ></NumberTextField>
                      <span v-if="!chkPrevStorage(item.prevStorage, prevAnswerDataNo9[index].thisStorage)" class="yellow text-caption">前月の当月末保管残高と一致しません</span>
                    </td>
                    <td>
                      <NumberTextField
                        :innerTable="true"
                        v-model="item.thisIn"
                        dense
                        :readonly="!editMode"
                        :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                        :orgRoundStep="1" 
                      ></NumberTextField>
                    </td>
                    <td>
                      <NumberTextField
                        :innerTable="true"
                        v-model="item.thisOut"
                        dense
                        :readonly="!editMode"
                        :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                        :orgRoundStep="1" 
                      ></NumberTextField>
                    </td>
                    <td>
                      <NumberTextField
                        :innerTable="true"
                        v-model="item.thisStorage"
                        dense
                        :readonly="true"
                        :rules="[]"
                      ></NumberTextField>
                      <span v-if="!chkValueIsMin(item.thisStorage, 0)" class="yellow text-caption">0よりも大きい値を設定してください</span>
                    </td>
                    <td>
                      <v-text-field 
                        :value="chkCalculation9(index)"
                        dense
                        :readonly="true"
                        :rules="[]"
                        class="input-color-red"
                      ></v-text-field>
                    </td>
                  </tr>
                  <!-- 合計行 -->
                  <tr>
                    <td>
                      {{ getCityDataNo9Item(sumAnswer9.type).text }}
                    </td>
                    <td>
                      <NumberTextField
                        :innerTable="true"
                        v-model="sumAnswer9.prevStorage"
                        dense
                        :readonly="true"
                        :rules="[]"
                      ></NumberTextField>
                      <span v-if="!chkPrevStorage(sumAnswer9.prevStorage, prevSumAnswer9.thisStorage)" class="yellow text-caption">前月の当月末保管残高と一致しません</span>
                    </td>
                    <td>
                      <NumberTextField
                        :innerTable="true"
                        v-model="sumAnswer9.thisIn"
                        dense
                        :readonly="true"
                        :rules="[]"
                      ></NumberTextField>
                    </td>
                    <td>
                      <NumberTextField
                        :innerTable="true"
                        v-model="sumAnswer9.thisOut"
                        dense
                        :readonly="true"
                        :rules="[]"
                      ></NumberTextField>
                    </td>
                    <td>
                      <NumberTextField
                        :innerTable="true"
                        v-model="sumAnswer9.thisStorage"
                        dense
                        :readonly="true"
                        :rules="[]"
                      ></NumberTextField>
                      <span v-if="!chkValueIsMin(sumAnswer9.thisStorage, 0)" class="yellow text-caption">0よりも大きい値を設定してください</span>
                    </td>
                    <td>
                      <v-text-field 
                        :value="chkSumCalculation9"
                        dense
                        :readonly="true"
                        :rules="[]"
                        class="input-color-red"
                      ></v-text-field>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </div>
          <div class="table-inner">
            <v-row class="justify-space-between" style="width: 100%">
              <v-col cols="2" class="ml-5">
                <h4>前月データ</h4>
              </v-col>
              <v-col v-if="attribute == 2" cols="4">
                <NumberTextField
                  v-model="prevAnswerData.targetCnt"
                  dense
                  :readonly="true"
                  :rules="[]"
                  propClass="centered-input"
                  prefix="事業所数"
                  suffix="事業所"
                ></NumberTextField>
              </v-col>
            </v-row>
            <v-data-table
              :items="prevAnswerDataNo9"
              :dense='true'
              :items-per-page="10"
              :mobile-breakpoint="0"
              :disable-pagination='true'
              :hide-default-header='true'
              :hide-default-footer='true'
              :fixed-header='false'
              :disable-sort='false'
              locale="ja-jp"
              loading-text="読込中"
              no-data-text="データがありません。"
              class="table-border no-hover-effect no9-table"
              height="100%"
            >
              <template v-slot:body="{items}">
                <thead>
                  <tr>
                    <th class="center" rowspan="2">
                      品目
                    </th>
                    <th class="center">
                      前月末保管残高
                    </th>
                    <th class="center">
                      当月中入庫高
                    </th>
                    <th class="center">
                      当月中出庫高
                    </th>
                    <th class="center">
                      当月末保管残高
                    </th>
                    <th class="center" rowspan="2">
                      備考
                    </th>
                  </tr>
                  <tr>
                    <th class="center">
                      数量（トン）
                    </th>
                    <th class="center">
                      数量（トン）
                    </th>
                    <th class="center">
                      数量（トン）
                    </th>
                    <th class="center">
                      数量（トン）
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in items" :key="index" >
                    <td>
                      {{ getCityDataNo9Item(item.type).text }}
                    </td>
                    <td>
                      <NumberTextField
                        :innerTable="true"
                        v-model="item.prevStorage"
                        dense
                        :readonly="!editMode"
                        :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                        :orgRoundStep="1" 
                      ></NumberTextField>
                    </td>
                    <td>
                      <NumberTextField
                        :innerTable="true"
                        v-model="item.thisIn"
                        dense
                        :readonly="!editMode"
                        :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                        :orgRoundStep="1" 
                      ></NumberTextField>
                    </td>
                    <td>
                      <NumberTextField
                        :innerTable="true"
                        v-model="item.thisOut"
                        dense
                        :readonly="!editMode"
                        :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                        :orgRoundStep="1" 
                      ></NumberTextField>
                    </td>
                    <td>
                      <NumberTextField
                        :innerTable="true"
                        v-model="item.thisStorage"
                        dense
                        :readonly="true"
                        :rules="[]"
                      ></NumberTextField>
                      <span v-if="!chkValueIsMin(item.thisStorage, 0)" class="yellow text-caption">0よりも大きい値を設定してください</span>
                    </td>
                    <td>
                      <v-text-field 
                        :value="chkPrevCalculation9(index)"
                        dense
                        :readonly="true"
                        :rules="[]"
                        class="input-color-red"
                      ></v-text-field>
                    </td>
                  </tr>
                  <!-- 合計行 -->
                  <tr>
                    <td>
                      {{ getCityDataNo9Item(prevSumAnswer9.type).text }}
                    </td>
                    <td>
                      <NumberTextField
                        :innerTable="true"
                        v-model="prevSumAnswer9.prevStorage"
                        dense
                        :readonly="true"
                        :rules="[]"
                      ></NumberTextField>
                    </td>
                    <td>
                      <NumberTextField
                        :innerTable="true"
                        v-model="prevSumAnswer9.thisIn"
                        dense
                        :readonly="true"
                        :rules="[]"
                      ></NumberTextField>
                    </td>
                    <td>
                      <NumberTextField
                        :innerTable="true"
                        v-model="prevSumAnswer9.thisOut"
                        dense
                        :readonly="true"
                        :rules="[]"
                      ></NumberTextField>
                    </td>
                    <td>
                      <NumberTextField
                        :innerTable="true"
                        v-model="prevSumAnswer9.thisStorage"
                        dense
                        :readonly="true"
                        :rules="[]"
                      ></NumberTextField>
                      <span v-if="!chkValueIsMin(prevSumAnswer9.thisStorage, 0)" class="yellow text-caption">0よりも大きい値を設定してください</span>
                    </td>
                    <td>
                      <v-text-field 
                        :value="chkPrevSumCalculation9"
                        dense
                        :readonly="true"
                        :rules="[]"
                        class="input-color-red"
                      ></v-text-field>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </div>
        </div>
      </div>

      <!-- 輸入物資 -->
      <div v-if="!loading && !loadingData && !isCheckDate" class="mt-2">
        <h3>輸入物資</h3>
        <div class="table-wrap d-flex">
          <div class="table-inner">
            <v-row class="justify-space-between" style="width: 100%">
              <v-col cols="2" class="ml-5">
                <h4>当月データ</h4>
              </v-col>
              <v-col v-if="attribute == 2" cols="4">
                <NumberTextField
                  v-model="answerData.targetCnt"
                  dense
                  :readonly="true"
                  :rules="[]"
                  propClass="centered-input"
                  prefix="事業所数"
                  suffix="事業所"
                ></NumberTextField>
              </v-col>
            </v-row>
            <v-data-table
              :items="answerDataImported"
              :dense='true'
              :items-per-page="10"
              :mobile-breakpoint="0"
              :disable-pagination='true'
              :hide-default-header='true'
              :hide-default-footer='true'
              :fixed-header='false'
              :disable-sort='false'
              locale="ja-jp"
              loading-text="読込中"
              no-data-text="データがありません。"
              class="table-border no-hover-effect no9-table"
              height="100%"
            >
              <template v-slot:body="{items}">
                <thead>
                  <tr>
                    <th class="center" rowspan="2">
                      品目
                    </th>
                    <th class="center">
                      前月末保管残高
                    </th>
                    <th class="center">
                      当月中入庫高
                    </th>
                    <th class="center">
                      当月中出庫高
                    </th>
                    <th class="center">
                      当月末保管残高
                    </th>
                    <th class="center" rowspan="2">
                      備考
                    </th>
                  </tr>
                  <tr>
                    <th class="center">
                      数量（トン）
                    </th>
                    <th class="center">
                      数量（トン）
                    </th>
                    <th class="center">
                      数量（トン）
                    </th>
                    <th class="center">
                      数量（トン）
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in items" :key="index" >
                    <td>
                      {{ getCityDataImportedItem(item.type).text }}
                    </td>
                    <td>
                      <NumberTextField
                        :innerTable="true"
                        v-model="item.prevStorage"
                        dense
                        :readonly="!editMode"
                        :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                        :orgRoundStep="1" 
                      ></NumberTextField>
                      <span v-if="!chkPrevStorage(item.prevStorage, prevAnswerDataImported[index].thisStorage)" class="yellow text-caption">前月の当月末保管残高と一致しません</span>
                    </td>
                    <td>
                      <NumberTextField
                        :innerTable="true"
                        v-model="item.thisIn"
                        dense
                        :readonly="!editMode"
                        :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                        :orgRoundStep="1" 
                      ></NumberTextField>
                    </td>
                    <td>
                      <NumberTextField
                        :innerTable="true"
                        v-model="item.thisOut"
                        dense
                        :readonly="!editMode"
                        :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                        :orgRoundStep="1" 
                      ></NumberTextField>
                    </td>
                    <td>
                      <NumberTextField
                        :innerTable="true"
                        v-model="item.thisStorage"
                        dense
                        :readonly="true"
                        :rules="[]"
                      ></NumberTextField>
                      <span v-if="!chkValueIsMin(item.thisStorage, 0)" class="yellow text-caption">0よりも大きい値を設定してください</span>
                    </td>
                    <td>
                      <v-text-field 
                        :value="chkCalculationImported(index)"
                        dense
                        :readonly="true"
                        :rules="[]"
                        class="input-color-red"
                      ></v-text-field>
                    </td>
                  </tr>
                  <!-- 合計行 -->
                  <tr>
                    <td>
                      {{ getCityDataImportedItem(sumAnswerImported.type).text }}
                    </td>
                    <td>
                      <NumberTextField
                        :innerTable="true"
                        v-model="sumAnswerImported.prevStorage"
                        dense
                        :readonly="true"
                        :rules="[]"
                      ></NumberTextField>
                      <span v-if="!chkPrevStorage(sumAnswerImported.prevStorage, prevSumAnswerImported.thisStorage)" class="yellow text-caption">前月の当月末保管残高と一致しません</span>
                    </td>
                    <td>
                      <NumberTextField
                        :innerTable="true"
                        v-model="sumAnswerImported.thisIn"
                        dense
                        :readonly="true"
                        :rules="[]"
                      ></NumberTextField>
                    </td>
                    <td>
                      <NumberTextField
                        :innerTable="true"
                        v-model="sumAnswerImported.thisOut"
                        dense
                        :readonly="true"
                        :rules="[]"
                      ></NumberTextField>
                    </td>
                    <td>
                      <NumberTextField
                        :innerTable="true"
                        v-model="sumAnswerImported.thisStorage"
                        dense
                        :readonly="true"
                        :rules="[]"
                      ></NumberTextField>
                      <span v-if="!chkValueIsMin(sumAnswerImported.thisStorage, 0)" class="yellow text-caption">0よりも大きい値を設定してください</span>
                    </td>
                    <td>
                      <v-text-field 
                        :value="chkSumCalculationImported"
                        dense
                        :readonly="true"
                        :rules="[]"
                        class="input-color-red"
                      ></v-text-field>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </div>
          <div class="table-inner">
            <v-row class="justify-space-between" style="width: 100%">
              <v-col cols="2" class="ml-5">
                <h4>前月データ</h4>
              </v-col>
              <v-col v-if="attribute == 2" cols="4">
                <NumberTextField
                  v-model="prevAnswerData.targetCnt"
                  dense
                  :readonly="true"
                  :rules="[]"
                  propClass="centered-input"
                  prefix="事業所数"
                  suffix="事業所"
                ></NumberTextField>
              </v-col>
            </v-row>
            <v-data-table
              :items="prevAnswerDataImported"
              :dense='true'
              :items-per-page="10"
              :mobile-breakpoint="0"
              :disable-pagination='true'
              :hide-default-header='true'
              :hide-default-footer='true'
              :fixed-header='false'
              :disable-sort='false'
              locale="ja-jp"
              loading-text="読込中"
              no-data-text="データがありません。"
              class="table-border no-hover-effect no9-table"
              height="100%"
            >
              <template v-slot:body="{items}">
                <thead>
                  <tr>
                    <th class="center" rowspan="2">
                      品目
                    </th>
                    <th class="center">
                      前月末保管残高
                    </th>
                    <th class="center">
                      当月中入庫高
                    </th>
                    <th class="center">
                      当月中出庫高
                    </th>
                    <th class="center">
                      当月末保管残高
                    </th>
                    <th class="center" rowspan="2">
                      備考
                    </th>
                  </tr>
                  <tr>
                    <th class="center">
                      数量（トン）
                    </th>
                    <th class="center">
                      数量（トン）
                    </th>
                    <th class="center">
                      数量（トン）
                    </th>
                    <th class="center">
                      数量（トン）
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in items" :key="index" >
                    <td>
                      {{ getCityDataImportedItem(item.type).text }}
                    </td>
                    <td>
                      <NumberTextField
                        :innerTable="true"
                        v-model="item.prevStorage"
                        dense
                        :readonly="!editMode"
                        :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                        :orgRoundStep="1" 
                      ></NumberTextField>
                    </td>
                    <td>
                      <NumberTextField
                        :innerTable="true"
                        v-model="item.thisIn"
                        dense
                        :readonly="!editMode"
                        :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                        :orgRoundStep="1" 
                      ></NumberTextField>
                    </td>
                    <td>
                      <NumberTextField
                        :innerTable="true"
                        v-model="item.thisOut"
                        dense
                        :readonly="!editMode"
                        :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                        :orgRoundStep="1" 
                      ></NumberTextField>
                    </td>
                    <td>
                      <NumberTextField
                        :innerTable="true"
                        v-model="item.thisStorage"
                        dense
                        :readonly="true"
                        :rules="[]"
                      ></NumberTextField>
                      <span v-if="!chkValueIsMin(item.thisStorage, 0)" class="yellow text-caption">0よりも大きい値を設定してください</span>
                    </td>
                    <td>
                      <v-text-field 
                        :value="chkPrevCalculationImported(index)"
                        dense
                        :readonly="true"
                        :rules="[]"
                        class="input-color-red"
                      ></v-text-field>
                    </td>
                  </tr>
                  <!-- 合計行 -->
                  <tr>
                    <td>
                      {{ getCityDataImportedItem(prevSumAnswerImported.type).text }}
                    </td>
                    <td>
                      <NumberTextField
                        :innerTable="true"
                        v-model="prevSumAnswerImported.prevStorage"
                        dense
                        :readonly="true"
                        :rules="[]"
                      ></NumberTextField>
                    </td>
                    <td>
                      <NumberTextField
                        :innerTable="true"
                        v-model="prevSumAnswerImported.thisIn"
                        dense
                        :readonly="true"
                        :rules="[]"
                      ></NumberTextField>
                    </td>
                    <td>
                      <NumberTextField
                        :innerTable="true"
                        v-model="prevSumAnswerImported.thisOut"
                        dense
                        :readonly="true"
                        :rules="[]"
                      ></NumberTextField>
                    </td>
                    <td>
                      <NumberTextField
                        :innerTable="true"
                        v-model="prevSumAnswerImported.thisStorage"
                        dense
                        :readonly="true"
                        :rules="[]"
                      ></NumberTextField>
                      <span v-if="!chkValueIsMin(prevSumAnswerImported.thisStorage, 0)" class="yellow text-caption">0よりも大きい値を設定してください</span>
                    </td>
                    <td>
                      <v-text-field 
                        :value="chkPrevSumCalculationImported"
                        dense
                        :readonly="true"
                        :rules="[]"
                        class="input-color-red"
                      ></v-text-field>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </div>
        </div>

        <!------------------------------------------------------------------------>
        <!-- ぐるぐる -->
        <modal name="modal-dialog" :draggable="false" :clickToClose="false" :scrollable="true" height="auto" id="vm--modal">
          <div>
            <h3 class="center mt-5 action-message">{{actionMessage}}</h3> 
            <Spinner size="150" class="spinner" ></Spinner>
          </div>
        </modal>

      </div>
    </v-form>
  </v-container>
</template>

<script>
import Debug from '../Lib/Debug'
import Util from '../Lib/Util'
import DatePicker from "./common/DatePicker.vue";
import NumberTextField from "./common/Number_text_field.vue";
import Const from '../mixins/const';
import Message from "../Lib/Message";
import dayjs from "dayjs";
import Spinner from 'vue-simple-spinner'

import CityDataAnswer from "../model/cityDataAnswer";
import CityDataAssociationSum from "../model/cityDataAssociationSum";

import Download from "../model/download";
import saveAs from "file-saver";

export default {
  mixins: [Const],
  
  components: {
    DatePicker,
    NumberTextField,
    Spinner,
  },

  data: () => ({
    valid : true,
    loading: true,
    loadingData: false,

    editMode: false,
    

    memberNo: '',
    attribute: 0,

    // 調査種別リスト
    surveyType: [
      {text: "月次報告データ", value: 1},
      {text: "実態調査", value: 2},
      {text: "電力調査", value: 3},
      {text: "冷媒調査", value: 4},
    ],

    // 対象調査種別
    targetSurveyType: null,

    // 12都市
    is12City: false,

    // 検索条件
    searchCondition: {
      searchType: 0,
      associationId: null,
      targetMonth: "",
      companyId: null,
      companyName: null,
      is12City: false,
    },
    getDataSearchCondition: {
      targetMonth: "",
      memberNo: null,
    },

    // テーブルヘッダー事業所
    headersMember: [
        { text: '集計', align: 'center', sortable: true, value: 'sum', width: "5%" },
        { text: 'No', align: 'center', sortable: true, value: 'no', width: "5%" },
        { text: '会員番号', align: 'center', sortable: true, value: 'memberNo', width: "5%" },
        { text: '会社ID', align: 'center', sortable: true, value: 'companyId', width: "5%" },
        { text: '会社名', align: 'center', sortable: true, value: 'companyName', width: "15%" },
        { text: '事業所名', align: 'center', sortable: true, value: 'officeName', width: "20%" },
        { text: '都道府県', align: 'center', sortable: true, value: 'prefName', width: "5%" },
        { text: '住所', align: 'center', sortable: true, value: 'address', width: "20%" },
        { text: '冷蔵倉庫所管容積', align: 'center', sortable: true, value: 'cVolume', width: "10%" },
        { text: '回答状況', align: 'center', sortable: true, value: 'no8Status', width: "10%" },
    ],
    // テーブルヘッダー都道府県協会
    headersAssociation: [
        { text: '集計', align: 'center', sortable: true, value: 'sum', width: "5%" },
        { text: 'No', align: 'center', sortable: true, value: 'no', width: "5%" },
        { text: '都市コード', align: 'center', sortable: true, value: 'cityCode', width: "10%" },
        { text: '都市名', align: 'center', sortable: true, value: 'cityName', width: "15%" },
        { text: '氏名又は名称', align: 'center', sortable: true, value: 'name', width: "20%" },
        { text: '営業所の名称', align: 'center', sortable: true, value: 'officeName', width: "20%" },
        { text: '冷蔵倉庫所管容積', align: 'center', sortable: true, value: 'cVolume', width: "15%" },
        { text: '都道府県集計', align: 'center', sortable: true, value: 'status', width: "10%" },
    ],
    // テーブルヘッダー都道府県協会
    headersPref: [
        { text: '集計', align: 'center', sortable: true, value: 'sum', width: "5%" },
        { text: 'No', align: 'center', sortable: true, value: 'no', width: "5%" },
        { text: '府県コード', align: 'center', sortable: true, value: 'prefSumCd', width: "10%" },
        { text: '府県名', align: 'center', sortable: true, value: 'prefName', width: "10%" },
        { text: 'ブロック', align: 'center', sortable: true, value: 'block', width: "10%" },
        { text: '地区協会名', align: 'center', sortable: true, value: 'officeName', width: "35%" },
        { text: '冷蔵倉庫所管容積', align: 'center', sortable: true, value: 'cVolume', width: "15%" },
        { text: '都道府県集計', align: 'center', sortable: true, value: 'status', width: "10%" },
    ],

    answerList: [],
    selected12City: null,

    // 回答データ一覧
    memberData: {
      cityCode: 1, cityName: '札幌市', officeName: "北海道冷蔵倉庫協会",
    },
    // 回答
    answerData: {
      sid: null,
      memberNo: '',
      targetMonth: '',
      companyName: '',
      officeName: '',
      address: '',
      responderName8: '',
      responderName9: '',
      responderNameImported: '',
      status: 0,
    },
    prevAnswerData: {},
    // 8号
    answerDataNo8: {
      answerId: null,
      volumeTotal: 0, 
      volumeReceipt: 0, 
      volumePrivate: 0, 
      volumeAvailable: 0, 
      volumeMeasure: 0, 
      weightIce: 0, 
      weightFrozen: 0,
    },
    prevAnswerDataNo8: {},
    // 9号
    answerDataNo9: [
      {answerId: null, type: 1, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {answerId: null, type: 2, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {answerId: null, type: 3, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {answerId: null, type: 4, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {answerId: null, type: 5, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {answerId: null, type: 6, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {answerId: null, type: 7, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {answerId: null, type: 8, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {answerId: null, type: 9, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {answerId: null, type: 10, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {answerId: null, type: 11, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
    ],
    sumAnswer9: {},
    prevAnswerDataNo9: {},
    prevSumAnswer9: {},
    // 輸入物資
    answerDataImported: [
      {answerId: null, type: 1, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {answerId: null, type: 2, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {answerId: null, type: 3, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {answerId: null, type: 4, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {answerId: null, type: 5, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {answerId: null, type: 6, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {answerId: null, type: 7, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {answerId: null, type: 8, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {answerId: null, type: 9, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {answerId: null, type: 10, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {answerId: null, type: 11, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {answerId: null, type: 12, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {answerId: null, type: 13, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
    ],
    sumAnswerImported: {},
    prevAnswerDataImported: {},
    prevSumAnswerImported: {},

    // 12都市集計 初期値
    sumData: {
      sid: null,
      associationId: '',
      targetMonth: '',
      officeName: '',
      name: '',
      cityDataSection: '',
      targetCnt: 0,
    },
    sumDataNo8: {
      sumId: null,
      sumType: 2,
      cityDataSection: "",
      targetMonth: '',
      volumeTotal: 0, 
      volumeReceipt: 0, 
      volumePrivate: 0, 
      volumeAvailable: 0, 
      volumeMeasure: 0, 
      weightIce: 0, 
      weightFrozen: 0,
    },
    sumDataNo9: [
      {sumId: null, sumType: 2, cityDataSection: "", targetMonth: '', type: 1, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {sumId: null, sumType: 2, cityDataSection: "", targetMonth: '', type: 2, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {sumId: null, sumType: 2, cityDataSection: "", targetMonth: '', type: 3, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {sumId: null, sumType: 2, cityDataSection: "", targetMonth: '', type: 4, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {sumId: null, sumType: 2, cityDataSection: "", targetMonth: '', type: 5, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {sumId: null, sumType: 2, cityDataSection: "", targetMonth: '', type: 6, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {sumId: null, sumType: 2, cityDataSection: "", targetMonth: '', type: 7, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {sumId: null, sumType: 2, cityDataSection: "", targetMonth: '', type: 8, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {sumId: null, sumType: 2, cityDataSection: "", targetMonth: '', type: 9, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {sumId: null, sumType: 2, cityDataSection: "", targetMonth: '', type: 10, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {sumId: null, sumType: 2, cityDataSection: "", targetMonth: '', type: 11, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
    ],
    sumDataImported: [
      {sumId: null, sumType: 2, cityDataSection: "", targetMonth: '', type: 1, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {sumId: null, sumType: 2, cityDataSection: "", targetMonth: '', type: 2, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {sumId: null, sumType: 2, cityDataSection: "", targetMonth: '', type: 3, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {sumId: null, sumType: 2, cityDataSection: "", targetMonth: '', type: 4, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {sumId: null, sumType: 2, cityDataSection: "", targetMonth: '', type: 5, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {sumId: null, sumType: 2, cityDataSection: "", targetMonth: '', type: 6, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {sumId: null, sumType: 2, cityDataSection: "", targetMonth: '', type: 7, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {sumId: null, sumType: 2, cityDataSection: "", targetMonth: '', type: 8, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {sumId: null, sumType: 2, cityDataSection: "", targetMonth: '', type: 9, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {sumId: null, sumType: 2, cityDataSection: "", targetMonth: '', type: 10, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {sumId: null, sumType: 2, cityDataSection: "", targetMonth: '', type: 11, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {sumId: null, sumType: 2, cityDataSection: "", targetMonth: '', type: 12, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {sumId: null, sumType: 2, cityDataSection: "", targetMonth: '', type: 13, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
    ],

    // 府県別集計 初期値
    prefSumData: {
      sid: null,
      associationId: '',
      targetMonth: '',
      officeName: '',
      name: '',
      targetCnt: 0,
      prefId: 0,
    },
    prefSumDataNo8: {
      sumId: null,
      sumType: 0,
      targetMonth: '',
      volumeTotal: 0, 
      volumeReceipt: 0, 
      volumePrivate: 0, 
      volumeAvailable: 0, 
      volumeMeasure: 0, 
      weightIce: 0, 
      weightFrozen: 0,
      prefId: 0,
    },
    prefSumDataNo9: [
      {sumId: null, sumType: 0, prefId: 0, targetMonth: '', type: 1, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {sumId: null, sumType: 0, prefId: 0, targetMonth: '', type: 2, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {sumId: null, sumType: 0, prefId: 0, targetMonth: '', type: 3, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {sumId: null, sumType: 0, prefId: 0, targetMonth: '', type: 4, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {sumId: null, sumType: 0, prefId: 0, targetMonth: '', type: 5, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {sumId: null, sumType: 0, prefId: 0, targetMonth: '', type: 6, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {sumId: null, sumType: 0, prefId: 0, targetMonth: '', type: 7, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {sumId: null, sumType: 0, prefId: 0, targetMonth: '', type: 8, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {sumId: null, sumType: 0, prefId: 0, targetMonth: '', type: 9, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {sumId: null, sumType: 0, prefId: 0, targetMonth: '', type: 10, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {sumId: null, sumType: 0, prefId: 0, targetMonth: '', type: 11, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
    ],
    prefSumDataImported: [
      {sumId: null, sumType: 0, prefId: 0, targetMonth: '', type: 1, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {sumId: null, sumType: 0, prefId: 0, targetMonth: '', type: 2, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {sumId: null, sumType: 0, prefId: 0, targetMonth: '', type: 3, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {sumId: null, sumType: 0, prefId: 0, targetMonth: '', type: 4, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {sumId: null, sumType: 0, prefId: 0, targetMonth: '', type: 5, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {sumId: null, sumType: 0, prefId: 0, targetMonth: '', type: 6, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {sumId: null, sumType: 0, prefId: 0, targetMonth: '', type: 7, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {sumId: null, sumType: 0, prefId: 0, targetMonth: '', type: 8, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {sumId: null, sumType: 0, prefId: 0, targetMonth: '', type: 9, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {sumId: null, sumType: 0, prefId: 0, targetMonth: '', type: 10, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {sumId: null, sumType: 0, prefId: 0, targetMonth: '', type: 11, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {sumId: null, sumType: 0, prefId: 0, targetMonth: '', type: 12, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {sumId: null, sumType: 0, prefId: 0, targetMonth: '', type: 13, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
    ],

    // 集計対象データ
    innerSelectedList: [],

    actionMessage: '',

    // 集計対象(単月・全月)
    dbAll: 1,

    // 対象年月チェック
    isCheckDate: false,

    // 集計データ
    existsSum: false,
  }),

  // ライフサイクルフック
  created: function () {
    this.attribute = this.$store.getters.getAttribute;  // 属性
    if(this.attribute == 0) {
      // 事業所ユーザ
      this.memberNo = this.$store.getters.getMemberNo;    // 会員番号
    } else {
      // 協会ユーザ
      this.memberNo = this.$store.getters.getAssociation.association_id;    // 会員番号
    }

    this.targetSurveyType = this.$store.getters.getTargetSurveyType;  // 調査種別

    // 属性による検索条件の設定
    if(this.attribute == 1) {
      // 都道府県協会ユーザは自協会に所属する会社のデータのみ
      this.searchCondition.searchType = 0;
      this.searchCondition.associationId = this.memberNo;
    } else {
      // 日本冷蔵倉庫協会ユーザは12都市の都道府県協会データのみ
      this.searchCondition.searchType = 1;
      this.is12City = true;
      this.searchCondition.is12City = this.is12City;
    }
    
    let paramTargetDate = this.$route.params.checkViewTargetDate;
    if((typeof paramTargetDate != "undefined") || (paramTargetDate != null)) {
      this.searchCondition.targetMonth = dayjs(paramTargetDate).format('YYYY-MM');  // 実施年月
    } else {
      this.searchCondition.targetMonth = dayjs().format('YYYY-MM');  // 実施年月
    }
  },

  mounted: async function () {
    this.loadList();
    
  },

  methods: {
    /** 12都市タブ選択 */
    // selectTab12City() {
    //   this.is12City = true;
    //   this.searchCondition.is12City = this.is12City;
    //   this.loadList();
    // },

    // /** 府県別タブ選択 */
    // selectTabPrefSum() {
    //   this.is12City = false;
    //   this.searchCondition.is12City = this.is12City;
    //   this.loadList();
    // },

    /** 表示更新 */
    async loadList(selectRowIndex = 0) {
      Debug.log('function[loadList]');
      this.loading = true;
      
      try {
        let res = null;
        // 回答データ一覧
        res = await CityDataAnswer.getSurveyAnsCheckDataList(this.searchCondition);

        if (res.status == 200 && res.data) {
          Debug.log(res.data.list);
          // 回答データ
          this.answerList = res.data.list;
          Debug.log(this.answerList);

          // 1行目を選択
          if(this.answerList.length > 0) {
            if(this.attribute == 1) {
              this.selectRows(this.answerList[selectRowIndex].memberNo, selectRowIndex);
            } else if(this.is12City) {
              this.selectRows(this.answerList[selectRowIndex].cityCode, selectRowIndex);
            } else {
              this.selectRows(this.answerList[selectRowIndex].prefSumCd, selectRowIndex);
            }
          }

          // 集計対象すべて選択
          if(selectRowIndex == 0) {
            this.selectAll = true;
          }

          // 集計結果存在
          this.existsSum = res.data.existsSum;
        }
      } catch (error) {
        // alert('情報を取得できませんでした。')
        Message.err(error, "情報を取得できませんでした");
      }

      this.loading = false;
    },

    search() {
      Debug.log("function[search]");
      this.$store.commit('setStoreSearchCondition', this.searchCondition);
      this.loadList();
    },

    // 回答データ編集
    editData() {
      this.editMode = true;
    },

    // キャンセル
    cancel() {
      if (!confirm("変更をキャンセルします。よろしいですか？")) return;

      // データ取得
      // 個別データ検索条件
      this.getData();
      
      this.editMode = false;
    },

    // 保存
    async registData() {
      if (!this.$refs.form.validate()) {
        alert("入力に誤りがあります。内容をご確認ください。");
        return;
      }

      if (!confirm("回答データを保存します。よろしいですか？")) return;
      // dateをcomputedの値で更新
      this.updateValue();

      this.loading = true;
      try {
        const thisAnswer = {
          answerData: this.answerData,
          answerDataNo8: this.answerDataNo8,
          answerDataNo9: this.answerDataNo9,
          sumAnswer9: this.sumAnswer9,
          answerDataImported: this.answerDataImported,
          sumAnswerImported: this.sumAnswerImported
        };
        const prevAnswer = {
          answerData: this.prevAnswerData,
          answerDataNo8: this.prevAnswerDataNo8,
          answerDataNo9: this.prevAnswerDataNo9,
          sumAnswer9: this.prevSumAnswer9,
          answerDataImported: this.prevAnswerDataImported,
          sumAnswerImported: this.prevSumAnswerImported
        };

        let res = null;
        if(this.attribute == 1) {
          // 都道府県協会
          res = await CityDataAnswer.setDataThisPrev(thisAnswer, prevAnswer);
        } else if(this.is12City) {
          // 日冷倉(12都市)
          res = await CityDataAssociationSum.setDataThisPrev(thisAnswer, prevAnswer);
        } else {
          // 日冷倉(府県別)
          res = await CityDataAssociationSum.setDataThisPrevPrefSum(thisAnswer, prevAnswer);
        }
        // Debug.log(res);

        // サーバーサイドのバリデーション 
        const validationMsg = res.data;
        if (validationMsg) {
          let message = "";
          if (Array.isArray(validationMsg)) {
            validationMsg.forEach(m => message += m + '\n')
          } else {
            message = validationMsg;
          }
          alert(message);

          this.loading = false;
          return;
        } else {
          alert("保存しました。");
        }

      } catch (error) {
        Message.err(error, "保存できませんでした");
      }
      this.loading = false;
      this.editMode = false;

      // データを再取得
      this.loadList(this.selected12City);
      // if(this.attribute == 1) {
      //   this.selectRows(this.answerList[this.selected12City].memberNo, this.selected12City);
      // } else if(this.is12City) {
      //   this.selectRows(this.answerList[this.selected12City].cityCode, this.selected12City);
      // } else {
      //   this.selectRows(this.answerList[this.selected12City].prefSumCd, this.selected12City);
      // }
    },

    updateValue() {
      // computedの自動計算値を更新
      this.answerDataNo8.volumeReceipt = this.answer8VolumeReceiptCalc;
      this.answerDataNo8.volumeAvailable = this.answer8VolumeAvailableCalc;
      this.prevAnswerDataNo8.volumeReceipt = this.prevAnswer8VolumeReceiptCalc;
      this.prevAnswerDataNo8.volumeAvailable = this.prevAnswer8VolumeAvailableCalc;
    },

    // 集計
    async goToSummary() {
      if(this.innerSelectedList.length <= 0) {
        alert("集計対象が選択されていません。");
        return
      }
      if(this.existsSum) {
        // 集計結果既にあり
        if (!confirm("集計結果が既に存在します。上書きしてよろしいですか？")) return;
      } else {
        // 集計なし
        if (!confirm("選択された事業所の回答を集計します。よろしいですか？")) return;
      }
      
      this.loading = true;
      try {
        // 都道府県協会集計
        const res = await CityDataAnswer.sumAssociation(this.innerSelectedList, this.getDataSearchCondition.targetMonth, this.memberNo);
        // Debug.log(res);

        // サーバーサイドのバリデーション 
        const validationMsg = res.data;
        if (validationMsg) {
          let message = "";
          if (Array.isArray(validationMsg)) {
            validationMsg.forEach(m => message += m + '\n')
          } else {
            message = validationMsg;
          }
          alert(message);

          this.loading = false;
          return;
        } else {
          alert("集計データを保存しました。集計画面に移動します。");
          this.$router.push({name: 'SurveySummary', params:{targetMonth: this.searchCondition.targetMonth}});
        }

      } catch (error) {
        Message.err(error, "集計データを保存できませんでした");
      }
      this.loading = false;
    },

    // 12都市集計ダウンロード
    async get12cityDB() {
      if (!confirm("選択された12都市の回答を集計します。よろしいですか？")) return;

      let tmpTargetMonth = this.searchCondition.targetMonth;
      if(this.dbAll == 1) {
        tmpTargetMonth = null;
      }

      try {
        this.actionMessage = "12都市集計を作成しています。\nしばらくお待ちください。"
        this.$modal.show('modal-dialog');

        const res = await CityDataAssociationSum.get12cityDB(tmpTargetMonth, this.innerSelectedList, this.searchCondition.targetMonth);

        if (res.status == 200 && res.data) {
          // 作成に成功したらExcelダウンロード
          Debug.log(res.data);
          const downloadFile = res.data;
          Download.download(downloadFile)
            .then(res => {
              saveAs(res.data, '12都市データ集計.zip');
            })
            .catch((err) => {
              Message.err(err, "ダウンロードできませんでした");
            })
        }
      } catch (error) {
        Message.err(error, "12都市DBを作成できませんでした");
      } finally {
        this.$modal.hide('modal-dialog');
      }
    },

    /** 府県別集計 */
    async getPrefSum() {
      this.actionMessage = "府県別集計を作成しています。\nしばらくお待ちください。"
      this.$modal.show('modal-dialog');

      // EXCEL出力
      CityDataAssociationSum.getPrefSum(this.innerSelectedList, this.searchCondition.targetMonth)
          .then(res =>{
            if (res && res.data) {
              if (!confirm("作成した府県別集計をダウンロードします。よろしいですか？")) return;
              
              const file_info = res.data;
              Debug.log2("Download ファイル情報->", file_info);

              // ファイルダウンロード
              this.download(file_info);
            }
          })
          .catch((err) => {
            Message.err(err, "府県別集計を作成できませんでした");
          })
          .finally(() => {
            this.$modal.hide('modal-dialog');
            //this.loading = false;
          });
    },

    selectRows(value, index) {
      // 編集モードの場合は確認アラート表示
      if(this.editMode) {
        if (!confirm("変更は保存されません。よろしいですか？")) return;
        this.editMode = false;
      }

      // 選択されたデータのindex
      this.selected12City = index;

      // データ取得
      // 個別データ検索条件
      if(this.attribute == 1) {
        // 都道府県協会ユーザは選択した事業所のデータ
        this.getDataSearchCondition = {
          targetMonth: this.searchCondition.targetMonth,
          memberNo: value,
        };
      } else if(this.is12City) {
        // 日本冷蔵倉庫協会ユーザは選択した12都市データ
        this.getDataSearchCondition = {
          targetMonth: this.searchCondition.targetMonth,
          cityDataSection: value,
        };
        Debug.log(this.getDataSearchCondition);
      } else {
        // 府県別集計
        this.getDataSearchCondition = {
          targetMonth: this.searchCondition.targetMonth,
          prefSumCd: value,
        };
      }
      this.getData();
      // 事業所データを選択されたデータに変更
      this.memberData = this.answerList[index];
    },

    // 親要素への伝搬を止める
    changeSelected() {
    },

    async getData() {
      Debug.log('function[getData]');
      this.loadingData = true;

      // バリデーションのリセット
      this.$nextTick(() => {
        this.$refs.form.resetValidation();
      });

      // 回答データ
      try {
        // 属性による取得データの切り替え
        let res = null;
        if(this.attribute == 1) {
          // 都道府県協会ユーザは選択した事業所のデータ
          res = await CityDataAnswer.getAnsCheckDataMember(this.getDataSearchCondition);
        } else if(this.is12City) {
          // 日本冷蔵倉庫協会ユーザは選択した12都市データ
          res = await CityDataAnswer.getAnsCheckData12city(this.getDataSearchCondition);
        } else {
          // 府県別集計
          res = await CityDataAnswer.getAnsCheckDataPrefSum(this.getDataSearchCondition);
        }
        
        Debug.log(res.data);
        if (res.status == 200 && res.data) {
          // 当月回答データが存在する場合、データ格納
          if(Object.keys(res.data.thisData).length > 0) {
            this.answerData = res.data.thisData;
          } else {
            // 初期値を設定
            if(this.attribute == 1) {
              // 事業所
              this.answerData = this.$options.data().answerData;
              // 事業所情報を設定
              this.answerData.targetMonth = this.getDataSearchCondition.targetMonth + '-01';
              this.answerData.memberNo = this.memberData.memberNo;
              this.answerData.companyName = this.memberData.companyName;
              this.answerData.officeName = this.memberData.officeName;
              this.answerData.address = this.memberData.address;
            } else if(this.is12City) {
              // 12都市
              this.answerData = this.$options.data().sumData;
              // 協会情報を設定
              this.answerData.targetMonth = this.getDataSearchCondition.targetMonth + '-01';
              this.answerData.associationId = this.memberData.associationId;
              this.answerData.officeName = this.memberData.officeName;
              this.answerData.name = this.memberData.name;
              this.answerData.cityDataSection = this.memberData.cityCode;
            } else {
              // 府県別
              this.answerData = this.$options.data().prefSumData;
              // 協会情報を設定
              this.answerData.targetMonth = this.getDataSearchCondition.targetMonth + '-01';
              this.answerData.associationId = this.memberData.associationId;
              this.answerData.officeName = this.memberData.officeName;
              this.answerData.name = this.memberData.name;
              this.answerData.prefId = this.memberData.prefId;
            }
          }

          // 当月8号データが存在する場合、データ格納
          if(Object.keys(res.data.this8).length > 0) {
            this.answerDataNo8 = res.data.this8;
          } else {
            // 初期値を設定
            if(this.attribute == 1) {
              // 事業所
              this.answerDataNo8 = this.$options.data().answerDataNo8;
            } else if(this.is12City) {
              // 12都市
              this.answerDataNo8 = this.$options.data().sumDataNo8;
            } else {
              // 府県別
              this.answerDataNo8 = this.$options.data().prefSumDataNo8;
            }
          }

          // 当月9号データが存在する場合、データ格納
          if(Object.keys(res.data.this9).length > 0) {
            this.answerDataNo9 = res.data.this9;
          } else {
            // 初期値を設定
            if(this.attribute == 1) {
              // 事業所
              this.answerDataNo9 = this.$options.data().answerDataNo9;
            } else if(this.is12City) {
              // 12都市
              this.answerDataNo9 = this.$options.data().sumDataNo9;
            } else {
              // 府県別
              this.answerDataNo9 = this.$options.data().prefSumDataNo9;
            }
          }

          // 当月輸入品データが存在する場合、データ格納
          if(Object.keys(res.data.thisImported).length > 0) {
            this.answerDataImported = res.data.thisImported;
          } else {
            // 初期値を設定
            if(this.attribute == 1) {
              // 事業所
              this.answerDataImported = this.$options.data().answerDataImported;
            } else if(this.is12City) {
              // 12都市
              this.answerDataImported = this.$options.data().sumDataImported;
            } else {
              // 府県別
              this.answerDataImported = this.$options.data().prefSumDataImported;
            }
          }

          Debug.log2("this.answerData:", this.answerData);
          Debug.log2("this.answerDataNo8:", this.answerDataNo8);
          Debug.log2("this.answerDataNo9:", this.answerDataNo9);
          Debug.log2("this.answerDataImported:", this.answerDataImported);

          // 前月回答データが存在する場合、データ格納
          if(Object.keys(res.data.prevData).length > 0) {
            this.prevAnswerData = res.data.prevData;
          } else {
            // 初期値を設定
            if(this.attribute == 1) {
              // 事業所
              // 回答
              this.prevAnswerData = this.$options.data().answerData;
              this.prevAnswerData.targetMonth = dayjs(this.getDataSearchCondition.targetMonth + '-01').subtract(1, 'month').format('YYYY-MM-DD');
              this.prevAnswerData.memberNo = this.memberData.memberNo;
              this.prevAnswerData.companyName = this.memberData.companyName;
              this.prevAnswerData.officeName = this.memberData.officeName;
              this.prevAnswerData.address = this.memberData.address;
            } else if(this.is12City) {
              // 12都市
              // 回答
              this.prevAnswerData = this.$options.data().sumData;
              this.prevAnswerData.targetMonth = dayjs(this.getDataSearchCondition.targetMonth + '-01').subtract(1, 'month').format('YYYY-MM-DD');
              this.prevAnswerData.associationId = this.memberData.associationId;
              this.prevAnswerData.officeName = this.memberData.officeName;
              this.prevAnswerData.name = this.memberData.name;
              this.prevAnswerData.cityDataSection = this.memberData.cityCode;
            } else {
              // 府県別
              // 回答
              this.prevAnswerData = this.$options.data().prefSumData;
              this.prevAnswerData.targetMonth = dayjs(this.getDataSearchCondition.targetMonth + '-01').subtract(1, 'month').format('YYYY-MM-DD');
              this.prevAnswerData.associationId = this.memberData.associationId;
              this.prevAnswerData.officeName = this.memberData.officeName;
              this.prevAnswerData.name = this.memberData.name;
            }
          }

          // 前月8号データが存在する場合、データ格納
          if(Object.keys(res.data.prev8).length > 0) {
            this.prevAnswerDataNo8 = res.data.prev8;
          } else {
            // 初期値を設定
            if(this.attribute == 1) {
              // 事業所
              this.prevAnswerDataNo8 = this.$options.data().answerDataNo8;
            } else if(this.is12City) {
              // 12都市
              this.prevAnswerDataNo8 = this.$options.data().sumDataNo8;
            } else {
              // 府県別
              this.prevAnswerDataNo8 = this.$options.data().prefSumDataNo8;
            }
          }

          // 前月9号データが存在する場合、データ格納
          if(Object.keys(res.data.prev9).length > 0) {
            this.prevAnswerDataNo9 = res.data.prev9;
          } else {
            // 初期値を設定
            if(this.attribute == 1) {
              // 事業所
              this.prevAnswerDataNo9 = this.$options.data().answerDataNo9;
            } else if(this.is12City) {
              // 12都市
              this.prevAnswerDataNo9 = this.$options.data().sumDataNo9;
            } else {
              // 府県別
              this.prevAnswerDataNo9 = this.$options.data().prefSumDataNo9;
            }
          }

          // 前月輸入品データが存在する場合、データ格納
          if(Object.keys(res.data.prevImported).length > 0) {
            this.prevAnswerDataImported = res.data.prevImported;
          } else {
            // 初期値を設定
            if(this.attribute == 1) {
              // 事業所
              this.prevAnswerDataImported = this.$options.data().answerDataImported;
            } else if(this.is12City) {
              // 12都市
              this.prevAnswerDataImported = this.$options.data().sumDataImported;
            } else {
              // 府県別
              this.prevAnswerDataImported = this.$options.data().prefSumDataImported;
            }
          }

          Debug.log2("this.prevAnswerData:", this.prevAnswerData);
          Debug.log2("this.prevAnswerDataNo8:", this.prevAnswerDataNo8);
          Debug.log2("this.prevAnswerDataNo9:", this.prevAnswerDataNo9);
          Debug.log2("this.prevAnswerDataImported:", this.prevAnswerDataImported);
        }
      } catch (error) {
        // alert('情報を取得できませんでした。')
        Message.err(error, "情報を取得できませんでした");
      }

      // 合計用の最後の配列を切り離す
      this.sumAnswer9 = this.answerDataNo9.pop();
      this.prevSumAnswer9 = this.prevAnswerDataNo9.pop();
      this.sumAnswerImported = this.answerDataImported.pop();
      this.prevSumAnswerImported = this.prevAnswerDataImported.pop();

      // バリデーション
      this.$nextTick(() => {
        this.$refs.formConfirm.validate();
      });
      this.$nextTick(() => {
        this.$refs.form.validate();
      });

      this.loadingData = false;
    },

    // setDetailMessage: (v) => Number(v) === 0 ? true : Number(v) < 0 ? '減少' : '増加',
    setDetailMessage: (v) => {
      const n = v.replaceAll(',', '');
      return Number(n) === 0 ? true : Number(n) < 0 ? '減少' : '増加';
    },

    //-------------------------------------------------------------------------
    // ファイルダウンロード
    async download(file_info) {
      Debug.log("function[download]");

      // ダウンロード
      Download.download(file_info.Download_path)
        .then(res => {
          saveAs(res.data, file_info.File_name);
        })
        .catch((err) => {
          Message.err(err, "ダウンロードできませんでした");
        })

    },

    // 日付の下限チェック
    checkDateLower(limit, targetDate) {
      const tmpStartDate = limit;
      const tmpEndDate = targetDate;

      if (!tmpStartDate || !tmpEndDate) return true;

      const startDate = Util.convStringDate(tmpStartDate+'-01', '-');
      const endDatee = Util.convStringDate(tmpEndDate+'-01', '-');
      
      if (startDate >= endDatee) {
        this.isCheckDate = true;
        return dayjs(limit).format('YYYY年MM月')+'より後を設定してください';
      }
      this.isCheckDate = false;
      return true;
    },

    // 当月の前月末保管残高と前月の当月末保管残高のチェック
    chkPrevStorage(val, prev) {
      if(val == prev) {
        return true;
      } else {
        //return '前月の当月末保管残高と一致しません';
        return false;
      }
    },

    // 最小値チェック
    chkValueIsMin(val, min) {
      if(val >= min) {
        return true;
      } else {
        // 最小値より小さい値
        return false;
      }
    },

    isSelectAll() {
      if((this.answerList.length > 0) && (this.innerSelectedList.length > 0)) {
        let tmpAnswerdMemberNoList = Array.from(new Set(this.answeredList.map((item) => {
            return item.memberNo;
          })
        ));
        tmpAnswerdMemberNoList.sort();
        let tmpInnerSelectedList = this.innerSelectedList;
        tmpInnerSelectedList.sort();

        let result = ( tmpAnswerdMemberNoList.length === tmpInnerSelectedList.length ) && tmpInnerSelectedList.every( item => tmpAnswerdMemberNoList.includes( item ) ); 

        return result;
      } else {
        Debug.log("return false");
        return false;
      }
    },
  },

  watch: {
    calcVolumeMeasureDiff: function(){
    },

    // 当月 9号
    answerDataNo9: {
      handler: function () {
        let sum = this.sumAnswer9;
        // 合計の初期化
        sum.prevStorage = 0;
        sum.thisIn = 0;
        sum.thisOut = 0;
        sum.thisStorage = 0;

        this.answerDataNo9.forEach(function (itemData) {
          // 当月末保管残高の算出
          itemData.thisStorage = Number(itemData.prevStorage) + Number(itemData.thisIn) - itemData.thisOut;

          // 合計の算出
          sum.prevStorage += Number(itemData.prevStorage); // 前月末保管残高
          sum.thisIn += Number(itemData.thisIn); // 当月中入庫高
          sum.thisOut += Number(itemData.thisOut); // 当月中出庫高
          sum.thisStorage += Number(itemData.thisStorage); // 当月末保管残高
        });
      },
      deep: true,
    },

    // 前月 9号
    prevAnswerDataNo9: {
      handler: function () {
        let sum = this.prevSumAnswer9;
        // 合計の初期化
        sum.prevStorage = 0;
        sum.thisIn = 0;
        sum.thisOut = 0;
        sum.thisStorage = 0;

        this.prevAnswerDataNo9.forEach(function (itemData) {
          // 当月末保管残高の算出
          itemData.thisStorage = Number(itemData.prevStorage) + Number(itemData.thisIn) - itemData.thisOut;

          // 合計の算出
          sum.prevStorage += Number(itemData.prevStorage); // 前月末保管残高
          sum.thisIn += Number(itemData.thisIn); // 当月中入庫高
          sum.thisOut += Number(itemData.thisOut); // 当月中出庫高
          sum.thisStorage += Number(itemData.thisStorage); // 当月末保管残高
        });
      },
      deep: true,
    },

    // 当月 輸入物資
    answerDataImported: {
      handler: function () {
        let sum = this.sumAnswerImported;
        // 合計の初期化
        sum.prevStorage = 0;
        sum.thisIn = 0;
        sum.thisOut = 0;
        sum.thisStorage = 0;

        this.answerDataImported.forEach(function (itemData) {
          // 当月末保管残高の算出
          itemData.thisStorage = Number(itemData.prevStorage) + Number(itemData.thisIn) - itemData.thisOut;

          // 合計の算出
          sum.prevStorage += Number(itemData.prevStorage); // 前月末保管残高
          sum.thisIn += Number(itemData.thisIn); // 当月中入庫高
          sum.thisOut += Number(itemData.thisOut); // 当月中出庫高
          sum.thisStorage += Number(itemData.thisStorage); // 当月末保管残高
        });
      },
      deep: true,
    },

    // 前月 輸入物資
    prevAnswerDataImported: {
      handler: function () {
        let sum = this.prevSumAnswerImported;
        // 合計の初期化
        sum.prevStorage = 0;
        sum.thisIn = 0;
        sum.thisOut = 0;
        sum.thisStorage = 0;

        this.prevAnswerDataImported.forEach(function (itemData) {
          // 当月末保管残高の算出
          itemData.thisStorage = Number(itemData.prevStorage) + Number(itemData.thisIn) - itemData.thisOut;

          // 合計の算出
          sum.prevStorage += Number(itemData.prevStorage); // 前月末保管残高
          sum.thisIn += Number(itemData.thisIn); // 当月中入庫高
          sum.thisOut += Number(itemData.thisOut); // 当月中出庫高
          sum.thisStorage += Number(itemData.thisStorage); // 当月末保管残高
        });
      },
      deep: true,
    },

    is12City: function (newValue) {
      this.searchCondition.is12City = newValue;
      this.loadList();
    },
  },

  computed :{
    // すべて選択
    selectAll: {
      get: function () {
        Debug.log('selectAll get');
        if(this.attribute == 1) {
          return this.isSelectAll();
        } else {
          return this.answerList ? this.answerList.length > 0 && this.innerSelectedList.length == this.answerList.length : false;
        }
      },
      set: function (index) {
        let tmpSelectedList = [];
        if (index) {
          Debug.log('selectAll set');
          this.answerList.forEach(function (answerList) {
              if(this.attribute == 1) {
                // 都道府県協会
                if(answerList.status == 3) {
                  tmpSelectedList.push(answerList.memberNo);
                }
              } else if(this.is12City) {
                // 日本冷蔵倉庫協会(12都市)
                tmpSelectedList.push(answerList.cityCode);
              } else {
                // 日本冷蔵倉庫協会(府県別)
                tmpSelectedList.push(answerList.prefSumCd);
              }
            }, this);
        }
        this.innerSelectedList = tmpSelectedList;
      }
    },

    answeredList() {
      return this.answerList.filter((e) => (e.status == 3));
    },

    // テーブルヘッダー
    comHeaders() {
      if(this.attribute == 2) {
        // 日本冷蔵倉庫協会ユーザは都道府県協会のデータ
        if(this.is12City) {
          return this.headersAssociation;
        } else {
          return this.headersPref;
        }
      } else {
        // 都道府県協会ユーザは自協会に所属する事業所のデータ
        return this.headersMember;
      }
    },

    /** 自動計算 */
    // 当月 受寄物在貨容積
    answer8VolumeReceiptCalc() {
      if(this.attribute == 1) {
        // 都道府県協会ユーザ
        // (9号 当月末保管残高×2.5)+内容積建 ※小数点以下四捨五入
        const volumeReceipt = this.sumAnswer9.thisStorage * 2.5 + Number(this.answerDataNo8.volumeMeasure);
        if(isNaN(volumeReceipt)) return 0;
        return Util.orgRound(volumeReceipt, 1);
      } else {
        // 日本冷蔵倉庫協会ユーザ
        return this.answerDataNo8.volumeReceipt;
      }
    },
    // 前月 受寄物在貨容積
    prevAnswer8VolumeReceiptCalc() {
      if(this.attribute == 1) {
        // 都道府県協会ユーザ
        // (9号 当月末保管残高×2.5)+内容積建 ※小数点以下四捨五入
        const volumeReceipt = this.prevSumAnswer9.thisStorage * 2.5 + Number(this.prevAnswerDataNo8.volumeMeasure);
        if(isNaN(volumeReceipt)) return 0;
        return Util.orgRound(volumeReceipt, 1);
      } else {
        // 日本冷蔵倉庫協会ユーザ
        return this.prevAnswerDataNo8.volumeReceipt;
      }
    },

    // 当月 空容積
    answer8VolumeAvailableCalc() {
      // 所管容積-(受寄物在貨容積+自家貨物在貨容積)
      const volumeAvailable = this.answerDataNo8.volumeTotal - (Number(this.answer8VolumeReceiptCalc) + Number(this.answerDataNo8.volumePrivate));
      if(isNaN(volumeAvailable)) return 0;
      return volumeAvailable;
    },
    // 前月 空容積
    prevAnswer8VolumeAvailableCalc() {
      // 所管容積-(受寄物在貨容積+自家貨物在貨容積)
      const volumeAvailable = this.prevAnswerDataNo8.volumeTotal - (Number(this.prevAnswer8VolumeReceiptCalc) + Number(this.prevAnswerDataNo8.volumePrivate));
      if(isNaN(volumeAvailable)) return 0;
      return volumeAvailable;
    },

    /** データチェック */
    // 在貨容積 確認
    chkVolume() {
      // (当月末保管残高×2.5)+内容積建
      let res = (this.sumAnswer9.thisStorage * 2.5) + Number(this.answerDataNo8.volumeMeasure)
      return Util.orgRound(res, 1);
    },
    // 在貨容積確認 差分
    chkVolumeDiff() {
      // 在貨容積 確認-受寄物在貨容積
      return this.chkVolume - this.answerDataNo8.volumeReceipt;
    },
    // 受寄物在貨容積 確認
    chkVolumeReceipt() {
      // (当月末保管残高×2.5)+内容積建
      let res = 0;
      if(this.sumAnswer9.thisStorage != 0) {
        res = (this.sumAnswer9.thisStorage * 2.5) + Number(this.answerDataNo8.volumeMeasure);
      }
      return Util.orgRound(res, 1);
    },
    // 受寄物在貨容積 差分
    chkVolumeReceiptDiff() {
      // 受寄物在貨容積 確認-受寄物在貨容積
      let res = 0;
      if(this.chkVolumeReceipt != 0) {
        res = this.chkVolumeReceipt - this.answerDataNo8.volumeReceipt;
      }
      return res;
    },
    // 在庫トン数 確認
    chkStock() {
      // (受寄物在貨容積-内容積建)×0.4
      const res = (this.answerDataNo8.volumeReceipt - this.answerDataNo8.volumeMeasure) * 0.4;
      // 少数第1位で四捨五入
      return Util.orgRound(res, 1);
    },
    // 在庫トン数 差分
    chkStockDiff() {
      // 在庫トン数 確認-当月末保管残高
      return this.chkStock - this.sumAnswer9.thisStorage;
    },

    /** 前月増減 */
    // 所管容積 前月増減
    calcVolumeTotalDiff() {
      return Number(this.answerDataNo8.volumeTotal) - Number(this.prevAnswerDataNo8.volumeTotal);
    },
    // 受寄物在貨容積 前月増減
    calcVolumeReceiptDiff() {
      return Number(this.answerDataNo8.volumeReceipt) - Number(this.prevAnswerDataNo8.volumeReceipt);
    },
    // 自家貨物在貨容積 前月増減
    calcVolumePrivateDiff() {
      return Number(this.answerDataNo8.volumePrivate) - Number(this.prevAnswerDataNo8.volumePrivate);
    },
    // 空容積 前月増減
    calcVolumeAvailableDiff() {
      return Number(this.answerDataNo8.volumeAvailable) - Number(this.prevAnswerDataNo8.volumeAvailable);
    },
    // 内容積建 前月増減
    calcVolumeMeasureDiff() {
      return Number(this.answerDataNo8.volumeMeasure) - Number(this.prevAnswerDataNo8.volumeMeasure);
    },

    /** 9号月末算不一致 */
    chkCalculation9() {
      return (index) => {
        let res = '';
        if( this.answerDataNo9[index].thisStorage != (Number(this.answerDataNo9[index].prevStorage) + Number(this.answerDataNo9[index].thisIn) - this.answerDataNo9[index].thisOut) ) {
          // 当月末保管残高 と (前月末保管残高+当月中入庫高-当月中出庫高)が不一致
          res = '月末算不一致';
        }

        return res;
      }
    },
    chkPrevCalculation9() {
      return (index) => {
        let res = '';
        if( this.prevAnswerDataNo9[index].thisStorage != (Number(this.prevAnswerDataNo9[index].prevStorage) + Number(this.prevAnswerDataNo9[index].thisIn) - this.prevAnswerDataNo9[index].thisOut) ) {
          // 当月末保管残高 と (前月末保管残高+当月中入庫高-当月中出庫高)が不一致
          res = '月末算不一致';
        }

        return res;
      }
    },
    /** 合計行 9号月末算不一致 */
    chkSumCalculation9() {
      let res = '';
      if( this.sumAnswer9.thisStorage != (Number(this.sumAnswer9.prevStorage) + Number(this.sumAnswer9.thisIn) - this.sumAnswer9.thisOut) ) {
        // 当月末保管残高 と (前月末保管残高+当月中入庫高-当月中出庫高)が不一致
        res = '月末算不一致';
      }

      return res;
    },
    chkPrevSumCalculation9() {
      let res = '';
      if( this.prevSumAnswer9.thisStorage != (Number(this.prevSumAnswer9.prevStorage) + Number(this.prevSumAnswer9.thisIn) - this.prevSumAnswer9.thisOut) ) {
        // 当月末保管残高 と (前月末保管残高+当月中入庫高-当月中出庫高)が不一致
        res = '月末算不一致';
      }

      return res;
    },

    /** 輸入物資月末算不一致 */
    chkCalculationImported() {
      return (index) => {
        let res = '';
        if( this.answerDataImported[index].thisStorage != (Number(this.answerDataImported[index].prevStorage) + Number(this.answerDataImported[index].thisIn) - this.answerDataImported[index].thisOut) ) {
          // 当月末保管残高 と (前月末保管残高+当月中入庫高-当月中出庫高)が不一致
          res = '月末算不一致';
        }

        return res;
      }
    },
    chkPrevCalculationImported() {
      return (index) => {
        let res = '';
        if( this.prevAnswerDataImported[index].thisStorage != (Number(this.prevAnswerDataImported[index].prevStorage) + Number(this.prevAnswerDataImported[index].thisIn) - this.prevAnswerDataImported[index].thisOut) ) {
          // 当月末保管残高 と (前月末保管残高+当月中入庫高-当月中出庫高)が不一致
          res = '月末算不一致';
        }

        return res;
      }
    },
    /** 合計行 9号月末算不一致 */
    chkSumCalculationImported() {
      let res = '';
      if( this.sumAnswerImported.thisStorage != (Number(this.sumAnswerImported.prevStorage) + Number(this.sumAnswerImported.thisIn) - this.sumAnswerImported.thisOut) ) {
        // 当月末保管残高 と (前月末保管残高+当月中入庫高-当月中出庫高)が不一致
        res = '月末算不一致';
      }

      return res;
    },
    chkPrevSumCalculationImported() {
      let res = '';
      if( this.prevSumAnswerImported.thisStorage != (Number(this.prevSumAnswerImported.prevStorage) + Number(this.prevSumAnswerImported.thisIn) - this.prevSumAnswerImported.thisOut) ) {
        // 当月末保管残高 と (前月末保管残高+当月中入庫高-当月中出庫高)が不一致
        res = '月末算不一致';
      }

      return res;
    }

  }
};
</script>

<style scoped>

.disablecolor {
  background: #bbbbbb;
}

.input-color-red.v-text-field >>> input {
  color: #ff0000 !important;
}
/* 詳細エリアの文字色 */
.detail-info.v-input >>> .error--text {
    color: var(--v-accent-base) !important;
}
.detail-info.v-input >>> .v-input__slot::before{
    color: var(--v-accent-base) !important;
}

/* ホバー効果を無効化 */
.no-hover-effect >>> tr:hover {
  background-color: transparent !important;
}

/* ホバー効果 */
.hover-effect >>> tr:hover {
  background-color: #BBDEFB !important;
}

.table-wrap {
  overflow-x: auto;
  width: 100%;
}
.table-wrap >>> table {
  width: fit-content !important;
}
.table-wrap >>> .table-inner:first-of-type .v-data-table:not(.no8-diff-table) table {
  border-right: 1px solid;
}
.table-wrap >>> .table-inner:last-of-type .v-data-table:not(.no8-diff-table) table {
  border-left: 1px solid;
}
.table-wrap >>> .v-data-table__wrapper table {
  border: 2px solid;
}
td.no-border-bottom {
  border-bottom: none;
}
td.no-border-top {
  border-top: none;
}

/** 8号テーブル */
.no8-table >>> .v-data-table__wrapper th, td {
  width: 140px;
  min-width: 140px;
  padding: 0px 10px 0px 10px;
}
.no8-table .v-input {
  margin-top: 0px;
  padding-top: 0px;
}
/** 前月増減テーブル */
.no8-diff-table >>> .v-data-table__wrapper th, td {
  width: 140px;
  min-width: 140px;
  padding: 0px 10px 0px 10px;
}
.no8-diff-table .v-input {
  margin-top: 0px;
  padding-top: 0px;
}

/** 9号テーブル */
.no9-table >>> .v-data-table__wrapper th, td {
  width: 140px;
  min-width: 140px;
  padding: 0px 10px 0px 10px !important;
}

/* ぐるぐる */
.action-message {
  white-space: pre-line;
}
.spinner {
  margin: 50px;
}

/** ボタングループ */
.v-btn-toggle {
  border: 1px solid var(--v-primary-base);
}
/* .v-btn-toggle > .v-btn {
  margin: ;
} */

</style>
