<template>
  <v-form
      ref="form"
      v-model="valid"
      lazy-validation
  >
    <!-- 検索エリア -->
    <v-container>
      <v-row class="mt-2">
        <v-col cols="auto">
          <h2>{{ surveyType.filter((e) => (e.value == targetSurveyType))[0].text }} 回答データチェック</h2>
        </v-col>
        <v-col cols="auto">
          <YearPicker
            label="対象年度"
            v-model="searchCondition.targetYear"
            format="YYYY年"
            selectType="month"
            :clearable="false"
            :hideDetails="false"
            suffix="度"
            :changeEvt="search"
            :rules="[checkDateLower(factFindingCheckDate, searchCondition.targetYear)]"
          ></YearPicker>
        </v-col>
        <v-spacer></v-spacer>
        <v-col v-if="editMode" cols="3" sm="3" md="2" lg="2">
          <v-btn class="" color="primary" width="150" :disabled="loading" @click="cancel()">キャンセル</v-btn>
        </v-col>
        <v-col v-if="editMode" cols="3" sm="3" md="2" lg="2">
          <v-btn class="" color="primary" width="150" :disabled="loading" @click="registData()"><v-icon :small="true">mdi-check-bold</v-icon>回答データ保存</v-btn>
        </v-col>
        <v-col v-else cols="3" sm="3" md="2" lg="2">
          <v-btn class="" color="primary" width="150" :disabled="loading || (answerDataList.length <= 0) || isCheckDate" @click="editData()"><v-icon :small="true">mdi-border-color</v-icon>回答データ編集</v-btn>
        </v-col>
        <v-col cols="3" sm="3" md="2" lg="2">
          <v-btn class="" color="primary" width="150" :disabled="loading || editMode || (answerDataList.length <= 0) || isCheckDate" @click="goToSummary()">集計</v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <v-row>
        <v-col class="pt-0 pb-0 mb-n2">件数 {{answerDataCount}}件</v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12">
          <v-data-table
            :headers="headers"
            :items="answerDataList"
            :loading="loading"
            :dense='true'
            :items-per-page="10"
            :mobile-breakpoint="0"
            :disable-pagination='false'
            :hide-default-header='false'
            :hide-default-footer='false'
            :fixed-header='true'
            :disable-sort='false'
            locale="ja-jp"
            loading-text="読込中"
            no-data-text="データがありません。"
            class="table-border no-hover-effect"
            height="calc(100vh - 260px)"
            @update:options="$nextTick(() => { $refs.form.validate(); });"
          >
            <template v-slot:body="{items}">
              <!-- <thead>
                <tr class="header-row">
                  <th class="center" v-for="(header, i) in headers" :key="i" :style="'width:'+header.width+'px'">
                    {{header.text}}
                  </th>
                </tr>
              </thead> -->
              <tbody>
                <tr v-for="(item) in items"
                    :key="item.memberNo"
                    :class="['tr-data', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : 'enable']"
                    @click="rowEdit(item.memberNo)"
                >
                  <td :class="['center', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <input type='checkbox' v-model="item.disable" :true-value="1" :false-value="0" />
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    {{item.checkCount}}
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    {{item.prefCode}}
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    {{item.pref}}
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    {{item.memberNo}}
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    {{item.companyName}}
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    {{item.officeName}}
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.volume.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.volume"
                      :innerTable="true"
                      :readonly="!editMode"
                      :rules="[maxValLocaleJP(999999999), minValLocaleJP(0)]"
                      :orgRoundStep="1"
                    ></NumberTextField>
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.volumeValueGeneral.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.volumeValueGeneral"
                      :innerTable="true"
                      :readonly="!editMode"
                      :rules="[chkVolumeValueGeneral, maxValLocaleJP(9999999), minValLocaleJP(0)]"
                      :orgRoundStep="1"
                    ></NumberTextField>
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.volumeValueMeasure.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.volumeValueMeasure"
                      :innerTable="true"
                      :readonly="!editMode"
                      :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                      :orgRoundStep="1"
                    ></NumberTextField>
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.inValueTotal.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.inValueTotal"
                      :innerTable="true"
                      :readonly="!editMode"
                      :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                      :orgRoundStep="1"
                    ></NumberTextField>
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.inValueGeneral.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.inValueGeneral"
                      :innerTable="true"
                      :readonly="!editMode"
                      :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                      :orgRoundStep="1"
                    ></NumberTextField>
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.inValueMeasure.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.inValueMeasure"
                      :innerTable="true"
                      :readonly="!editMode"
                      :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                      :orgRoundStep="1"
                    ></NumberTextField>
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.outValueTotal.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.outValueTotal"
                      :innerTable="true"
                      :readonly="!editMode"
                      :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                      :orgRoundStep="1"
                    ></NumberTextField>
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.outValueGeneral.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.outValueGeneral"
                      :innerTable="true"
                      :readonly="!editMode"
                      :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                      :orgRoundStep="1"
                    ></NumberTextField>
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.outValueMeasure.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.outValueMeasure"
                      :innerTable="true"
                      :readonly="!editMode"
                      :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                      :orgRoundStep="1"
                    ></NumberTextField>
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.prevValueGeneral.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.prevValueGeneral"
                      :innerTable="true"
                      :readonly="!editMode"
                      :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                      :orgRoundStep="1"
                    ></NumberTextField>
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.interValueGeneral.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.interValueGeneral"
                      :innerTable="true"
                      :readonly="!editMode"
                      :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                      :orgRoundStep="1"
                    ></NumberTextField>
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.storageValueTotal.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.storageValueTotal"
                      :innerTable="true"
                      :readonly="!editMode"
                      :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                      :orgRoundStep="1"
                    ></NumberTextField>
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.storageValueGeneral.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.storageValueGeneral"
                      :innerTable="true"
                      :readonly="!editMode"
                      :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                      :orgRoundStep="1"
                    ></NumberTextField>
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.storageValueMeasure.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.storageValueMeasure"
                      :innerTable="true"
                      :readonly="!editMode"
                      :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                      :orgRoundStep="1"
                    ></NumberTextField>
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.cargoValueTotal.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.cargoValueTotal"
                      :innerTable="true"
                      :readonly="!editMode"
                      :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                      :orgRoundStep="1"
                    ></NumberTextField>
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.cargoValueGeneral.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.cargoValueGeneral"
                      :innerTable="true"
                      :readonly="!editMode"
                      :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                      :orgRoundStep="1"
                    ></NumberTextField>
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.cargoValueMeasure.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.cargoValueMeasure"
                      :innerTable="true"
                      :readonly="!editMode"
                      :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                      :orgRoundStep="1"
                    ></NumberTextField>
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.frozenValueGeneral.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.frozenValueGeneral"
                      :innerTable="true"
                      :readonly="!editMode"
                      :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                      :orgRoundStep="1"
                    ></NumberTextField>
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.workValueTotal.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.workValueTotal"
                      :innerTable="true"
                      :readonly="!editMode"
                      :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                      :orgRoundStep="1"
                    ></NumberTextField>
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.workValueGeneral.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.workValueGeneral"
                      :innerTable="true"
                      :readonly="!editMode"
                      :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                      :orgRoundStep="1"
                    ></NumberTextField>
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.workValueMeasure.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.workValueMeasure"
                      :innerTable="true"
                      :readonly="!editMode"
                      :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                      :orgRoundStep="1"
                    ></NumberTextField>
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.chargeValueTotal.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.chargeValueTotal"
                      :innerTable="true"
                      :readonly="!editMode"
                      :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                      :orgRoundStep="1"
                    ></NumberTextField>
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.chargeValueGeneral.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.chargeValueGeneral"
                      :innerTable="true"
                      :readonly="!editMode"
                      :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                      :orgRoundStep="1"
                    ></NumberTextField>
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.chargeValueMeasure.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.chargeValueMeasure"
                      :innerTable="true"
                      :readonly="!editMode"
                      :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                      :orgRoundStep="1"
                    ></NumberTextField>
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.processingValueTotal.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.processingValueTotal"
                      :innerTable="true"
                      :readonly="!editMode"
                      :rules="[maxValLocaleJP(9999999), minValLocaleJP(-9999999)]"
                      :orgRoundStep="1"
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.transportValueTotal.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.transportValueTotal"
                      :innerTable="true"
                      :readonly="!editMode"
                      :rules="[maxValLocaleJP(9999999), minValLocaleJP(-9999999)]"
                      :orgRoundStep="1"
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.resOtherValueTotal.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.resOtherValueTotal"
                      :innerTable="true"
                      :rules="[]"
                      readonly
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.resOtherValueGeneral.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.resOtherValueGeneral"
                      :innerTable="true"
                      :rules="[]"
                      readonly
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.resOtherValueMeasure.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.resOtherValueMeasure"
                      :innerTable="true"
                      :rules="[]"
                      readonly
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.resIncomeValueTotal.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.resIncomeValueTotal"
                      :innerTable="true"
                      :rules="[]"
                      readonly
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.resIncomeValueGeneral.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.resIncomeValueGeneral"
                      :innerTable="true"
                      :rules="[]"
                      readonly
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.resIncomeValueMeasure.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.resIncomeValueMeasure"
                      :innerTable="true"
                      :rules="[]"
                      readonly
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.workPartNum.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.workPartNum"
                      :innerTable="true"
                      :readonly="!editMode"
                      :rules="[maxValLocaleJP(99999), minValLocaleJP(0)]"
                      :orgRoundStep="10"
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.workNum.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.workNum"
                      :innerTable="true"
                      :readonly="!editMode"
                      :rules="[maxValLocaleJP(99999), minValLocaleJP(0)]"
                      :orgRoundStep="10"
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.clericalPartNum.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.clericalPartNum"
                      :innerTable="true"
                      :readonly="!editMode"
                      :rules="[maxValLocaleJP(99999), minValLocaleJP(0)]"
                      :orgRoundStep="10"
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.clericalNum.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.clericalNum"
                      :innerTable="true"
                      :readonly="!editMode"
                      :rules="[maxValLocaleJP(99999), minValLocaleJP(0)]"
                      :orgRoundStep="10"
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.manageNum.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.manageNum"
                      :innerTable="true"
                      :readonly="!editMode"
                      :rules="[maxValLocaleJP(99999), minValLocaleJP(0)]"
                      :orgRoundStep="10"
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.employeeTotalNum.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.employeeTotalNum"
                      :innerTable="true"
                      :readonly="!editMode"
                      :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                      :orgRoundStep="10"
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.workPartAveIncome.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.workPartAveIncome"
                      :innerTable="true"
                      :readonly="!editMode"
                      :rules="[maxValLocaleJP(99999), minValLocaleJP(0)]"
                      :orgRoundStep="1"
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.workAveIncome.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.workAveIncome"
                      :innerTable="true"
                      :readonly="!editMode"
                      :rules="[maxValLocaleJP(99999), minValLocaleJP(0)]"
                      :orgRoundStep="1"
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.clericalPartAveIncome.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.clericalPartAveIncome"
                      :innerTable="true"
                      :readonly="!editMode"
                      :rules="[maxValLocaleJP(99999), minValLocaleJP(0)]"
                      :orgRoundStep="1"
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.clericalAveIncome.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.clericalAveIncome"
                      :innerTable="true"
                      :readonly="!editMode"
                      :rules="[maxValLocaleJP(99999), minValLocaleJP(0)]"
                      :orgRoundStep="1"
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.manageAveIncome.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.manageAveIncome"
                      :innerTable="true"
                      :readonly="!editMode"
                      :rules="[maxValLocaleJP(99999), minValLocaleJP(0)]"
                      :orgRoundStep="1"
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.workAveAge.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.workAveAge"
                      :innerTable="true"
                      :readonly="!editMode"
                      :rules="[maxValLocaleJP(99), minValLocaleJP(0)]"
                      :orgRoundStep="10"
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.clericalAveAge.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.clericalAveAge"
                      :innerTable="true"
                      :readonly="!editMode"
                      :rules="[maxValLocaleJP(99), minValLocaleJP(0)]"
                      :orgRoundStep="10"
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.workAveContinuous.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.workAveContinuous"
                      :innerTable="true"
                      :readonly="!editMode"
                      :rules="[maxValLocaleJP(99), minValLocaleJP(0)]"
                      :orgRoundStep="10"
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.clericalAveContinuous.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.clericalAveContinuous"
                      :innerTable="true"
                      :readonly="!editMode"
                      :rules="[maxValLocaleJP(99), minValLocaleJP(0)]"
                      :orgRoundStep="10"
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.consignWorkNum.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.consignWorkNum"
                      :innerTable="true"
                      :readonly="!editMode"
                      :rules="[maxValLocaleJP(99999), minValLocaleJP(0)]"
                      :orgRoundStep="10"
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.consignClericalNum.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.consignClericalNum"
                      :innerTable="true"
                      :readonly="!editMode"
                      :rules="[maxValLocaleJP(99999), minValLocaleJP(0)]"
                      :orgRoundStep="10"
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.consignWorkAveIncome.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.consignWorkAveIncome"
                      :innerTable="true"
                      :readonly="!editMode"
                      :rules="[maxValLocaleJP(99999), minValLocaleJP(0)]"
                      :orgRoundStep="1"
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.consignClericalAveIncome.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.consignClericalAveIncome"
                      :innerTable="true"
                      :readonly="!editMode"
                      :rules="[maxValLocaleJP(99999), minValLocaleJP(0)]"
                      :orgRoundStep="1"
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.contractElectricity.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.contractElectricity"
                      :innerTable="true"
                      :readonly="!editMode"
                      :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                      :orgRoundStep="1"
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.usageElectricity.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.usageElectricity"
                      :innerTable="true"
                      :readonly="!editMode"
                      :rules="[maxValLocaleJP(99999999), minValLocaleJP(0)]"
                      :orgRoundStep="1"
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.paymentElectricity.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.paymentElectricity"
                      :innerTable="true"
                      :readonly="!editMode"
                      :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                      :orgRoundStep="1"
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.profitAndLoss.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.profitAndLoss"
                      :innerTable="true"
                      :readonly="!editMode"
                      :rules="[maxValLocaleJP(9999999), minValLocaleJP(-9999999)]"
                      :orgRoundStep="1"
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.numDock.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.numDock"
                      :innerTable="true"
                      :readonly="!editMode"
                      :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                      :orgRoundStep="1"
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <v-select
                      :items="[
                        { text: '×', value: 0 },
                        { text: '○', value: 1 },
                      ]"
                      v-model="item.refrigerator"
                      :readonly="!editMode"
                      :rules="[]"
                    >
                    </v-select>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.refrigeratorTemp.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.refrigeratorTemp"
                      :innerTable="true"
                      :readonly="!editMode"
                      :rules="[maxValLocaleJP(9999999), minValLocaleJP(-9999999)]"
                      :orgRoundStep="10"
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.numClient.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.numClient"
                      :innerTable="true"
                      :readonly="!editMode"
                      :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                      :orgRoundStep="1"
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.numNewClient.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.numNewClient"
                      :innerTable="true"
                      :readonly="!editMode"
                      :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                      :orgRoundStep="1"
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.usagePallet.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.usagePallet"
                      :innerTable="true"
                      :readonly="!editMode"
                      :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                      :orgRoundStep="1"
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.stockPallet}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.stockPallet"
                      :innerTable="true"
                      :readonly="!editMode"
                      :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                      :orgRoundStep="1"
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.stowageVertical.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.stowageVertical"
                      :innerTable="true"
                      :readonly="!editMode"
                      :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                      :orgRoundStep="10"
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.stowageHorizontal.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.stowageHorizontal"
                      :innerTable="true"
                      :readonly="!editMode"
                      :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                      :orgRoundStep="10"
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.stowageHeight.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.stowageHeight"
                      :innerTable="true"
                      :readonly="!editMode"
                      :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                      :orgRoundStep="1"
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.resVolumeFacility.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.resVolumeFacility"
                      :innerTable="true"
                      :rules="[]"
                      readonly
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.resMeasureFacility.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.resMeasureFacility"
                      :innerTable="true"
                      :rules="[]"
                      readonly
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.resInFacility.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.resInFacility"
                      :innerTable="true"
                      :rules="[]"
                      readonly
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.resOutFacility.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.resOutFacility"
                      :innerTable="true"
                      :rules="[]"
                      readonly
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.resPrevFacility.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.resPrevFacility"
                      :innerTable="true"
                      :rules="[]"
                      readonly
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.resInterFacility.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.resInterFacility"
                      :innerTable="true"
                      :rules="[]"
                      readonly
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.resRateStockYearAve.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.resRateStockYearAve"
                      :innerTable="true"
                      :rules="[chkResRateStockYearAve]"
                      readonly
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.resStockSimpleFacilityAve.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.resStockSimpleFacilityAve"
                      :innerTable="true"
                      :rules="[]"
                      readonly
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.resTurnoverYear.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.resTurnoverYear"
                      :innerTable="true"
                      :rules="[]"
                      readonly
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.resStockPrevInterAve.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.resStockPrevInterAve"
                      :innerTable="true"
                      :rules="[]"
                      readonly
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.resStockFacilityAve.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.resStockFacilityAve"
                      :innerTable="true"
                      :rules="[]"
                      readonly
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.resIncomeStorage.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.resIncomeStorage"
                      :innerTable="true"
                      :rules="[chkResIncomeStorage]"
                      readonly
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.resIncomeCargo.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.resIncomeCargo"
                      :innerTable="true"
                      :rules="[chkResIncomeCargo]"
                      readonly
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.resIncomeFrozen.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.resIncomeFrozen"
                      :innerTable="true"
                      :rules="[]"
                      readonly
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.resChargeWorkTotal.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.resChargeWorkTotal"
                      :innerTable="true"
                      :rules="[]"
                      readonly
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.resChargeWork.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.resChargeWork"
                      :innerTable="true"
                      :rules="[]"
                      readonly
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.resChargeVolume.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.resChargeVolume"
                      :innerTable="true"
                      :rules="[]"
                      readonly
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.resChargeTotal.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.resChargeTotal"
                      :innerTable="true"
                      :rules="[]"
                      readonly
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.resChargeGeneral.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.resChargeGeneral"
                      :innerTable="true"
                      :rules="[]"
                      readonly
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.resChargeMeasure.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.resChargeMeasure"
                      :innerTable="true"
                      :rules="[]"
                      readonly
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.resChargeProcessing.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.resChargeProcessing"
                      :innerTable="true"
                      :rules="[]"
                      readonly
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.resChargeTransport.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.resChargeTransport"
                      :innerTable="true"
                      :rules="[]"
                      readonly
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.resIncomeOtherTotal.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.resIncomeOtherTotal"
                      :innerTable="true"
                      :rules="[]"
                      readonly
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.resIncomeTotal.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.resIncomeTotal"
                      :innerTable="true"
                      :rules="[]"
                      readonly
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.resIncomeStorageFacility.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.resIncomeStorageFacility"
                      :innerTable="true"
                      :rules="[]"
                      readonly
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.resStorageNum.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.resStorageNum"
                      :innerTable="true"
                      :rules="[]"
                      readonly
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.resIncomeCargoFacility.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.resIncomeCargoFacility"
                      :innerTable="true"
                      :rules="[]"
                      readonly
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.resCargoNum.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.resCargoNum"
                      :innerTable="true"
                      :rules="[]"
                      readonly
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.resIncomeFacility.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.resIncomeFacility"
                      :innerTable="true"
                      :rules="[chkResIncomeFacility]"
                      readonly
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.resWorkTotal.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.resWorkTotal"
                      :innerTable="true"
                      :rules="[]"
                      readonly
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.resWorkEmployee.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.resWorkEmployee"
                      :innerTable="true"
                      :rules="[]"
                      readonly
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.resWorkConsign.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.resWorkConsign"
                      :innerTable="true"
                      :rules="[]"
                      readonly
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.resClericalTotal.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.resClericalTotal"
                      :innerTable="true"
                      :rules="[]"
                      readonly
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.resClericalEmployee.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.resClericalEmployee"
                      :innerTable="true"
                      :rules="[]"
                      readonly
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.resClericalConsign.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.resClericalConsign"
                      :innerTable="true"
                      :rules="[]"
                      readonly
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.resManageEmployee.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.resManageEmployee"
                      :innerTable="true"
                      :rules="[]"
                      readonly
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.resWorkerTotal.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.resWorkerTotal"
                      :innerTable="true"
                      :rules="[]"
                      readonly
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.resEmployeeTotal.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.resEmployeeTotal"
                      :innerTable="true"
                      :rules="[]"
                      readonly
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.resConsignTotal.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.resConsignTotal"
                      :innerTable="true"
                      :rules="[]"
                      readonly
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.resSalesPerson.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.resSalesPerson"
                      :innerTable="true"
                      :rules="[]"
                      readonly
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.resSalesFacility.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.resSalesFacility"
                      :innerTable="true"
                      :rules="[]"
                      readonly
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.resTonPerson.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.resTonPerson"
                      :innerTable="true"
                      :rules="[]"
                      readonly
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.resTonFacilityPerson.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.resTonFacilityPerson"
                      :innerTable="true"
                      :rules="[]"
                      readonly
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.resTonWork1.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.resTonWork1"
                      :innerTable="true"
                      :rules="[]"
                      readonly
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.resTonWork2.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.resTonWork2"
                      :innerTable="true"
                      :rules="[]"
                      readonly
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.resFacilityWork1.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.resFacilityWork1"
                      :innerTable="true"
                      :rules="[]"
                      readonly
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.resContractElectricityFacility.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.resContractElectricityFacility"
                      :innerTable="true"
                      :rules="[]"
                      readonly
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.resUsageElectricityFacility.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.resUsageElectricityFacility"
                      :innerTable="true"
                      :rules="[]"
                      readonly
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.resRateElectricity.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.resRateElectricity"
                      :innerTable="true"
                      :rules="[chkResRateElectricity]"
                      readonly
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.resRateElectricityFacility.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.resRateElectricityFacility"
                      :innerTable="true"
                      :rules="[]"
                      readonly
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.resPaymentElectricityFacility.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.resPaymentElectricityFacility"
                      :innerTable="true"
                      :rules="[]"
                      readonly
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.resUnitPriceElectricity.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.resUnitPriceElectricity"
                      :innerTable="true"
                      :rules="[chkResUnitPriceElectricity]"
                      readonly
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.resUnitPriceElectricityFacility.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.resUnitPriceElectricityFacility"
                      :innerTable="true"
                      :rules="[]"
                      readonly
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.resProfitAndLossFacility.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.resProfitAndLossFacility"
                      :innerTable="true"
                      :rules="[]"
                      readonly
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.resWeightPerPiece.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.resWeightPerPiece"
                      :innerTable="true"
                      :rules="[]"
                      readonly
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.resStowageCapacity.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.resStowageCapacity"
                      :innerTable="true"
                      :rules="[]"
                      readonly
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.resWeight.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.resWeight"
                      :innerTable="true"
                      :rules="[]"
                      readonly
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.resNumDockFacility.toLocaleString()}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.resNumDockFacility"
                      :innerTable="true"
                      :rules="[]"
                      readonly
                    ></NumberTextField>
                  </td>
                  <td :class="['left', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    {{item.resScale}}
                  </td>
                  <td :class="['left', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    {{item.resTurnover}}
                  </td>
                  <td :class="['left', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    {{item.resEmployee}}
                  </td>
                  <td :class="['left', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    {{item.resStockTurnover}}
                  </td>
                  <td :class="['left', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    {{item.resElectricityError}}
                  </td>
                  <td :class="['left', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    {{item.resSales}}
                  </td>
                  <td :class="['left', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    {{item.resPallet}}
                  </td>
                  <td :class="['left', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    {{item.area}}
                  </td>
                  <td :class="['left', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    {{item.resCompanyScale}}
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.resTonIncomeStorage}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.resTonIncomeStorage"
                      :innerTable="true"
                      :rules="[]"
                      readonly
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.resTonIncomeCargo}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.resTonIncomeCargo"
                      :innerTable="true"
                      :rules="[]"
                      readonly
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    <div class="value-align" v-if="item.checkCount == 0 && select_member_no != item.memberNo" >
                        {{item.capital}}
                    </div>
                    <NumberTextField v-else
                      v-model="item.capital"
                      :innerTable="true"
                      readonly
                      :rules="[]"
                    ></NumberTextField>
                  </td>
                  <td :class="['left', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    {{item.trendsNow == null ? '' : item.trendsNow == 0 ? '良い' : '悪い'}}
                  </td>
                  <td :class="['left', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    {{item.trendsNow == null ? '' : item.trendsFuture == 0 ? '良くなる' : '悪くなる'}}
                  </td>
                  <td :class="['left', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    {{item.trendsRemarks}}
                  </td>
                </tr>
                <!-- 合計 -->
                <tr class="tr-total">
                  <td colspan="7">
                    合計
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.volume.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.volumeValueGeneral.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.volumeValueMeasure.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.inValueTotal.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.inValueGeneral.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.inValueMeasure.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.outValueTotal.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.outValueGeneral.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.outValueMeasure.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.prevValueGeneral.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.interValueGeneral.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.storageValueTotal.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.storageValueGeneral.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.storageValueMeasure.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.cargoValueTotal.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.cargoValueGeneral.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.cargoValueMeasure.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.frozenValueGeneral.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.workValueTotal.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.workValueGeneral.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.workValueMeasure.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.chargeValueTotal.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.chargeValueGeneral.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.chargeValueMeasure.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.processingValueTotal.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.transportValueTotal.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.resOtherValueTotal.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.resOtherValueGeneral.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.resOtherValueMeasure.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.resIncomeValueTotal.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.resIncomeValueGeneral.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.resIncomeValueMeasure.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.workPartNum.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.workNum.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.clericalPartNum.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.clericalNum.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.manageNum.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.employeeTotalNum.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.workPartAveIncome.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.workAveIncome.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.clericalPartAveIncome.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.clericalAveIncome.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.manageAveIncome.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.workAveAge.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.clericalAveAge.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.workAveContinuous.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.clericalAveContinuous.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.consignWorkNum.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.consignClericalNum.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.consignWorkAveIncome.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.consignClericalAveIncome.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.contractElectricity.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.usageElectricity.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.paymentElectricity.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.profitAndLoss.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.numDock.toLocaleString()"></div>
                  </td>
                  <td>
                      <!--  -->
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.refrigeratorTemp.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.numClient.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.numNewClient.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.usagePallet.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.stockPallet.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.stowageVertical.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.stowageHorizontal.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.stowageHeight.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.resVolumeFacility.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.resMeasureFacility.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.resInFacility.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.resOutFacility.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.resPrevFacility.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.resInterFacility.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.resRateStockYearAve.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.resStockSimpleFacilityAve.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.resTurnoverYear.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.resStockPrevInterAve.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.resStockFacilityAve.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.resIncomeStorage.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.resIncomeCargo.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.resIncomeFrozen.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.resChargeWorkTotal.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.resChargeWork.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.resChargeVolume.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.resChargeTotal.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.resChargeGeneral.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.resChargeMeasure.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.resChargeProcessing.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.resChargeTransport.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.resIncomeOtherTotal.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.resIncomeTotal.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.resIncomeStorageFacility.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.resStorageNum.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.resIncomeCargoFacility.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.resCargoNum.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.resIncomeFacility.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.resWorkTotal.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.resWorkEmployee.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.resWorkConsign.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.resClericalTotal.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.resClericalEmployee.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.resClericalConsign.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.resManageEmployee.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.resWorkerTotal.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.resEmployeeTotal.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.resConsignTotal.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.resSalesPerson.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.resSalesFacility.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.resTonPerson.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.resTonFacilityPerson.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.resTonWork1.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resTotal.resTonWork2.toLocaleString()"></div>
                  </td>
                  <td>
                    <div class="value-align" v-text="resTotal.resFacilityWork1.toLocaleString()"></div>
                  </td>
                  <td>
                    <div class="value-align" v-text="resTotal.resContractElectricityFacility.toLocaleString()"></div>
                  </td>
                  <td>
                    <div class="value-align" v-text="resTotal.resUsageElectricityFacility.toLocaleString()"></div>
                  </td>
                  <td>
                    <div class="value-align" v-text="resTotal.resRateElectricity.toLocaleString()"></div>
                  </td>
                  <td>
                    <div class="value-align" v-text="resTotal.resRateElectricityFacility.toLocaleString()"></div>
                  </td>
                  <td>
                    <div class="value-align" v-text="resTotal.resPaymentElectricityFacility.toLocaleString()"></div>
                  </td>
                  <td>
                    <div class="value-align" v-text="resTotal.resUnitPriceElectricity.toLocaleString()"></div>
                  </td>
                  <td>
                    <div class="value-align" v-text="resTotal.resUnitPriceElectricityFacility.toLocaleString()"></div>
                  </td>
                  <td>
                    <div class="value-align" v-text="resTotal.resProfitAndLossFacility.toLocaleString()"></div>
                  </td>
                  <td>
                    <div class="value-align" v-text="resTotal.resWeightPerPiece.toLocaleString()"></div>
                  </td>
                  <td>
                    <div class="value-align" v-text="resTotal.resStowageCapacity.toLocaleString()"></div>
                  </td>
                  <td>
                    <div class="value-align" v-text="resTotal.resWeight.toLocaleString()"></div>
                  </td>
                  <td>
                    <div class="value-align" v-text="resTotal.resNumDockFacility.toLocaleString()"></div>
                  </td>
                  <td colspan="15" rowspan="3" class="td-blank">
                  </td>
                </tr>
                <!-- 平均 -->
                <tr class="tr-ave">
                  <td colspan="7">
                    平均
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.volume.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.volumeValueGeneral.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.volumeValueMeasure.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.inValueTotal.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.inValueGeneral.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.inValueMeasure.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.outValueTotal.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.outValueGeneral.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.outValueMeasure.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.prevValueGeneral.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.interValueGeneral.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.storageValueTotal.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.storageValueGeneral.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.storageValueMeasure.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.cargoValueTotal.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.cargoValueGeneral.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.cargoValueMeasure.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.frozenValueGeneral.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.workValueTotal.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.workValueGeneral.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.workValueMeasure.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.chargeValueTotal.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.chargeValueGeneral.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.chargeValueMeasure.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.processingValueTotal.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.transportValueTotal.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.resOtherValueTotal.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.resOtherValueGeneral.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.resOtherValueMeasure.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.resIncomeValueTotal.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.resIncomeValueGeneral.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.resIncomeValueMeasure.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.workPartNum.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.workNum.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.clericalPartNum.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.clericalNum.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.manageNum.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.employeeTotalNum.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.workPartAveIncome.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.workAveIncome.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.clericalPartAveIncome.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.clericalAveIncome.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.manageAveIncome.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.workAveAge.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.clericalAveAge.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.workAveContinuous.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.clericalAveContinuous.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.consignWorkNum.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.consignClericalNum.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.consignWorkAveIncome.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.consignClericalAveIncome.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.contractElectricity.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.usageElectricity.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.paymentElectricity.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.profitAndLoss.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.numDock.toLocaleString()"></div>
                  </td>
                  <td>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.refrigeratorTemp.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.numClient.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.numNewClient.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.usagePallet.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.stockPallet.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.stowageVertical.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.stowageHorizontal.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.stowageHeight.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.resVolumeFacility.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.resMeasureFacility.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.resInFacility.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.resOutFacility.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.resPrevFacility.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.resInterFacility.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.resRateStockYearAve.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.resStockSimpleFacilityAve.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.resTurnoverYear.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.resStockPrevInterAve.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.resStockFacilityAve.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.resIncomeStorage.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.resIncomeCargo.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.resIncomeFrozen.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.resChargeWorkTotal.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.resChargeWork.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.resChargeVolume.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.resChargeTotal.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.resChargeGeneral.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.resChargeMeasure.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.resChargeProcessing.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.resChargeTransport.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.resIncomeOtherTotal.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.resIncomeTotal.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.resIncomeStorageFacility.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.resStorageNum.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.resIncomeCargoFacility.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.resCargoNum.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.resIncomeFacility.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.resWorkTotal.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.resWorkEmployee.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.resWorkConsign.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.resClericalTotal.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.resClericalEmployee.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.resClericalConsign.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.resManageEmployee.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.resWorkerTotal.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.resEmployeeTotal.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.resConsignTotal.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.resSalesPerson.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.resSalesFacility.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.resTonPerson.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.resTonFacilityPerson.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.resTonWork1.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="resAve.resTonWork2.toLocaleString()"></div>
                  </td>
                  <td>
                    <div class="value-align" v-text="resAve.resFacilityWork1.toLocaleString()"></div>
                  </td>
                  <td>
                    <div class="value-align" v-text="resAve.resContractElectricityFacility.toLocaleString()"></div>
                  </td>
                  <td>
                    <div class="value-align" v-text="resAve.resUsageElectricityFacility.toLocaleString()"></div>
                  </td>
                  <td>
                    <div class="value-align" v-text="resAve.resRateElectricity.toLocaleString()"></div>
                  </td>
                  <td>
                    <div class="value-align" v-text="resAve.resRateElectricityFacility.toLocaleString()"></div>
                  </td>
                  <td>
                    <div class="value-align" v-text="resAve.resPaymentElectricityFacility.toLocaleString()"></div>
                  </td>
                  <td>
                    <div class="value-align" v-text="resAve.resUnitPriceElectricity.toLocaleString()"></div>
                  </td>
                  <td>
                    <div class="value-align" v-text="resAve.resUnitPriceElectricityFacility.toLocaleString()"></div>
                  </td>
                  <td>
                    <div class="value-align" v-text="resAve.resProfitAndLossFacility.toLocaleString()"></div>
                  </td>
                  <td>
                    <div class="value-align" v-text="resAve.resWeightPerPiece.toLocaleString()"></div>
                  </td>
                  <td>
                    <div class="value-align" v-text="resAve.resStowageCapacity.toLocaleString()"></div>
                  </td>
                  <td>
                    <div class="value-align" v-text="resAve.resWeight.toLocaleString()"></div>
                  </td>
                  <td>
                    <div class="value-align" v-text="resAve.resNumDockFacility.toLocaleString()"></div>
                  </td>
                  <td colspan="15" rowspan="3" class="td-blank">
                  </td>

                </tr>
                <!-- 1000設備㌧当り -->
                <tr class="tr-facility">
                  <td colspan="7">
                    1000設備㌧当り
                  </td>
                  <td>
                  </td>
                  <td>
                  </td>
                  <td>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.inValueTotal.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.inValueGeneral.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.inValueMeasure.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.outValueTotal.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.outValueGeneral.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.outValueMeasure.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.prevValueGeneral.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.interValueGeneral.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.storageValueTotal.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.storageValueGeneral.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.storageValueMeasure.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.cargoValueTotal.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.cargoValueGeneral.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.cargoValueMeasure.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.frozenValueGeneral.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.workValueTotal.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.workValueGeneral.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.workValueMeasure.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.chargeValueTotal.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.chargeValueGeneral.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.chargeValueMeasure.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.processingValueTotal.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.transportValueTotal.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.resOtherValueTotal.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.resOtherValueGeneral.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.resOtherValueMeasure.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.resIncomeValueTotal.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.resIncomeValueGeneral.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.resIncomeValueMeasure.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.workPartNum.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.workNum.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.clericalPartNum.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.clericalNum.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.manageNum.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.employeeTotalNum.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.workPartAveIncome.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.workAveIncome.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.clericalPartAveIncome.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.clericalAveIncome.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.manageAveIncome.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.workAveAge.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.clericalAveAge.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.workAveContinuous.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.clericalAveContinuous.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.consignWorkNum.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.consignClericalNum.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.consignWorkAveIncome.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.consignClericalAveIncome.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.contractElectricity.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.usageElectricity.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.paymentElectricity.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.profitAndLoss.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.numDock.toLocaleString()"></div>
                  </td>
                  <td>
                  </td>
                  <td>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.numClient.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.numNewClient.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.usagePallet.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.stockPallet.toLocaleString()"></div>
                  </td>
                  <td>
                  </td>
                  <td>
                  </td>
                  <td>
                  </td>
                  <td>
                  </td>
                  <td>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.resInFacility.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.resOutFacility.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.resPrevFacility.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.resInterFacility.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.resRateStockYearAve.toLocaleString()"></div>
                  </td>
                  <td>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.resTurnoverYear.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.resStockPrevInterAve.toLocaleString()"></div>
                  </td>
                  <td>
                  </td>
                  <td>
                  </td>
                  <td>
                  </td>
                  <td>
                  </td>
                  <td>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.resChargeWork.toLocaleString()"></div>
                  </td>
                  <td>
                  </td>
                  <td>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.resChargeGeneral.toLocaleString()"></div>
                  </td>
                  <td>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.resChargeProcessing.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.resChargeTransport.toLocaleString()"></div>
                  </td>
                  <td>
                  </td>
                  <td>
                  </td>
                  <td>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.resStorageNum.toLocaleString()"></div>
                  </td>
                  <td>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.resCargoNum.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.resIncomeFacility.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.resWorkTotal.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.resWorkEmployee.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.resWorkConsign.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.resClericalTotal.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.resClericalEmployee.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.resClericalConsign.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.resManageEmployee.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.resWorkerTotal.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.resEmployeeTotal.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.resConsignTotal.toLocaleString()"></div>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.resSalesPerson.toLocaleString()"></div>
                  </td>
                  <td>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.resTonPerson.toLocaleString()"></div>
                  </td>
                  <td>
                  </td>
                  <td>
                      <div class="value-align" v-text="calcFacility.resTonWork1.toLocaleString()"></div>
                  </td>
                  <td>
                  </td>
                  <td>
                    <div class="value-align" v-text="calcFacility.resFacilityWork1.toLocaleString()"></div>
                  </td>
                  <td>
                    <div class="value-align" v-text="calcFacility.resContractElectricityFacility.toLocaleString()"></div>
                  </td>
                  <td>
                    <div class="value-align" v-text="calcFacility.resUsageElectricityFacility.toLocaleString()"></div>
                  </td>
                  <td>
                    <div class="value-align" v-text="calcFacility.resRateElectricity.toLocaleString()"></div>
                  </td>
                  <td>
                    <div class="value-align" v-text="calcFacility.resPaymentElectricityFacility.toLocaleString()"></div>
                  </td>
                  <td>
                  </td>
                  <td>
                    <div class="value-align" v-text="calcFacility.resUnitPriceElectricity.toLocaleString()"></div>
                  </td>
                  <td>
                  </td>
                  <td>
                    <div class="value-align" v-text="calcFacility.resProfitAndLossFacility.toLocaleString()"></div>
                  </td>
                  <td>
                    <div class="value-align" v-text="calcFacility.resWeightPerPiece.toLocaleString()"></div>
                  </td>
                  <td>
                  </td>
                  <td>
                    <div class="value-align" v-text="calcFacility.resWeight.toLocaleString()"></div>
                  </td>
                  <td>
                    <div class="value-align" v-text="calcFacility.resNumDockFacility.toLocaleString()"></div>
                  </td>
                  <td colspan="15" rowspan="3" class="td-blank">
                  </td>

                </tr>
              </tbody>
            </template>

          </v-data-table>
        </v-col>
      </v-row>


    </v-container>
  </v-form>
</template>

<script>
import Debug from '../Lib/Debug'
import Util from '../Lib/Util'
import YearPicker from "./common/YearPicker.vue";
// import CvTextField from './Cv_text_field.vue'
import NumberTextField from './common/Number_text_field.vue'
import Message from "../Lib/Message";
import dayjs from "dayjs";
import Const from '../mixins/const';

import FactFinding from "../model/factFinding";
import FactFindingAnswer from "../model/factFindingAnswer";

export default {
  mixins: [Const],

  components: {
    YearPicker,
    // CvTextField,
    NumberTextField,
  },

  data: () => ({
    testValue: 12345,

    valid : true,
    loading: false,

    show: true,

    editMode: false,

    // 調査種別リスト
    surveyType: [
      {text: "月次報告データ", value: 1},
      {text: "実態調査", value: 2},
      {text: "電力調査", value: 3},
      {text: "冷媒調査", value: 4},
    ],

    // 対象調査種別
    targetSurveyType: null,

    // 検索条件
    searchCondition: {
      targetYear: "2022",
    },

    // テーブルヘッダー
    headers: [
        { text: '除外', align: 'center', sortable: true, value: 'disable', width: 60 },
        { text: 'NG判定', align: 'center', sortable: true, value: 'checkCount', width: 60 },
        { text: '府県 コード', align: 'center', sortable: true, value: 'prefCode', width: 60 },
        { text: '府県名', align: 'center', sortable: true, value: 'pref', width: 80 },
        { text: '会員番号', align: 'center', sortable: true, value: 'memberNo', width: 80 },
        { text: '会社名', align: 'center', sortable: true, value: 'companyName', width: 150 },
        { text: '事業所名', align: 'center', sortable: true, value: 'officeName', width: 150 },
        { text: '所管容積(㎥)', align: 'center', sortable: true, value: 'volume', width: 120 },
        { text: '一般保管1', align: 'center', sortable: true, value: 'volumeValueGeneral', width: 120 },
        { text: '容積建1', align: 'center', sortable: true, value: 'volumeValueMeasure', width: 120 },
        { text: '年間入庫', align: 'center', sortable: true, value: 'inValueTotal', width: 120 },
        { text: '入一般', align: 'center', sortable: true, value: 'inValueGeneral', width: 120 },
        { text: '入容積建', align: 'center', sortable: true, value: 'inValueMeasure', width: 120 },
        { text: '年間出庫', align: 'center', sortable: true, value: 'outValueTotal', width: 120 },
        { text: '出一般', align: 'center', sortable: true, value: 'outValueGeneral', width: 120 },
        { text: '出容積', align: 'center', sortable: true, value: 'outValueMeasure', width: 120 },
        { text: '前月繰越', align: 'center', sortable: true, value: 'prevValueGeneral', width: 120 },
        { text: '中間繰越', align: 'center', sortable: true, value: 'interValueGeneral', width: 120 },
        { text: '保管料収入', align: 'center', sortable: true, value: 'storageValueTotal', width: 120 },
        { text: '保管料一般', align: 'center', sortable: true, value: 'storageValueGeneral', width: 120 },
        { text: '保管料容積', align: 'center', sortable: true, value: 'storageValueMeasure', width: 120 },
        { text: '荷役料収入', align: 'center', sortable: true, value: 'cargoValueTotal', width: 120 },
        { text: '荷役料一般', align: 'center', sortable: true, value: 'cargoValueGeneral', width: 120 },
        { text: '荷役料容積', align: 'center', sortable: true, value: 'cargoValueMeasure', width: 120 },
        { text: '凍結料', align: 'center', sortable: true, value: 'frozenValueGeneral', width: 120 },
        { text: 'その他作業料合計', align: 'center', sortable: true, value: 'workValueTotal', width: 120 },
        { text: 'その他作業料一般', align: 'center', sortable: true, value: 'workValueGeneral', width: 120 },
        { text: 'その他作業料容積', align: 'center', sortable: true, value: 'workValueMeasure', width: 120 },
        { text: 'その他手数料合計', align: 'center', sortable: true, value: 'chargeValueTotal', width: 120 },
        { text: 'その他手数料一般', align: 'center', sortable: true, value: 'chargeValueGeneral', width: 120 },
        { text: 'その他手数料容積', align: 'center', sortable: true, value: 'chargeValueMeasure', width: 120 },
        { text: '流通加工', align: 'center', sortable: true, value: 'processingValueTotal', width: 120 },
        { text: '利用運送', align: 'center', sortable: true, value: 'transportValueTotal', width: 120 },
        { text: 'その他収入合計', align: 'center', sortable: true, value: 'resOtherValueTotal', width: 120 },
        { text: 'その他収入一般', align: 'center', sortable: true, value: 'resOtherValueGeneral', width: 120 },
        { text: 'その他収入容積', align: 'center', sortable: true, value: 'resOtherValueMeasure', width: 120 },
        { text: '収入合計', align: 'center', sortable: true, value: 'resIncomeValueTotal', width: 120 },
        { text: '収入合計一般', align: 'center', sortable: true, value: 'resIncomeValueGeneral', width: 120 },
        { text: '収入合計容積', align: 'center', sortable: true, value: 'resIncomeValueMeasure', width: 120 },
        { text: '作業パート人数', align: 'center', sortable: true, value: 'workPartNum', width: 120 },
        { text: '作業専任人数', align: 'center', sortable: true, value: 'workNum', width: 120 },
        { text: '事務パート人数', align: 'center', sortable: true, value: 'clericalPartNum', width: 120 },
        { text: '事務人数', align: 'center', sortable: true, value: 'clericalNum', width: 120 },
        { text: '社員管理者', align: 'center', sortable: true, value: 'manageNum', width: 120 },
        { text: '社員合計', align: 'center', sortable: true, value: 'employeeTotalNum', width: 120 },
        { text: '作業パート賃金', align: 'center', sortable: true, value: 'workPartAveIncome', width: 120 },
        { text: '作業専任賃金', align: 'center', sortable: true, value: 'workAveIncome', width: 120 },
        { text: '事務パート賃金', align: 'center', sortable: true, value: 'clericalPartAveIncome', width: 120 },
        { text: '事務賃金', align: 'center', sortable: true, value: 'clericalAveIncome', width: 120 },
        { text: '社員管理者賃金', align: 'center', sortable: true, value: 'manageAveIncome', width: 120 },
        { text: '作業専任平均年齢', align: 'center', sortable: true, value: 'workAveAge', width: 120 },
        { text: '事務平均年齢', align: 'center', sortable: true, value: 'clericalAveAge', width: 120 },
        { text: '作業専任平均勤続年数', align: 'center', sortable: true, value: 'workAveContinuous', width: 120 },
        { text: '事務平均勤続年数', align: 'center', sortable: true, value: 'clericalAveContinuous', width: 120 },
        { text: '下請現場人数', align: 'center', sortable: true, value: 'consignWorkNum', width: 120 },
        { text: '下請事務人数', align: 'center', sortable: true, value: 'consignClericalNum', width: 120 },
        { text: '下請現場賃金', align: 'center', sortable: true, value: 'consignWorkAveIncome', width: 120 },
        { text: '下請事務賃金', align: 'center', sortable: true, value: 'consignClericalAveIncome', width: 120 },
        { text: '契約電力', align: 'center', sortable: true, value: 'contractElectricity', width: 120 },
        { text: '電力使用量', align: 'center', sortable: true, value: 'usageElectricity', width: 120 },
        { text: '電力支払額', align: 'center', sortable: true, value: 'paymentElectricity', width: 120 },
        { text: '業務損益', align: 'center', sortable: true, value: 'profitAndLoss', width: 120 },
        { text: 'バース数', align: 'center', sortable: true, value: 'numDock', width: 120 },
        { text: '低温化', align: 'center', sortable: true, value: 'refrigerator', width: 120 },
        { text: '設定温度', align: 'center', sortable: true, value: 'refrigeratorTemp', width: 120 },
        { text: '取引先数', align: 'center', sortable: true, value: 'numClient', width: 120 },
        { text: '新規数', align: 'center', sortable: true, value: 'numNewClient', width: 120 },
        { text: '使用パレット', align: 'center', sortable: true, value: 'usagePallet', width: 120 },
        { text: '実在庫量', align: 'center', sortable: true, value: 'stockPallet', width: 120 },
        { text: 'P.L', align: 'center', sortable: true, value: 'stowageVertical', width: 120 },
        { text: 'P.W', align: 'center', sortable: true, value: 'stowageHorizontal', width: 120 },
        { text: 'P.H', align: 'center', sortable: true, value: 'stowageHeight', width: 120 },
        { text: '所管容積設備㌧', align: 'center', sortable: true, value: 'resVolumeFacility', width: 120 },
        { text: '除容積建設備能力', align: 'center', sortable: true, value: 'resMeasureFacility', width: 120 },
        { text: '年間入庫量1000設備㌧', align: 'center', sortable: true, value: 'resInFacility', width: 120 },
        { text: '年間出庫量1000設備㌧', align: 'center', sortable: true, value: 'resOutFacility', width: 120 },
        { text: '前月繰越数量1000設備㌧', align: 'center', sortable: true, value: 'resPrevFacility', width: 120 },
        { text: '中間繰越数量1000設備㌧', align: 'center', sortable: true, value: 'resInterFacility', width: 120 },
        { text: '在庫率年平均%', align: 'center', sortable: true, value: 'resRateStockYearAve', width: 120 },
        { text: '単純設備㌧平均在庫', align: 'center', sortable: true, value: 'resStockSimpleFacilityAve', width: 120 },
        { text: '回転数年間(回)', align: 'center', sortable: true, value: 'resTurnoverYear', width: 120 },
        { text: '(前月+中間)/2平均在庫', align: 'center', sortable: true, value: 'resStockPrevInterAve', width: 120 },
        { text: '平均在庫1000設備㌧', align: 'center', sortable: true, value: 'resStockFacilityAve', width: 120 },
        { text: '保管料収入(￥/設備㌧)', align: 'center', sortable: true, value: 'resIncomeStorage', width: 120 },
        { text: '荷役料収入(￥/設備㌧)', align: 'center', sortable: true, value: 'resIncomeCargo', width: 120 },
        { text: '凍結料収入(￥/設備㌧)', align: 'center', sortable: true, value: 'resIncomeFrozen', width: 120 },
        { text: '他作業料計(￥/設備㌧)', align: 'center', sortable: true, value: 'resChargeWorkTotal', width: 120 },
        { text: '他作業料一(￥/設備㌧)', align: 'center', sortable: true, value: 'resChargeWork', width: 120 },
        { text: '他作業容積(￥/設備㌧)', align: 'center', sortable: true, value: 'resChargeVolume', width: 120 },
        { text: '他手数料計(￥/設備㌧)', align: 'center', sortable: true, value: 'resChargeTotal', width: 120 },
        { text: '他手数一般(￥/設備㌧)', align: 'center', sortable: true, value: 'resChargeGeneral', width: 120 },
        { text: '他手数容積(￥/設備㌧)', align: 'center', sortable: true, value: 'resChargeMeasure', width: 120 },
        { text: '流通加工(￥/設備㌧)', align: 'center', sortable: true, value: 'resChargeProcessing', width: 120 },
        { text: '利用運送(￥/設備㌧)', align: 'center', sortable: true, value: 'resChargeTransport', width: 120 },
        { text: '他収入合計(￥/設備㌧)', align: 'center', sortable: true, value: 'resIncomeOtherTotal', width: 120 },
        { text: '収入合計(￥/設備㌧)', align: 'center', sortable: true, value: 'resIncomeTotal', width: 120 },
        { text: '保管収入㌧1000設備㌧', align: 'center', sortable: true, value: 'resIncomeStorageFacility', width: 120 },
        { text: '保管積数(kg/設備㌧)', align: 'center', sortable: true, value: 'resStorageNum', width: 120 },
        { text: '荷役収入㌧1000設備㌧', align: 'center', sortable: true, value: 'resIncomeCargoFacility', width: 120 },
        { text: '荷役積数(kg/設備㌧)', align: 'center', sortable: true, value: 'resCargoNum', width: 120 },
        { text: '設備㌧収入小計(￥/設備㌧)', align: 'center', sortable: true, value: 'resIncomeFacility', width: 120 },
        { text: '現場従業員1000設備㌧', align: 'center', sortable: true, value: 'resWorkTotal', width: 120 },
        { text: '(現場社員)1000設備㌧', align: 'center', sortable: true, value: 'resWorkEmployee', width: 120 },
        { text: '(現場委託)1000設備㌧', align: 'center', sortable: true, value: 'resWorkConsign', width: 120 },
        { text: '事務所従業員1000設備㌧', align: 'center', sortable: true, value: 'resClericalTotal', width: 120 },
        { text: '(事務社員)1000設備㌧', align: 'center', sortable: true, value: 'resClericalEmployee', width: 120 },
        { text: '(事務委託)1000設備㌧', align: 'center', sortable: true, value: 'resClericalConsign', width: 120 },
        { text: '社員管理者1000設備㌧', align: 'center', sortable: true, value: 'resManageEmployee', width: 120 },
        { text: '従業員合計1000設備㌧', align: 'center', sortable: true, value: 'resWorkerTotal', width: 120 },
        { text: '(社員計)1000設備㌧', align: 'center', sortable: true, value: 'resEmployeeTotal', width: 120 },
        { text: '(外部委託計)1000設備㌧', align: 'center', sortable: true, value: 'resConsignTotal', width: 120 },
        { text: '一人当り売上(千円/人)', align: 'center', sortable: true, value: 'resSalesPerson', width: 120 },
        { text: '一人当り売上1000設備㌧', align: 'center', sortable: true, value: 'resSalesFacility', width: 120 },
        { text: '一人当り扱量(t/年人)', align: 'center', sortable: true, value: 'resTonPerson', width: 120 },
        { text: '一人当り扱量1000設備㌧', align: 'center', sortable: true, value: 'resTonFacilityPerson', width: 120 },
        { text: '現場従業員一人当り扱量1', align: 'center', sortable: true, value: 'resTonWork1', width: 120 },
        { text: '現場従業員一人当り扱量2', align: 'center', sortable: true, value: 'resTonWork2', width: 120 },
        { text: '1従業員当り設備㌧', align: 'center', sortable: true, value: 'resFacilityWork1', width: 120 },
        { text: '契約電力設備㌧', align: 'center', sortable: true, value: 'resContractElectricityFacility', width: 120 },
        { text: '電力使用量設備㌧', align: 'center', sortable: true, value: 'resUsageElectricityFacility', width: 120 },
        { text: '契約電力有効率', align: 'center', sortable: true, value: 'resRateElectricity', width: 120 },
        { text: '電力有効率設備㌧', align: 'center', sortable: true, value: 'resRateElectricityFacility', width: 120 },
        { text: '電力支払額設備㌧', align: 'center', sortable: true, value: 'resPaymentElectricityFacility', width: 120 },
        { text: 'KWH単価', align: 'center', sortable: true, value: 'resUnitPriceElectricity', width: 120 },
        { text: 'KWH単価設備㌧', align: 'center', sortable: true, value: 'resUnitPriceElectricityFacility', width: 120 },
        { text: '業務損益設備㌧', align: 'center', sortable: true, value: 'resProfitAndLossFacility', width: 120 },
        { text: '1P当重量kg', align: 'center', sortable: true, value: 'resWeightPerPiece', width: 120 },
        { text: 'P積付容積', align: 'center', sortable: true, value: 'resStowageCapacity', width: 120 },
        { text: '貨物比重', align: 'center', sortable: true, value: 'resWeight', width: 120 },
        { text: 'バース数1000設備㌧', align: 'center', sortable: true, value: 'resNumDockFacility', width: 120 },
        { text: '規模', align: 'center', sortable: true, value: 'resScale', width: 100 },
        { text: '回転', align: 'center', sortable: true, value: 'resTurnover', width: 100 },
        { text: '従業員', align: 'center', sortable: true, value: 'resEmployee', width: 100 },
        { text: '在庫回転', align: 'center', sortable: true, value: 'resStockTurnover', width: 100 },
        { text: '電力異常', align: 'center', sortable: true, value: 'resElectricityError', width: 100 },
        { text: '売上', align: 'center', sortable: true, value: 'resSales', width: 100 },
        { text: 'パレット', align: 'center', sortable: true, value: 'resPallet', width: 100 },
        { text: 'エリア', align: 'center', sortable: true, value: 'area', width: 100 },
        { text: '大企業中小企業', align: 'center', sortable: true, value: 'resCompanyScale', width: 100 },
        { text: '保管収入㌧', align: 'center', sortable: true, value: 'resTonIncomeStorage', width: 100 },
        { text: '荷役収入㌧', align: 'center', sortable: true, value: 'resTonIncomeCargo', width: 100 },
        { text: '資本金（千円）', align: 'center', sortable: true, value: 'capital', width: 100 },
        { text: '現在景気', align: 'center', sortable: true, value: 'trendsNow', width: 100 },
        { text: '将来景気', align: 'center', sortable: true, value: 'trendsFuture', width: 100 },
        { text: 'コメント', align: 'center', sortable: true, value: 'trendsRemarks', width: 500 },
    ],

    // 回答データ一覧
    answerDataList: [],
    answerDataCount: 0,
    resTotal: {},
    resAve: {},

    // 対象年月チェック
    isCheckDate: false,

    // 事業所選択
    select_member_no: '',
  }),

  // ライフサイクルフック
  created: function () {
    Debug.log('FactFindingCheck created');
    this.targetSurveyType = this.$store.getters.getTargetSurveyType;

    // 検索条件
    const str = this.$store.getters.getStoreSearchCondition;
    Debug.log(str);
    if(str.targetDate == null) {
      this.searchCondition.targetYear = dayjs().startOf('year').format().substr(0, 7);  // 実施年を現在年の1月1日に設定
    } else {
      this.searchCondition.targetYear = str.targetDate;
    }

  },

  mounted: async function () {
  },

  methods: {
    /** 表示更新 */
    async loadList() {
      Debug.log('function[loadList]');
      this.loading = true;
      //this.answerDataList = this.resAnswerDataList;
      //this.answerDataCount = this.answerDataList.length;

      try {
        let res = null;
        // 回答データ一覧
        res = await FactFindingAnswer.getSurveyAnsCheckDataList(this.searchCondition);

        if (res.status == 200 && res.data) {
          Debug.log(res.data);
          // 回答データ
          this.answerDataList = res.data;
          this.answerDataCount = this.answerDataList.length;
          Debug.log(this.answerDataList);
        }
      } catch (error) {
        // alert('情報を取得できませんでした。')
        Message.err(error, "情報を取得できませんでした");
      }

      this.loading = false;
    },

    /** 詳細ページに遷移 */
    goto_detail (name, blenk=false) {
      if(blenk) {
        // 別タブで開く
        let resolvedRoute = this.$router.resolve({
          name: name,
        });
        window.open(resolvedRoute.href, '_blank');
        return;
      }

      // 同タブで開く
      this.$router.push({name: name});
    },

    // 検索
    async search() {
      Debug.log("function[search]");
      this.$store.commit('setStoreSearchCondition', this.searchCondition);
      await this.loadList();
      this.$refs.form.validate();
    },

    // キャンセル
    cancel() {
      if (!confirm("変更をキャンセルします。よろしいですか？")) return;
      this.search();
      this.editMode = false;
    },
    // 回答データ編集
    editData() {
      this.editMode = true;
    },
    // 保存
    async registData() {
      if (!confirm("回答データを保存します。よろしいですか？")) return;

      this.loading = true;
      try {
        const res = await FactFindingAnswer.setDataList(this.answerDataList);

        // サーバーサイドのバリデーション
        const validationMsg = res.data;
        if (validationMsg) {
          let message = "";
          if (Array.isArray(validationMsg)) {
            validationMsg.forEach(m => message += m + '\n')
          } else {
            message = validationMsg;
          }
          alert(message);

          this.loading = false;
          return;
        } else {
          alert("保存しました。");
        }

      } catch (error) {
        Message.err(error, "保存できませんでした");
      }
      this.loading = false;
      this.editMode = false;

      // データを再取得
      this.search();
    },

    // 集計
    async goToSummary() {
      if (!confirm("集計を行います。よろしいですか？")) return;

      this.loading = true;
      try {
        // 集計データ保存
        const res = await FactFinding.summary(this.searchCondition.targetYear, this.answerDataList);
        // Debug.log(res);

        // サーバーサイドのバリデーション
        const validationMsg = res.data;
        if (validationMsg) {
          let message = "";
          if (Array.isArray(validationMsg)) {
            validationMsg.forEach(m => message += m + '\n')
          } else {
            message = validationMsg;
          }
          alert(message);

          this.loading = false;
          return;
        } else {
          alert("集計データを保存しました。集計画面に移動します。");
          this.$router.push({name: 'SurveySummary', params:{targetYear: this.searchCondition.targetYear,
                                                            answerDataList: JSON.stringify(this.answerDataList)}});
        }

      } catch (error) {
        Message.err(error, "集計データを保存できませんでした");
      }
      this.loading = false;

      //if (!confirm("集計画面に移動します。よろしいですか？")) return;
      //this.$router.push({name: 'SurveySummary', params:{targetYear: this.searchCondition.targetYear}});
    },

    // 日付の下限チェック
    checkDateLower(limit, targetDate) {
      Debug.log2('checkDateLower', targetDate);
      const tmpStartDate = limit;
      const tmpEndDate = targetDate;

      if (!tmpStartDate || !tmpEndDate) return true;

      const startDate = Util.convStringDate(tmpStartDate+'-01', '-');
      const endDatee = Util.convStringDate(tmpEndDate+'-01', '-');

      if (startDate >= endDatee) {
        this.isCheckDate = true;
        return dayjs(limit).format('YYYY年')+'より後を設定してください';
      }
      this.isCheckDate = false;
      return true;
    },

    // NG判定件数の計算
    calcCheckCount(index) {
      let count = 0;
      if(this.chkVolumeValueGeneral(this.answerDataList[index].volumeValueGeneral) !== true) {
        Debug.log('NG:chkVolumeValueGeneral');
        count++;
      }
      if(this.chkResRateStockYearAve(this.answerDataList[index].resRateStockYearAve) !== true) {
        Debug.log('NG:chkResRateStockYearAve');
        count++;
      }
      if(this.chkResIncomeStorage(this.answerDataList[index].resIncomeStorage) !== true) {
        Debug.log('NG:chkResIncomeStorage');
        count++;
      }
      if(this.chkResIncomeCargo(this.answerDataList[index].resIncomeCargo) !== true) {
        Debug.log('NG:chkResIncomeCargo');
        count++;
      }
      if(this.chkResIncomeFacility(this.answerDataList[index].resIncomeFacility) !== true) {
        Debug.log('NG:chkResIncomeFacility');
        count++;
      }
      if(this.chkResRateElectricity(this.answerDataList[index].resRateElectricity) !== true) {
        Debug.log('NG:chkResRateElectricity');
        count++;
      }
      if(this.chkResUnitPriceElectricity(this.answerDataList[index].resUnitPriceElectricity) !== true) {
        Debug.log('NG:chkResUnitPriceElectricity');
        count++;
      }
      this.$set(this.answerDataList[index], 'checkCount', count);
      //this.answerDataList[index].checkCount = count;
      return count;
    },

    /** カスタムルール */
    // 除容積建設備能力
    chkVolumeValueGeneral: (v) => !!v && v != 0 || "値が0です",
    // 在庫率年平均%
    chkResRateStockYearAve: (v) => (typeof v === 'number' ? v <= 100 : v.replace(/,/g, '') <= 100 ) || "100%以上です",
    // 保管料収入(￥/設備㌧)
    chkResIncomeStorage: (v) => (typeof v === 'number' ? v <= 100000 : v.replace(/,/g, '') <= 100000 )|| "100,000以上です", // NumberTextFieldで,区切りの場合、,を削除する
    // 荷役料収入(￥/設備㌧)
    chkResIncomeCargo: (v) => (typeof v === 'number' ? v <= 100000 : v.replace(/,/g, '') <= 100000 ) || "100,000以上です",
    // 設備㌧収入小計(￥/設備㌧)
    chkResIncomeFacility: (v) => (typeof v === 'number' ? v <= 100000 : v.replace(/,/g, '') <= 100000 ) || "100,000以上です",
    // 契約電力有効率
    chkResRateElectricity: (v) => (typeof v === 'number' ? v <= 100 : v.replace(/,/g, '') <= 100 ) || "100%以上です",
    // KWH単価
    chkResUnitPriceElectricity: (v) => (typeof v === 'number' ? v <= 50 : v.replace(/,/g, '') <= 50 ) || "50/kwh以上です",

    /** 計算処理 */
    // その他収入合計
    calcOtherValueTotal(index) {
      // その他作業料合計+その他手数料合計+流通加工+利用運送
      let res = 0;
      res = this.answerDataList[index].workValueTotal + this.answerDataList[index].chargeValueTotal + this.answerDataList[index].processingValueTotal + this.answerDataList[index].transportValueTotal;
      return res;
      //this.answerDataList[index].resOtherValueTotal = res;
    },
    // その他収入一般
    calcOtherValueGeneral(index) {
      // その他作業料一般+その他手数料一般+流通加工+利用運送
      let res = 0;
      res = this.answerDataList[index].workValueGeneral + this.answerDataList[index].chargeValueGeneral + this.answerDataList[index].processingValueTotal + this.answerDataList[index].transportValueTotal;
      return res;
      //this.answerDataList[index].resOtherValueGeneral = res;
    },
    // その他収入容積
    calcOtherValueMeasure(index) {
      // その他作業料容積+その他手数料容積
      let res = 0;
      res = this.answerDataList[index].workValueMeasure + this.answerDataList[index].chargeValueMeasure
      return res;
      //this.answerDataList[index].resOtherValueMeasure = res;
    },
    // 収入合計
    calcIncomeValueTotal(index) {
      // 保管料収入+荷役料収入+凍結料+その他収入合計
      let res = 0;
      res = this.answerDataList[index].storageValueTotal + this.answerDataList[index].cargoValueTotal + this.answerDataList[index].frozenValueGeneral + this.calcOtherValueTotal(index);
      return res;
      //this.answerDataList[index].resIncomeValueTotal = res;
    },
    // 収入合計一般
    calcIncomeValueGeneral(index) {
      // 保管料一般+荷役料一般+凍結料+その他収入一般
      let res = 0;
      res = this.answerDataList[index].storageValueGeneral + this.answerDataList[index].cargoValueGeneral + this.answerDataList[index].frozenValueGeneral + this.calcOtherValueGeneral(index);
      return res;
      //this.answerDataList[index].resIncomeValueGeneral = res;
    },
    // 収入合計容積
    calcIncomeValueMeasure(index) {
      // 保管料容積+荷役料容積+その他収入容積
      let res = 0;
      res = this.answerDataList[index].storageValueMeasure + this.answerDataList[index].cargoValueMeasure + this.calcOtherValueMeasure(index);
      return res;
      //this.answerDataList[index].resIncomeValueMeasure = res;
    },
    // 所管容積設備㌧
    calcVolumeFacility(index) {
      // 所管容積×0.4
      let res = 0;
      res = this.answerDataList[index].volume * 0.4;
      // 少数第1位で四捨五入
      return Util.orgRound(res, 1);
      //this.answerDataList[index].resVolumeFacility = Util.orgRound(res, 1);
    },
    // 除容積建設備能力
    calcMeasureFacility(index) {
      // 一般保管１×0.4
      let res = 0;
      res = this.answerDataList[index].volumeValueGeneral * 0.4;
      // 少数第1位で四捨五入
      return Util.orgRound(res, 1);
      //this.answerDataList[index].resMeasureFacility = Util.orgRound(res, 1);
    },
    // 年間入庫量1000設備㌧
    calcInFacility(index) {
      // 入一般÷除容積建設備能力×1000 ※除容積建設備能力が0の場合は0
      let res = 0;
      if(this.calcMeasureFacility(index) != 0) {
        res = this.answerDataList[index].inValueGeneral / this.calcMeasureFacility(index) * 1000;
      }
      // 少数第1位で四捨五入
      return Util.orgRound(res, 1);
      //this.answerDataList[index].resInFacility = Util.orgRound(res, 1);
    },
    // 年間出庫量1000設備㌧
    calcOutFacility(index) {
      // 出一般÷除容積建設備能力×1000 ※除容積建設備能力が0の場合は0
      let res = 0;
      if(this.calcMeasureFacility(index) != 0) {
        res = this.answerDataList[index].outValueGeneral / this.calcMeasureFacility(index) * 1000;
      }
      // 少数第1位で四捨五入
      return Util.orgRound(res, 1);
      //this.answerDataList[index].resOutFacility = Util.orgRound(res, 1);
    },
    // 前月繰越数量1000設備㌧
    calcPrevFacility(index) {
      // 前月繰越÷除容積建設備能力×1000 ※除容積建設備能力が0の場合は0
      let res = 0;
      if(this.calcMeasureFacility(index) != 0) {
        res = this.answerDataList[index].prevValueGeneral / this.calcMeasureFacility(index) * 1000;
      }
      // 少数第2位で四捨五入
      return Util.orgRound(res, 10);
      //this.answerDataList[index].resPrevFacility = Util.orgRound(res, 10);
    },
    // 中間繰越数量1000設備㌧
    calcInterFacility(index) {
      // 中間繰越÷除容積建設備能力×1000 ※除容積建設備能力が0の場合は0
      let res = 0;
      if(this.calcMeasureFacility(index) != 0) {
        res = this.answerDataList[index].interValueGeneral / this.calcMeasureFacility(index) * 1000;
      }
      // 少数第2位で四捨五入
      return Util.orgRound(res, 10);
      //this.answerDataList[index].resInterFacility = Util.orgRound(res, 10);
    },
    // 在庫率年平均%
    calcRateStockYearAve(index) {
      // ((前月繰越+中間繰越)÷2)÷除容積建設備能力×100 ※除容積建設備能力が0の場合は0
      let res = 0;
      if(this.calcMeasureFacility(index) != 0) {
        res = ((this.answerDataList[index].prevValueGeneral + this.answerDataList[index].interValueGeneral) / 2) / this.calcMeasureFacility(index) * 100;
      }
      // 少数第3位で四捨五入
      return Util.orgRound(res, 100);
      //this.answerDataList[index].resRateStockYearAve = Util.orgRound(res, 100);
    },
    // 単純設備㌧平均在庫
    calcStockSimpleFacilityAve(index) {
      // '(前月+中間)/2平均在庫'÷除容積建設備能力×1000 ※除容積建設備能力が0の場合は0
      let res = 0;
      if(this.calcMeasureFacility(index) != 0) {
        res = this.calcStockPrevInterAve(index) / this.calcMeasureFacility(index) * 100;
      }
      // 少数第3位で四捨五入
      return Util.orgRound(res, 100);
      //this.answerDataList[index].resStockSimpleFacilityAve = Util.orgRound(res, 100);
    },
    // 回転数年間(回)
    calcTurnoverYear(index) {
      // 入一般÷'(前月+中間)/2平均在庫' ※'(前月+中間)/2平均在庫'が0の場合は0
      let res = 0;
      if(this.calcStockPrevInterAve(index) != 0) {
        res = this.answerDataList[index].inValueGeneral / this.calcStockPrevInterAve(index);
      }
      // 少数第2位で四捨五入
      return Util.orgRound(res, 10);
      //this.answerDataList[index].resTurnoverYear = Util.orgRound(res, 10);
    },
    // (前月+中間)/2平均在庫
    calcStockPrevInterAve(index) {
      // (前月繰越+中間繰越)÷2 ※除容積建設備能力が0の場合は0
      let res = 0;
      if(this.calcMeasureFacility(index) != 0) {
        res = (this.answerDataList[index].prevValueGeneral + this.answerDataList[index].interValueGeneral) / 2;
      }
      // 少数第2位で四捨五入
      return Util.orgRound(res, 10);
      //this.answerDataList[index].resStockPrevInterAve = Util.orgRound(res, 10);
    },
    // 平均在庫1000設備㌧
    calcStockFacilityAve(index) {
      // '(前月+中間)/2平均在庫'÷除容積建設備能力×1000 ※除容積建設備能力が0の場合は0
      let res = 0;
      if(this.calcMeasureFacility(index) != 0) {
        res = this.calcStockPrevInterAve(index) / this.calcMeasureFacility(index) * 1000;
      }
      // 少数第2位で四捨五入
      return Util.orgRound(res, 10);
      //this.answerDataList[index].resStockFacilityAve = Util.orgRound(res, 10);
    },
    // 保管料収入(￥/設備㌧)
    calcIncomeStorage(index) {
      // 保管料一般/除容積建設備能力×1000 ※除容積建設備能力が0の場合は0
      let res = 0;
      if(this.calcMeasureFacility(index) != 0) {
        res = this.answerDataList[index].storageValueGeneral / this.calcMeasureFacility(index) * 1000;
      }
      // 少数第2位で四捨五入
      return Util.orgRound(res, 10);
      //this.answerDataList[index].resIncomeStorage = Util.orgRound(res, 10);
    },
    // 荷役料収入(￥/設備㌧)
    calcIncomeCargo(index) {
      // 荷役料一般/除容積建設備能力×1000 ※除容積建設備能力が0の場合は0
      let res = 0;
      if(this.calcMeasureFacility(index) != 0) {
        res = this.answerDataList[index].cargoValueGeneral / this.calcMeasureFacility(index) * 1000;
      }
      // 少数第2位で四捨五入
      return Util.orgRound(res, 10);
      //this.answerDataList[index].resIncomeCargo = Util.orgRound(res, 10);
    },
    // 凍結料収入(￥/設備㌧)
    calcIncomeFrozen(index) {
      // 凍結料/除容積建設備能力×1000 ※除容積建設備能力が0の場合は0
      let res = 0;
      if(this.calcMeasureFacility(index) != 0) {
        res = this.answerDataList[index].frozenValueGeneral / this.calcMeasureFacility(index) * 1000;
      }
      // 少数第2位で四捨五入
      return Util.orgRound(res, 10);
      //this.answerDataList[index].resIncomeFrozen = Util.orgRound(res, 10);
    },
    // 他作業料計(￥/設備㌧)
    calcChargeWorkTotal(index) {
      // その他作業料合計÷除容積建設備能力×1000 ※除容積建設備能力が0の場合は0
      let res = 0;
      if(this.calcMeasureFacility(index) != 0) {
        res = this.answerDataList[index].workValueTotal / this.calcMeasureFacility(index) * 1000;
      }
      // 少数第2位で四捨五入
      return Util.orgRound(res, 10);
      //this.answerDataList[index].resChargeWorkTotal = Util.orgRound(res, 10);
    },
    // 他作業料一(￥/設備㌧)
    calcChargeWork(index) {
      // その他作業料一般÷除容積建設備能力×1000 ※除容積建設備能力が0の場合は0
      let res = 0;
      if(this.calcMeasureFacility(index) != 0) {
        res = this.answerDataList[index].workValueGeneral / this.calcMeasureFacility(index) * 1000;
      }
      // 少数第2位で四捨五入
      return Util.orgRound(res, 10);
      //this.answerDataList[index].resChargeWork = Util.orgRound(res, 10);
    },
    // 他作業容積(￥/設備㌧)
    calcChargeVolume(index) {
      // その他作業料容積÷除容積建設備能力×1000 ※除容積建設備能力が0の場合は0
      let res = 0;
      if(this.calcMeasureFacility(index) != 0) {
        res = this.answerDataList[index].workValueMeasure / this.calcMeasureFacility(index) * 1000;
      }
      // 少数第2位で四捨五入
      return Util.orgRound(res, 10);
      //this.answerDataList[index].resChargeVolume = Util.orgRound(res, 10);
    },
    // 他手数料計(￥/設備㌧)
    calcChargeTotal(index) {
      // その他手数料合計÷除容積建設備能力×1000 ※除容積建設備能力が0の場合は0
      let res = 0;
      if(this.calcMeasureFacility(index) != 0) {
        res = this.answerDataList[index].chargeValueTotal / this.calcMeasureFacility(index) * 1000;
      }
      // 少数第2位で四捨五入
      return Util.orgRound(res, 10);
      //this.answerDataList[index].resChargeTotal = Util.orgRound(res, 10);
    },
    // 他手数一般(￥/設備㌧)
    calcChargeGeneral(index) {
      // その他手数料一般÷除容積建設備能力×1000 ※除容積建設備能力が0の場合は0
      let res = 0;
      if(this.calcMeasureFacility(index) != 0) {
        res = this.answerDataList[index].chargeValueGeneral / this.calcMeasureFacility(index) * 1000;
      }
      // 少数第2位で四捨五入
      return Util.orgRound(res, 10);
      //this.answerDataList[index].resChargeGeneral = Util.orgRound(res, 10);
    },
    // 他手数容積(￥/設備㌧)
    calcChargeMeasure(index) {
      // その他手数料容積÷除容積建設備能力×1000 ※除容積建設備能力が0の場合は0
      let res = 0;
      if(this.calcMeasureFacility(index) != 0) {
        res = this.answerDataList[index].chargeValueMeasure / this.calcMeasureFacility(index) * 1000;
      }
      // 少数第2位で四捨五入
      return Util.orgRound(res, 10);
      //this.answerDataList[index].resChargeMeasure = Util.orgRound(res, 10);
    },
    // 流通加工(￥/設備㌧)
    calcChargeProcessing(index) {
      // 流通加工÷除容積建設備能力×1000 ※除容積建設備能力が0の場合は0
      let res = 0;
      if(this.calcMeasureFacility(index) != 0) {
        res = this.answerDataList[index].processingValueTotal / this.calcMeasureFacility(index) * 1000;
      }
      // 少数第2位で四捨五入
      return Util.orgRound(res, 10);
      //this.answerDataList[index].resChargeProcessing = Util.orgRound(res, 10);
    },
    // 利用運送(￥/設備㌧)
    calcChargeTransport(index) {
      // 利用運送÷除容積建設備能力×1000 ※除容積建設備能力が0の場合は0
      let res = 0;
      if(this.calcMeasureFacility(index) != 0) {
        res = this.answerDataList[index].transportValueTotal / this.calcMeasureFacility(index) * 1000;
      }
      // 少数第2位で四捨五入
      return Util.orgRound(res, 10);
      //this.answerDataList[index].resChargeTransport = Util.orgRound(res, 10);
    },
    // 他収入合計(￥/設備㌧)
    calcIncomeOtherTotal(index) {
      // その他収入合計÷除容積建設備能力×1000 ※除容積建設備能力が0の場合は0
      let res = 0;
      if(this.calcMeasureFacility(index) != 0) {
        res = this.calcOtherValueTotal(index) / this.calcMeasureFacility(index) * 1000;
      }
      // 少数第2位で四捨五入
      return Util.orgRound(res, 10);
      //this.answerDataList[index].resIncomeOtherTotal = Util.orgRound(res, 10);
    },
    // 収入合計(￥/設備㌧)
    calcIncomeTotal(index) {
      // 収入合計一般÷除容積建設備能力×1000 ※除容積建設備能力が0の場合は0
      let res = 0;
      if(this.calcMeasureFacility(index) != 0) {
        res = this.calcIncomeValueGeneral(index) / this.calcMeasureFacility(index) * 1000;
      }
      // 少数第2位で四捨五入
      return Util.orgRound(res, 10);
      //this.answerDataList[index].resIncomeTotal = Util.orgRound(res, 10);
    },
    // 保管収入㌧1000設備㌧
    calcIncomeStorageFacility(index) {
      // (入一般+(前月繰越+中間繰越)×12)÷除容積建設備能力×1000 ※除容積建設備能力が0の場合は0
      let res = 0;
      if(this.calcMeasureFacility(index) != 0) {
        res = (this.answerDataList[index].inValueGeneral + (this.answerDataList[index].prevValueGeneral + this.answerDataList[index].interValueGeneral) * 12) / this.calcMeasureFacility(index) * 1000;
      }
      // 少数第2位で四捨五入
      return Util.orgRound(res, 10);
    },
    // 保管積数(kg/設備㌧)
    calcStorageNum(index) {
      // 平均在庫1000設備㌧×24+年間入庫量1000設備㌧ ※除容積建設備能力が0の場合は0
      let res = 0;
      if(this.calcMeasureFacility(index) != 0) {
        res = this.calcStockFacilityAve(index) * 24 + this.calcInFacility(index);
      }
      // 少数第2位で四捨五入
      return Util.orgRound(res, 10);
    },
    // 荷役収入㌧1000設備㌧
    calcIncomeCargoFacility(index) {
      // 入一般×2÷除容積建設備能力×1000 ※除容積建設備能力が0の場合は0
      let res = 0;
      if(this.calcMeasureFacility(index) != 0) {
        res = this.answerDataList[index].inValueGeneral * 2 / this.calcMeasureFacility(index);
      }
      // 少数第2位で四捨五入
      return Util.orgRound(res, 10);
    },
    // 荷役積数(kg/設備㌧)
    calcCargoNum(index) {
      // 年間入庫量1000設備㌧×2 ※除容積建設備能力が0の場合は0
      let res = 0;
      if(this.calcMeasureFacility(index) != 0) {
        res = this.calcInFacility(index) * 2;
      }
      // 少数第2位で四捨五入
      return Util.orgRound(res, 10);
    },
    // 設備㌧収入小計(￥/設備㌧)
    calcIncomeFacility(index) {
      // '保管料収入(￥/設備㌧)'+'荷役料収入(￥/設備㌧)' ※除容積建設備能力が0の場合は0
      let res = 0;
      if(this.calcMeasureFacility(index) != 0) {
        res = this.calcIncomeStorage(index) + this.calcIncomeCargo(index);
      }
      // 少数第2位で四捨五入
      return Util.orgRound(res, 10);
    },
    // 現場従業員1000設備㌧
    calcWorkTotal(index) {
      // (作業パート人数+作業専任人数+下請現場人数)÷除容積建設備能力×1000 ※除容積建設備能力が0の場合は0
      let res = 0;
      if(this.calcMeasureFacility(index) != 0) {
        res = (this.answerDataList[index].workPartNum + this.answerDataList[index].workNum + this.answerDataList[index].consignWorkNum) / this.calcMeasureFacility(index) * 1000;
      }
      // 少数第3位で四捨五入
      return Util.orgRound(res, 100);
    },
    // (現場社員)1000設備㌧
    calcWorkEmployee(index) {
      // (作業専任人数+作業パート人数)÷除容積建設備能力×1000 ※除容積建設備能力が0の場合は0
      let res = 0;
      if(this.calcMeasureFacility(index) != 0) {
        res = (this.answerDataList[index].workNum + this.answerDataList[index].workPartNum) / this.calcMeasureFacility(index) * 1000;
      }
      // 少数第3位で四捨五入
      return Util.orgRound(res, 100);
    },
    // (現場委託)1000設備㌧
    calcWorkConsign(index) {
      // 下請現場人数÷除容積建設備能力×1000 ※除容積建設備能力が0の場合は0
      let res = 0;
      if(this.calcMeasureFacility(index) != 0) {
        res = this.answerDataList[index].consignWorkNum / this.calcMeasureFacility(index) * 1000;
      }
      // 少数第3位で四捨五入
      return Util.orgRound(res, 100);
    },
    // 事務所従業員1000設備㌧
    calcClericalTotal(index) {
      // (事務パート人数+事務人数+下請事務人数)÷除容積建設備能力×1000 ※除容積建設備能力が0の場合は0
      let res = 0;
      if(this.calcMeasureFacility(index) != 0) {
        res = (this.answerDataList[index].clericalPartNum + this.answerDataList[index].clericalNum + this.answerDataList[index].consignClericalNum) / this.calcMeasureFacility(index) * 1000;
      }
      // 少数第3位で四捨五入
      return Util.orgRound(res, 100);
    },
    // (事務社員)1000設備㌧
    calcClericalEmployee(index) {
      // (事務人数+事務パート人数)÷除容積建設備能力×1000 ※除容積建設備能力が0の場合は0
      let res = 0;
      if(this.calcMeasureFacility(index) != 0) {
        res = (this.answerDataList[index].clericalNum + this.answerDataList[index].clericalPartNum) / this.calcMeasureFacility(index) * 1000;
      }
      // 少数第3位で四捨五入
      return Util.orgRound(res, 100);
    },
    // (事務委託)1000設備㌧
    calcClericalConsign(index) {
      // 下請事務人数÷除容積建設備能力×1000 ※除容積建設備能力が0の場合は0
      let res = 0;
      if(this.calcMeasureFacility(index) != 0) {
        res = this.answerDataList[index].consignClericalNum / this.calcMeasureFacility(index) * 1000;
      }
      // 少数第3位で四捨五入
      return Util.orgRound(res, 100);
    },
    // 社員管理者1000設備㌧
    calcManageEmployee(index) {
      // 社員管理者÷除容積建設備能力×1000 ※除容積建設備能力が0の場合は0
      let res = 0;
      if(this.calcMeasureFacility(index) != 0) {
        res = this.answerDataList[index].manageNum / this.calcMeasureFacility(index) * 1000;
      }
      // 少数第3位で四捨五入
      return Util.orgRound(res, 100);
    },
    // 従業員合計1000設備㌧
    calcWorkerTotal(index) {
      // (社員合計+下請現場人数+下請事務人数)÷除容積建設備能力×1000 ※除容積建設備能力が0の場合は0
      let res = 0;
      if(this.calcMeasureFacility(index) != 0) {
        res = (this.answerDataList[index].employeeTotalNum + this.answerDataList[index].consignWorkNum + this.answerDataList[index].consignClericalNum) / this.calcMeasureFacility(index) * 1000;
      }
      // 少数第3位で四捨五入
      return Util.orgRound(res, 100);
    },
    // (社員計)1000設備㌧
    calcEmployeeTotal(index) {
      // 社員合計÷除容積建設備能力×1000 ※除容積建設備能力が0の場合は0
      let res = 0;
      if(this.calcMeasureFacility(index) != 0) {
        res = this.answerDataList[index].employeeTotalNum / this.calcMeasureFacility(index) * 1000;
      }
      // 少数第3位で四捨五入
      return Util.orgRound(res, 100);
    },
    // (外部委託計)1000設備㌧
    calcConsignTotal(index) {
      // (下請現場人数+下請事務人数)÷除容積建設備能力×1000 ※除容積建設備能力が0の場合は0
      let res = 0;
      if(this.calcMeasureFacility(index) != 0) {
        res = (this.answerDataList[index].consignWorkNum + this.answerDataList[index].consignClericalNum) / this.calcMeasureFacility(index) * 1000;
      }
      // 少数第3位で四捨五入
      return Util.orgRound(res, 100);
    },
    // 一人当り売上(千円/人)
    calcSalesPerson(index) {
      // 収入合計一般÷(社員合計+下請現場人数+下請事務人数)
      let res = 0;
      if((this.answerDataList[index].employeeTotalNum + this.answerDataList[index].consignWorkNum + this.answerDataList[index].consignClericalNum) != 0) {
        res = this.calcIncomeValueGeneral(index) / (this.answerDataList[index].employeeTotalNum + this.answerDataList[index].consignWorkNum + this.answerDataList[index].consignClericalNum);
      }
      // 少数第1位で四捨五入
      return Util.orgRound(res, 1);
    },
    // 一人当り売上1000設備㌧
    calcSalesFacility(index) {
      // '収入合計(￥/設備㌧)'÷従業員合計1000設備㌧
      let res = 0;
      if(this.calcWorkerTotal(index) != 0) {
        res = this.calcIncomeTotal(index) / this.calcWorkerTotal(index);
      }
      // 少数第1位で四捨五入
      return Util.orgRound(res, 1);
    },
    // 一人当り扱量(t/年人)
    calcTonPerson(index) {
      // (入一般+出一般)÷(社員合計+下請現場人数+下請事務人数)
      let res = 0;
      if((this.answerDataList[index].employeeTotalNum + this.answerDataList[index].consignWorkNum + this.answerDataList[index].consignClericalNum) != 0) {
        res = (this.answerDataList[index].inValueGeneral + this.answerDataList[index].outValueGeneral) / (this.answerDataList[index].employeeTotalNum + this.answerDataList[index].consignWorkNum + this.answerDataList[index].consignClericalNum);
      }
      // 少数第1位で四捨五入
      return Util.orgRound(res, 1);
    },
    // 一人当り扱量1000設備㌧
    calcTonFacilityPerson(index) {
      // (年間入庫量1000設備㌧+年間出庫量1000設備㌧)÷従業員合計1000設備㌧
      let res = 0;
      if(this.calcWorkerTotal(index) != 0) {
        res = (this.calcInFacility(index) + this.calcOutFacility(index)) / this.calcWorkerTotal(index);
      }
      // 少数第1位で四捨五入
      return Util.orgRound(res, 1);
    },
    // 現場従業員一人当り扱量1
    calcTonWork1(index) {
      // (入一般+出一般)÷(作業パート人数+作業専任人数+下請現場人数)
      let res = 0;
      if((this.answerDataList[index].workPartNum + this.answerDataList[index].workNum + this.answerDataList[index].consignWorkNum) != 0) {
        res = (this.answerDataList[index].inValueGeneral + this.answerDataList[index].outValueGeneral) /
                (this.answerDataList[index].workPartNum + this.answerDataList[index].workNum + this.answerDataList[index].consignWorkNum);
      }
      // 少数第1位で四捨五入
      return Util.orgRound(res, 1);
    },
    // 現場従業員一人当り扱量2
    calcTonWork2(index) {
      // (年間入庫量1000設備㌧+年間出庫量1000設備㌧)÷現場従業員1000設備㌧
      let res = 0;
      if(this.calcWorkTotal(index) != 0) {
        res = (this.calcInFacility(index) + this.calcOutFacility(index)) / this.calcWorkTotal(index);
      }
      // 少数第1位で四捨五入
      return Util.orgRound(res, 1);
    },
    // 1従業員当り設備㌧
    calcFacilityWork1(index) {
      // 1000÷従業員合計1000設備㌧
      let res = 0;
      if(this.calcWorkerTotal(index) != 0) {
        res = 1000 / this.calcWorkerTotal(index);
      }
      // 少数第1位で四捨五入
      return Util.orgRound(res, 1);
    },
    // 契約電力設備㌧
    calcContractElectricityFacility(index) {
      // 契約電力÷所管容積設備㌧×1000
      let res = 0;
      if(this.calcVolumeFacility(index) != 0) {
        res = this.answerDataList[index].contractElectricity / this.calcVolumeFacility(index) * 1000;
      }
      // 少数第2位で四捨五入
      return Util.orgRound(res, 10);
    },
    // 電力使用量設備㌧
    calcUsageElectricityFacility(index) {
      // 電力使用量÷所管容積設備㌧×1000
      let res = 0;
      if(this.calcVolumeFacility(index) != 0) {
        res = this.answerDataList[index].usageElectricity / this.calcVolumeFacility(index) * 1000;
      }
      // 少数第1位で四捨五入
      return Util.orgRound(res, 1);
    },
    // 契約電力有効率
    calcRateElectricity(index) {
      // 電力使用量÷(契約電力×24×365)×100
      let res = 0;
      if(this.answerDataList[index].contractElectricity != 0) {
        res = this.answerDataList[index].usageElectricity / (this.answerDataList[index].contractElectricity * 24 * 365) * 100;
      }
      // 少数第2位で四捨五入
      return Util.orgRound(res, 10);
    },
    // 電力有効率設備㌧
    calcRateElectricityFacility(index) {
      // 電力使用量設備㌧÷(契約電力設備㌧×24×365)×100
      let res = 0;
      if(this.calcContractElectricityFacility(index) != 0) {
        res = this.calcUsageElectricityFacility(index) / (this.calcContractElectricityFacility(index) * 24 * 365) * 100;
      }
      // 少数第2位で四捨五入
      return Util.orgRound(res, 10);
    },
    // 電力支払額設備㌧
    calcPaymentElectricityFacility(index) {
      // 電力支払額÷所管容積設備㌧×1000
      let res = 0;
      if(this.calcVolumeFacility(index) != 0) {
        res = this.answerDataList[index].paymentElectricity / this.calcVolumeFacility(index) * 1000;
      }
      // 少数第1位で四捨五入
      return Util.orgRound(res, 1);
    },
    // KWH単価
    calcUnitPriceElectricity(index) {
      // 電力支払額×1000÷電力使用量
      let res = 0;
      if(this.answerDataList[index].usageElectricity != 0) {
        res = this.answerDataList[index].paymentElectricity * 1000 / this.answerDataList[index].usageElectricity;
      }
      // 少数第2位で四捨五入
      return Util.orgRound(res, 10);
    },
    // KWH単価設備㌧
    calcUnitPriceElectricityFacility(index) {
      // 電力支払額設備㌧×1000÷電力使用量設備㌧
      let res = 0;
      if(this.calcUsageElectricityFacility(index) != 0) {
        res = this.calcPaymentElectricityFacility(index) * 1000 / this.calcUsageElectricityFacility(index);
      }
      // 少数第2位で四捨五入
      return Util.orgRound(res, 10);
    },
    // 業務損益設備㌧
    calcProfitAndLossFacility(index) {
      // 業務損益÷所管容積設備㌧×1000
      let res = 0;
      if(this.calcVolumeFacility(index) != 0) {
        res = this.answerDataList[index].profitAndLoss / this.calcVolumeFacility(index) * 1000;
      }
      // 少数第1位で四捨五入
      return Util.orgRound(res, 1);
    },
    // 1P当重量kg
    calcWeightPerPiece(index) {
      // 実在庫量÷使用パレット×1000
      let res = 0;
      if(this.answerDataList[index].usagePallet != 0) {
        res = this.answerDataList[index].stockPallet / this.answerDataList[index].usagePallet * 1000;
      }
      // 少数第2位で四捨五入
      return Util.orgRound(res, 10);
    },
    // P積付容積
    calcStowageCapacity(index) {
      // (Ｐ．Ｌ×Ｐ．Ｗ×Ｐ．Ｈ)÷1000000
      let res = 0;
      res = (this.answerDataList[index].stowageVertical * this.answerDataList[index].stowageHorizontal * this.answerDataList[index].stowageHeight) / 1000000;
      // 少数第2位で四捨五入
      return Util.orgRound(res, 10);
    },
    // 貨物比重
    calcWeight(index) {
      // 1P当重量kg÷(P積付容積×1000)÷0.729
      let res = 0;
      if(this.calcStowageCapacity(index) != 0) {
        res = this.calcWeightPerPiece(index) / (this.calcStowageCapacity(index) * 1000) / 0.729;
      }
      // 少数第3位で四捨五入
      return Util.orgRound(res, 100);
    },
    // バース数1000設備㌧
    calcNumDockFacility(index) {
      // バース数÷所管容積設備㌧×1000 ※所管容積設備㌧が0の場合1
      let res = 1;
      if(this.calcVolumeFacility(index) != 0) {
        res = this.answerDataList[index].numDock / this.calcVolumeFacility(index) * 1000;
      }
      // 少数第2位で四捨五入
      return Util.orgRound(res, 10);
    },
    // 規模
    calcScale(index) {
      let res = '';
      if(this.answerDataList[index].volume >= 25000) {
        // 所管容積(㎥)>=25000
        res = '大';
      } else if(this.answerDataList[index].volume >= 7500) {
        // 所管容積(㎥)>=7500
        res = '中';
      } else if(this.answerDataList[index].volume > 0) {
        // 所管容積(㎥)>0
        res = '小';
      }
      return res;
    },
    // 回転
    calcTurnover(index) {
      let res = '';
      if(this.calcTurnoverYear(index) != 0) {
        // 回転数年間(回)が0以外
        if(this.calcTurnoverYear(index) >= 12) {
          // 回転数年間(回) >= 12
          res = '流通';
        } else if(this.calcTurnoverYear(index) >= 6) {
          // 回転数年間(回) >= 6
          res = '中間';
        } else if(this.calcTurnoverYear(index) > 0) {
          // 回転数年間(回) > 0
          res = '保管';
        }
      } else if(this.calcVolumeFacility(index) != 0) {
        // 回転数年間(回)が0 で 所管容積設備㌧が0以外
        res = '保管';
      }
      return res;
    },
    // 従業員
    calcEmployee(index) {
      let res = '';
      // 分岐用値
      const tmpNum = this.answerDataList[index].employeeTotalNum
                      +this.answerDataList[index].consignWorkNum
                      +this.answerDataList[index].consignClericalNum;
      if( this.answerDataList[index].employeeTotalNum != 0 ) {
        // 分岐用値
        const tmpIncome = this.answerDataList[index].workPartAveIncome
                      + this.answerDataList[index].workAveIncome
                      + this.answerDataList[index].clericalPartAveIncome
                      + this.answerDataList[index].clericalAveIncome
                      + this.answerDataList[index].manageAveIncome
                      + this.answerDataList[index].consignWorkAveIncome
                      + this.answerDataList[index].consignClericalAveIncome;
        // 社員合計が0以外
        if( tmpIncome != 0 ) {
          // (作業パート賃金+作業専任賃金+事務パート賃金+事務賃金+社員管理者賃金+下請現場賃金+下請事務賃金)が0以外
          res = '○';
        } else {
          // (作業パート賃金+作業専任賃金+事務パート賃金+事務賃金+社員管理者賃金+下請現場賃金+下請事務賃金)が0
          res = '△';
        }
      } else if( tmpNum == 0 ) {
        // 社員合計が0 で (社員合計+下請現場人数+下請事務人数)が0
        res = '×';
      }
      return res;
    },
    // 在庫回転
    calcStockTurnover(index) {
      let res = '';
      if((this.answerDataList[index].prevValueGeneral + this.answerDataList[index].interValueGeneral) != 0 ) {
        // (前月繰越+中間繰越)が0以外
        res = '○';
      } else {
        // (前月繰越+中間繰越)が0
        res = '×';
      }
      return res;
    },
    // 電力異常
    calcElectricityError(index) {
      let res = '';
      if((this.calcUnitPriceElectricity(index) > 50) || (this.calcUnitPriceElectricity(index) < 0)) {
        // KWH単価>50 または KWH単価<0
        res = '異常';
      } else if(this.calcUnitPriceElectricity(index) == 0) {
        // KWH単価==0
        res = '×';
      } else {
        res = '○';
      }
      return res;
    },
    // 売上
    calcSales(index) {
      let res = '';
      if(this.calcIncomeValueTotal(index) != 0) {
        // 収入合計が0以外
        res = '○';
      }
      return res;
    },
    // パレット
    calcPallet(index) {
      let res = '';
      if( (this.answerDataList[index].usagePallet * this.answerDataList[index].stockPallet) == 0 ||
            (this.answerDataList[index].stockPallet * this.answerDataList[index].stowageHorizontal * this.answerDataList[index].stowageHeight) == 0 ) {
        // 使用パレット×実在庫量が0 または (実在庫量×Ｐ．Ｗ×Ｐ．Ｈ)が0
        res = '×';
      } else {
        res = '○';
      }
      return res;
    },
    // 大企業中小企業
    calcCompanyScale(index) {
      let res = '';
      if((this.answerDataList[index].capital != null) &&
          (this.answerDataList[index].capital != 0)) {
        // 資本金（千円）が0以外
        if(this.answerDataList[index].capital <= 100000) {
          // 資本金（千円）<=100000
          res = '小企業';
        } else if(this.answerDataList[index].capital > 300000) {
          // 資本金（千円）>300000
          res = '大企業';
        } else {
          // それ以外
          res = '中企業';
        }
      }
      return res;
    },
    // 保管収入㌧
    calcTonIncomeStorage(index) {
      // 入一般+(前月繰越+中間繰越)×12 ※(年間入庫+前月繰越+中間繰越)が0の場合はnull
      let res = null;
      // (年間入庫+前月繰越+中間繰越)
      const tmpVal = this.answerDataList[index].inValueTotal + this.answerDataList[index].prevValueGeneral + this.answerDataList[index].interValueGeneral;
      if(tmpVal != 0) {
        res = this.answerDataList[index].inValueGeneral + (this.answerDataList[index].prevValueGeneral + this.answerDataList[index].interValueGeneral) * 12;
      }
      return res;
    },
    // 荷役収入㌧
    calcTonIncomeCargo(index) {
      // 入一般×2 ※年間入庫が0の場合はnull
      let res = null;
      if(this.answerDataList[index].inValueTotal != 0) {
        res = this.answerDataList[index].inValueGeneral * 2;
      }
      return res;
    },

    /** 合計 */
    calcTotal() {
      let res = {
        volume: 0, volumeValueGeneral: 0, volumeValueMeasure: 0, inValueTotal: 0, inValueGeneral: 0, inValueMeasure: 0, outValueTotal: 0, outValueGeneral: 0, outValueMeasure: 0, prevValueGeneral: 0, interValueGeneral: 0,
        storageValueTotal: 0, storageValueGeneral: 0, storageValueMeasure: 0, cargoValueTotal: 0, cargoValueGeneral: 0, cargoValueMeasure: 0, frozenValueGeneral: 0, workValueTotal: 0, workValueGeneral: 0, workValueMeasure: 0, chargeValueTotal: 0, chargeValueGeneral: 0, chargeValueMeasure: 0, processingValueTotal: 0, transportValueTotal: 0, resOtherValueTotal: 0, resOtherValueGeneral: 0, resOtherValueMeasure: 0, resIncomeValueTotal: 0, resIncomeValueGeneral: 0, resIncomeValueMeasure: 0,
        workPartNum: 0, workNum: 0, clericalPartNum: 0, clericalNum: 0, manageNum: 0, employeeTotalNum: 0, workPartAveIncome: 0, workAveIncome: 0, clericalPartAveIncome: 0, clericalAveIncome: 0, manageAveIncome: 0,
        workAveAge: 0, clericalAveAge: 0, workAveContinuous: 0, clericalAveContinuous: 0, consignWorkNum: 0, consignClericalNum: 0, consignWorkAveIncome: 0, consignClericalAveIncome: 0,
        contractElectricity: 0, usageElectricity: 0, paymentElectricity: 0, profitAndLoss: 0, numDock: 0, refrigeratorTemp: 0, numClient: 0, numNewClient: 0, usagePallet: 0, stockPallet: 0, stowageVertical: 0, stowageHorizontal: 0, stowageHeight: 0,
        resVolumeFacility: 0, resMeasureFacility: 0, resInFacility: 0, resOutFacility: 0, resPrevFacility: 0, resInterFacility: 0, resRateStockYearAve: 0, resStockSimpleFacilityAve: 0, resTurnoverYear: 0, resStockPrevInterAve: 0, resStockFacilityAve: 0,
        resIncomeStorage: 0, resIncomeCargo: 0, resIncomeFrozen: 0, resChargeWorkTotal: 0, resChargeWork: 0, resChargeVolume: 0, resChargeTotal: 0, resChargeGeneral: 0, resChargeMeasure: 0, resChargeProcessing: 0, resChargeTransport: 0, resIncomeOtherTotal: 0, resIncomeTotal: 0,
        resIncomeStorageFacility: 0, resStorageNum: 0, resIncomeCargoFacility: 0, resCargoNum: 0, resIncomeFacility: 0, resWorkTotal: 0, resWorkEmployee: 0, resWorkConsign: 0, resClericalTotal: 0, resClericalEmployee: 0, resClericalConsign: 0, resManageEmployee: 0, resWorkerTotal: 0, resEmployeeTotal: 0, resConsignTotal: 0,
        resSalesPerson: 0, resSalesFacility: 0, resTonPerson: 0, resTonFacilityPerson: 0, resTonWork1: 0, resTonWork2: 0, resFacilityWork1: 0,
        resContractElectricityFacility: 0, resUsageElectricityFacility: 0, resRateElectricity: 0, resRateElectricityFacility: 0, resPaymentElectricityFacility: 0, resUnitPriceElectricity: 0, resUnitPriceElectricityFacility: 0,
        resProfitAndLossFacility: 0, resWeightPerPiece: 0, resStowageCapacity: 0, resWeight: 0, resNumDockFacility: 0,
      };

      // 回答が1件もない場合初期データを返す
      if(this.answerDataCount <= 0) return res;

      res.volume = this.answerDataList.reduce((sum, e) => {
        return sum + e.volume;
      }, 0);
      res.volumeValueGeneral = this.answerDataList.reduce((sum, e) => {
        return sum + e.volumeValueGeneral;
      }, 0);
      res.volumeValueMeasure = this.answerDataList.reduce((sum, e) => {
        return sum + e.volumeValueMeasure;
      }, 0);
      res.inValueTotal = this.answerDataList.reduce((sum, e) => {
        return sum + e.inValueTotal;
      }, 0);
      res.inValueGeneral = this.answerDataList.reduce((sum, e) => {
        return sum + e.inValueGeneral;
      }, 0);
      res.inValueMeasure = this.answerDataList.reduce((sum, e) => {
        return sum + e.inValueMeasure;
      }, 0);
      res.outValueTotal = this.answerDataList.reduce((sum, e) => {
        return sum + e.outValueTotal;
      }, 0);
      res.outValueGeneral = this.answerDataList.reduce((sum, e) => {
        return sum + e.outValueGeneral;
      }, 0);
      res.outValueMeasure = this.answerDataList.reduce((sum, e) => {
        return sum + e.outValueMeasure;
      }, 0);
      res.prevValueGeneral = this.answerDataList.reduce((sum, e) => {
        return sum + e.prevValueGeneral;
      }, 0);
      res.interValueGeneral = this.answerDataList.reduce((sum, e) => {
        return sum + e.interValueGeneral;
      }, 0);
      res.storageValueTotal = this.answerDataList.reduce((sum, e) => {
        return sum + e.storageValueTotal;
      }, 0);
      res.storageValueGeneral = this.answerDataList.reduce((sum, e) => {
        return sum + e.storageValueGeneral;
      }, 0);
      res.storageValueMeasure = this.answerDataList.reduce((sum, e) => {
        return sum + e.storageValueMeasure;
      }, 0);
      res.cargoValueTotal = this.answerDataList.reduce((sum, e) => {
        return sum + e.cargoValueTotal;
      }, 0);
      res.cargoValueGeneral = this.answerDataList.reduce((sum, e) => {
        return sum + e.cargoValueGeneral;
      }, 0);
      res.cargoValueMeasure = this.answerDataList.reduce((sum, e) => {
        return sum + e.cargoValueMeasure;
      }, 0);
      res.frozenValueGeneral = this.answerDataList.reduce((sum, e) => {
        return sum + e.frozenValueGeneral;
      }, 0);
      res.workValueTotal = this.answerDataList.reduce((sum, e) => {
        return sum + e.workValueTotal;
      }, 0);
      res.workValueGeneral = this.answerDataList.reduce((sum, e) => {
        return sum + e.workValueGeneral;
      }, 0);
      res.workValueMeasure = this.answerDataList.reduce((sum, e) => {
        return sum + e.workValueMeasure;
      }, 0);
      res.chargeValueTotal = this.answerDataList.reduce((sum, e) => {
        return sum + e.chargeValueTotal;
      }, 0);
      res.chargeValueGeneral = this.answerDataList.reduce((sum, e) => {
        return sum + e.chargeValueGeneral;
      }, 0);
      res.chargeValueMeasure = this.answerDataList.reduce((sum, e) => {
        return sum + e.chargeValueMeasure;
      }, 0);
      res.processingValueTotal = this.answerDataList.reduce((sum, e) => {
        return sum + e.processingValueTotal;
      }, 0);
      res.transportValueTotal = this.answerDataList.reduce((sum, e) => {
        return sum + e.transportValueTotal;
      }, 0);
      res.resOtherValueTotal = this.answerDataList.reduce((sum, e) => {
        return sum + e.resOtherValueTotal;
      }, 0);
      res.resOtherValueGeneral = this.answerDataList.reduce((sum, e) => {
        return sum + e.resOtherValueGeneral;
      }, 0);
      res.resOtherValueMeasure = this.answerDataList.reduce((sum, e) => {
        return sum + e.resOtherValueMeasure;
      }, 0);
      res.resIncomeValueTotal = this.answerDataList.reduce((sum, e) => {
        return sum + e.resIncomeValueTotal;
      }, 0);
      res.resIncomeValueGeneral = this.answerDataList.reduce((sum, e) => {
        return sum + e.resIncomeValueGeneral;
      }, 0);
      res.resIncomeValueMeasure = this.answerDataList.reduce((sum, e) => {
        return sum + e.resIncomeValueMeasure;
      }, 0);
      res.workPartNum = this.answerDataList.reduce((sum, e) => {
        return sum + e.workPartNum;
      }, 0);
      res.workNum = this.answerDataList.reduce((sum, e) => {
        return sum + e.workNum;
      }, 0);
      res.clericalPartNum = this.answerDataList.reduce((sum, e) => {
        return sum + e.clericalPartNum;
      }, 0);
      res.clericalNum = this.answerDataList.reduce((sum, e) => {
        return sum + e.clericalNum;
      }, 0);
      res.manageNum = this.answerDataList.reduce((sum, e) => {
        return sum + e.manageNum;
      }, 0);
      res.employeeTotalNum = this.answerDataList.reduce((sum, e) => {
        return sum + e.employeeTotalNum;
      }, 0);
      res.workPartAveIncome = this.answerDataList.reduce((sum, e) => {
        return sum + e.workPartAveIncome;
      }, 0);
      res.workAveIncome = this.answerDataList.reduce((sum, e) => {
        return sum + e.workAveIncome;
      }, 0);
      res.clericalPartAveIncome = this.answerDataList.reduce((sum, e) => {
        return sum + e.clericalPartAveIncome;
      }, 0);
      res.clericalAveIncome = this.answerDataList.reduce((sum, e) => {
        return sum + e.clericalAveIncome;
      }, 0);
      res.manageAveIncome = this.answerDataList.reduce((sum, e) => {
        return sum + e.manageAveIncome;
      }, 0);
      res.workAveAge = this.answerDataList.reduce((sum, e) => {
        return sum + e.workAveAge;
      }, 0);
      res.clericalAveAge = this.answerDataList.reduce((sum, e) => {
        return sum + e.clericalAveAge;
      }, 0);
      res.workAveContinuous = this.answerDataList.reduce((sum, e) => {
        return sum + e.workAveContinuous;
      }, 0);
      res.clericalAveContinuous = this.answerDataList.reduce((sum, e) => {
        return sum + e.clericalAveContinuous;
      }, 0);
      res.consignWorkNum = this.answerDataList.reduce((sum, e) => {
        return sum + e.consignWorkNum;
      }, 0);
      res.consignClericalNum = this.answerDataList.reduce((sum, e) => {
        return sum + e.consignClericalNum;
      }, 0);
      res.consignWorkAveIncome = this.answerDataList.reduce((sum, e) => {
        return sum + e.consignWorkAveIncome;
      }, 0);
      res.consignClericalAveIncome = this.answerDataList.reduce((sum, e) => {
        return sum + e.consignClericalAveIncome;
      }, 0);
      res.contractElectricity = this.answerDataList.reduce((sum, e) => {
        return sum + e.contractElectricity;
      }, 0);
      res.usageElectricity = this.answerDataList.reduce((sum, e) => {
        return sum + e.usageElectricity;
      }, 0);
      res.paymentElectricity = this.answerDataList.reduce((sum, e) => {
        return sum + e.paymentElectricity;
      }, 0);
      res.profitAndLoss = this.answerDataList.reduce((sum, e) => {
        return sum + e.profitAndLoss;
      }, 0);
      res.numDock = this.answerDataList.reduce((sum, e) => {
        return sum + e.numDock;
      }, 0);
      res.refrigeratorTemp = this.answerDataList.reduce((sum, e) => {
        return sum + e.refrigeratorTemp;
      }, 0);
      res.numClient = this.answerDataList.reduce((sum, e) => {
        return sum + e.numClient;
      }, 0);
      res.numNewClient = this.answerDataList.reduce((sum, e) => {
        return sum + e.numNewClient;
      }, 0);
      res.usagePallet = this.answerDataList.reduce((sum, e) => {
        return sum + e.usagePallet;
      }, 0);
      res.stockPallet = this.answerDataList.reduce((sum, e) => {
        return sum + e.stockPallet;
      }, 0);
      res.stowageVertical = this.answerDataList.reduce((sum, e) => {
        return sum + e.stowageVertical;
      }, 0);
      res.stowageHorizontal = this.answerDataList.reduce((sum, e) => {
        return sum + e.stowageHorizontal;
      }, 0);
      res.stowageHeight = this.answerDataList.reduce((sum, e) => {
        return sum + e.stowageHeight;
      }, 0);
      res.resVolumeFacility = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resVolumeFacility;
      }, 0), 1);
      res.resMeasureFacility = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resMeasureFacility;
      }, 0), 1);
      res.resInFacility = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resInFacility;
      }, 0), 1);
      res.resOutFacility = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resOutFacility;
      }, 0), 1);
      res.resPrevFacility = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resPrevFacility;
      }, 0), 10);
      res.resInterFacility = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resInterFacility;
      }, 0), 10);
      res.resRateStockYearAve = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resRateStockYearAve;
      }, 0), 100);
      res.resStockSimpleFacilityAve = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resStockSimpleFacilityAve;
      }, 0) / this.answerDataCount, 100);
      res.resTurnoverYear = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resTurnoverYear;
      }, 0), 10);
      res.resStockPrevInterAve = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resStockPrevInterAve;
      }, 0), 10);
      res.resStockFacilityAve = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resStockFacilityAve;
      }, 0), 10);
      res.resIncomeStorage = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resIncomeStorage;
      }, 0), 10);
      res.resIncomeCargo = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resIncomeCargo;
      }, 0), 10);
      res.resIncomeFrozen = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resIncomeFrozen;
      }, 0), 10);
      res.resChargeWorkTotal = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resChargeWorkTotal;
      }, 0), 10);
      res.resChargeWork = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resChargeWork;
      }, 0), 10);
      res.resChargeVolume = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resChargeVolume;
      }, 0), 10);
      res.resChargeTotal = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resChargeTotal;
      }, 0), 10);
      res.resChargeGeneral = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resChargeGeneral;
      }, 0), 10);
      res.resChargeMeasure = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resChargeMeasure;
      }, 0), 10);
      res.resChargeProcessing = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resChargeProcessing;
      }, 0), 10);
      res.resChargeTransport = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resChargeTransport;
      }, 0), 10);
      res.resIncomeOtherTotal = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resIncomeOtherTotal;
      }, 0), 10);
      res.resIncomeTotal = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resIncomeTotal;
      }, 0), 10);
      res.resIncomeStorageFacility = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resIncomeStorageFacility;
      }, 0), 10);
      res.resStorageNum = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resStorageNum;
      }, 0), 10);
      res.resIncomeCargoFacility = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resIncomeCargoFacility;
      }, 0), 10);
      res.resCargoNum = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resCargoNum;
      }, 0), 10);
      res.resIncomeFacility = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resIncomeFacility;
      }, 0), 10);
      res.resWorkTotal = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resWorkTotal;
      }, 0), 100);
      res.resWorkEmployee = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resWorkEmployee;
      }, 0), 100);
      res.resWorkConsign = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resWorkConsign;
      }, 0), 100);
      res.resClericalTotal = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resClericalTotal;
      }, 0), 100);
      res.resClericalEmployee = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resClericalEmployee;
      }, 0), 100);
      res.resClericalConsign = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resClericalConsign;
      }, 0), 100);
      res.resManageEmployee = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resManageEmployee;
      }, 0), 100);
      res.resWorkerTotal = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resWorkerTotal;
      }, 0), 100);
      res.resEmployeeTotal = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resEmployeeTotal;
      }, 0), 100);
      res.resConsignTotal = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resConsignTotal;
      }, 0), 100);
      res.resSalesPerson = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resSalesPerson;
      }, 0), 1);
      res.resSalesFacility = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resSalesFacility;
      }, 0), 1);
      res.resTonPerson = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resTonPerson;
      }, 0), 1);
      res.resTonFacilityPerson = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resTonFacilityPerson;
      }, 0), 1);
      res.resTonWork1 = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resTonWork1;
      }, 0), 1);
      res.resTonWork2 = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resTonWork2;
      }, 0), 1);
      res.resFacilityWork1 = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resFacilityWork1;
      }, 0), 1);
      res.resContractElectricityFacility = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resContractElectricityFacility;
      }, 0), 10);
      res.resUsageElectricityFacility = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resUsageElectricityFacility;
      }, 0), 1);
      res.resRateElectricity = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resRateElectricity;
      }, 0), 10);
      res.resRateElectricityFacility = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resRateElectricityFacility;
      }, 0), 10);
      res.resPaymentElectricityFacility = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resPaymentElectricityFacility;
      }, 0), 1);
      res.resUnitPriceElectricity = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resUnitPriceElectricity;
      }, 0), 10);
      res.resUnitPriceElectricityFacility = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resUnitPriceElectricityFacility;
      }, 0), 10);
      res.resProfitAndLossFacility = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resProfitAndLossFacility;
      }, 0), 1);
      res.resWeightPerPiece = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resWeightPerPiece;
      }, 0), 10);
      res.resStowageCapacity = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resStowageCapacity;
      }, 0), 10);
      res.resWeight = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resWeight;
      }, 0), 100);
      res.resNumDockFacility = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resNumDockFacility;
      }, 0), 10);

      return res;
    },

    /** 平均 */
    calcAve() {
      let res = {
        volume: 0, volumeValueGeneral: 0, volumeValueMeasure: 0, inValueTotal: 0, inValueGeneral: 0, inValueMeasure: 0, outValueTotal: 0, outValueGeneral: 0, outValueMeasure: 0, prevValueGeneral: 0, interValueGeneral: 0,
        storageValueTotal: 0, storageValueGeneral: 0, storageValueMeasure: 0, cargoValueTotal: 0, cargoValueGeneral: 0, cargoValueMeasure: 0, frozenValueGeneral: 0, workValueTotal: 0, workValueGeneral: 0, workValueMeasure: 0, chargeValueTotal: 0, chargeValueGeneral: 0, chargeValueMeasure: 0, processingValueTotal: 0, transportValueTotal: 0, resOtherValueTotal: 0, resOtherValueGeneral: 0, resOtherValueMeasure: 0, resIncomeValueTotal: 0, resIncomeValueGeneral: 0, resIncomeValueMeasure: 0,
        workPartNum: 0, workNum: 0, clericalPartNum: 0, clericalNum: 0, manageNum: 0, employeeTotalNum: 0, workPartAveIncome: 0, workAveIncome: 0, clericalPartAveIncome: 0, clericalAveIncome: 0, manageAveIncome: 0,
        workAveAge: 0, clericalAveAge: 0, workAveContinuous: 0, clericalAveContinuous: 0, consignWorkNum: 0, consignClericalNum: 0, consignWorkAveIncome: 0, consignClericalAveIncome: 0,
        contractElectricity: 0, usageElectricity: 0, paymentElectricity: 0, profitAndLoss: 0, numDock: 0, refrigeratorTemp: 0, numClient: 0, numNewClient: 0, usagePallet: 0, stockPallet: 0, stowageVertical: 0, stowageHorizontal: 0, stowageHeight: 0,
        resVolumeFacility: 0, resMeasureFacility: 0, resInFacility: 0, resOutFacility: 0, resPrevFacility: 0, resInterFacility: 0, resRateStockYearAve: 0, resStockSimpleFacilityAve: 0, resTurnoverYear: 0, resStockPrevInterAve: 0, resStockFacilityAve: 0,
        resIncomeStorage: 0, resIncomeCargo: 0, resIncomeFrozen: 0, resChargeWorkTotal: 0, resChargeWork: 0, resChargeVolume: 0, resChargeTotal: 0, resChargeGeneral: 0, resChargeMeasure: 0, resChargeProcessing: 0, resChargeTransport: 0, resIncomeOtherTotal: 0, resIncomeTotal: 0,
        resIncomeStorageFacility: 0, resStorageNum: 0, resIncomeCargoFacility: 0, resCargoNum: 0, resIncomeFacility: 0, resWorkTotal: 0, resWorkEmployee: 0, resWorkConsign: 0, resClericalTotal: 0, resClericalEmployee: 0, resClericalConsign: 0, resManageEmployee: 0, resWorkerTotal: 0, resEmployeeTotal: 0, resConsignTotal: 0,
        resSalesPerson: 0, resSalesFacility: 0, resTonPerson: 0, resTonFacilityPerson: 0, resTonWork1: 0, resTonWork2: 0, resFacilityWork1: 0,
        resContractElectricityFacility: 0, resUsageElectricityFacility: 0, resRateElectricity: 0, resRateElectricityFacility: 0, resPaymentElectricityFacility: 0, resUnitPriceElectricity: 0, resUnitPriceElectricityFacility: 0,
        resProfitAndLossFacility: 0, resWeightPerPiece: 0, resStowageCapacity: 0, resWeight: 0, resNumDockFacility: 0,
      };

      // 回答が1件もない場合初期データを返す
      if(this.answerDataCount <= 0) return res;

      res.volume = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.volume;
      }, 0) / this.answerDataCount, 1);
      res.volumeValueGeneral = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.volumeValueGeneral;
      }, 0) / this.answerDataCount, 1);
      res.volumeValueMeasure = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.volumeValueMeasure;
      }, 0) / this.answerDataCount, 1);
      res.inValueTotal = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.inValueTotal;
      }, 0) / this.answerDataCount, 1);
      res.inValueGeneral = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.inValueGeneral;
      }, 0) / this.answerDataCount, 1);
      res.inValueMeasure = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.inValueMeasure;
      }, 0) / this.answerDataCount, 1);
      res.outValueTotal = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.outValueTotal;
      }, 0) / this.answerDataCount, 1);
      res.outValueGeneral = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.outValueGeneral;
      }, 0) / this.answerDataCount, 1);
      res.outValueMeasure = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.outValueMeasure;
      }, 0) / this.answerDataCount, 1);
      res.prevValueGeneral = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.prevValueGeneral;
      }, 0) / this.answerDataCount, 1);
      res.interValueGeneral = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.interValueGeneral;
      }, 0) / this.answerDataCount, 1);
      res.storageValueTotal = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.storageValueTotal;
      }, 0) / this.answerDataCount, 1);
      res.storageValueGeneral = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.storageValueGeneral;
      }, 0) / this.answerDataCount, 1);
      res.storageValueMeasure = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.storageValueMeasure;
      }, 0) / this.answerDataCount, 1);
      res.cargoValueTotal = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.cargoValueTotal;
      }, 0) / this.answerDataCount, 1);
      res.cargoValueGeneral = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.cargoValueGeneral;
      }, 0) / this.answerDataCount, 1);
      res.cargoValueMeasure = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.cargoValueMeasure;
      }, 0) / this.answerDataCount, 1);
      res.frozenValueGeneral = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.frozenValueGeneral;
      }, 0) / this.answerDataCount, 1);
      res.workValueTotal = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.workValueTotal;
      }, 0) / this.answerDataCount, 1);
      res.workValueGeneral = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.workValueGeneral;
      }, 0) / this.answerDataCount, 1);
      res.workValueMeasure = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.workValueMeasure;
      }, 0) / this.answerDataCount, 1);
      res.chargeValueTotal = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.chargeValueTotal;
      }, 0) / this.answerDataCount, 1);
      res.chargeValueGeneral = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.chargeValueGeneral;
      }, 0) / this.answerDataCount, 1);
      res.chargeValueMeasure = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.chargeValueMeasure;
      }, 0) / this.answerDataCount, 1);
      res.processingValueTotal = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.processingValueTotal;
      }, 0) / this.answerDataCount, 1);
      res.transportValueTotal = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.transportValueTotal;
      }, 0) / this.answerDataCount, 1);
      res.resOtherValueTotal = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resOtherValueTotal;
      }, 0) / this.answerDataCount, 1);
      res.resOtherValueGeneral = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resOtherValueGeneral;
      }, 0) / this.answerDataCount, 1);
      res.resOtherValueMeasure = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resOtherValueMeasure;
      }, 0) / this.answerDataCount, 1);
      res.resIncomeValueTotal = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resIncomeValueTotal;
      }, 0) / this.answerDataCount, 1);
      res.resIncomeValueGeneral = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resIncomeValueGeneral;
      }, 0) / this.answerDataCount, 1);
      res.resIncomeValueMeasure = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resIncomeValueMeasure;
      }, 0) / this.answerDataCount, 1);
      res.workPartNum = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.workPartNum;
      }, 0) / this.answerDataCount, 1);
      res.workNum = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.workNum;
      }, 0) / this.answerDataCount, 1);
      res.clericalPartNum = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.clericalPartNum;
      }, 0) / this.answerDataCount, 1);
      res.clericalNum = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.clericalNum;
      }, 0) / this.answerDataCount, 1);
      res.manageNum = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.manageNum;
      }, 0) / this.answerDataCount, 1);
      res.employeeTotalNum = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.employeeTotalNum;
      }, 0) / this.answerDataCount, 1);
      res.workPartAveIncome = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.workPartAveIncome;
      }, 0) / this.answerDataCount, 1);
      res.workAveIncome = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.workAveIncome;
      }, 0) / this.answerDataCount, 1);
      res.clericalPartAveIncome = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.clericalPartAveIncome;
      }, 0) / this.answerDataCount, 1);
      res.clericalAveIncome = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.clericalAveIncome;
      }, 0) / this.answerDataCount, 1);
      res.manageAveIncome = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.manageAveIncome;
      }, 0) / this.answerDataCount, 1);
      res.workAveAge = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.workAveAge;
      }, 0) / this.answerDataCount, 1);
      res.clericalAveAge = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.clericalAveAge;
      }, 0) / this.answerDataCount, 1);
      res.workAveContinuous = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.workAveContinuous;
      }, 0) / this.answerDataCount, 1);
      res.clericalAveContinuous = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.clericalAveContinuous;
      }, 0) / this.answerDataCount, 1);
      res.consignWorkNum = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.consignWorkNum;
      }, 0) / this.answerDataCount, 1);
      res.consignClericalNum = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.consignClericalNum;
      }, 0) / this.answerDataCount, 1);
      res.consignWorkAveIncome = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.consignWorkAveIncome;
      }, 0) / this.answerDataCount, 1);
      res.consignClericalAveIncome = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.consignClericalAveIncome;
      }, 0) / this.answerDataCount, 1);
      res.contractElectricity = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.contractElectricity;
      }, 0) / this.answerDataCount, 1);
      res.usageElectricity = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.usageElectricity;
      }, 0) / this.answerDataCount, 1);
      res.paymentElectricity = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.paymentElectricity;
      }, 0) / this.answerDataCount, 1);
      res.profitAndLoss = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.profitAndLoss;
      }, 0) / this.answerDataCount, 1);
      res.numDock = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.numDock;
      }, 0) / this.answerDataCount, 1);
      res.refrigeratorTemp = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.refrigeratorTemp;
      }, 0) / this.answerDataCount, 1);
      res.numClient = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.numClient;
      }, 0) / this.answerDataCount, 1);
      res.numNewClient = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.numNewClient;
      }, 0) / this.answerDataCount, 1);
      res.usagePallet = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.usagePallet;
      }, 0) / this.answerDataCount, 1);
      res.stockPallet = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.stockPallet;
      }, 0) / this.answerDataCount, 1);
      res.stowageVertical = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.stowageVertical;
      }, 0) / this.answerDataCount, 1);
      res.stowageHorizontal = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.stowageHorizontal;
      }, 0) / this.answerDataCount, 1);
      res.stowageHeight = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.stowageHeight;
      }, 0) / this.answerDataCount, 1);
      res.resVolumeFacility = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resVolumeFacility;
      }, 0) / this.answerDataCount, 1);
      res.resMeasureFacility = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resMeasureFacility;
      }, 0) / this.answerDataCount, 1);
      res.resInFacility = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resInFacility;
      }, 0) / this.answerDataCount, 1);
      res.resOutFacility = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resOutFacility;
      }, 0) / this.answerDataCount, 1);
      res.resPrevFacility = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resPrevFacility;
      }, 0) / this.answerDataCount, 10);
      res.resInterFacility = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resInterFacility;
      }, 0) / this.answerDataCount, 10);
      res.resRateStockYearAve = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resRateStockYearAve;
      }, 0) / this.answerDataCount, 100);
      res.resStockSimpleFacilityAve = Util.orgRound(
        this.resTotal.resStockSimpleFacilityAve / 1000 * 100, 100);  // 単純設備㌧平均在庫 平均 (単純設備㌧平均在庫 合計÷1000×100)
      res.resStockPrevInterAve = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resStockPrevInterAve;
      }, 0) / this.answerDataCount, 10);
      res.resStockFacilityAve = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resStockFacilityAve;
      }, 0) / this.answerDataCount, 10);
      res.resTurnoverYear = Util.orgRound((res.resInFacility + res.resOutFacility)
                                            / 2 / res.resStockFacilityAve, 10); // 回転数年間(回) 平均((年間入庫量1000設備㌧ 平均+年間出庫量1000設備㌧ 平均)÷2÷平均在庫1000設備㌧ 平均)
      res.resIncomeStorage = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resIncomeStorage;
      }, 0) / this.answerDataCount, 10);
      res.resIncomeCargo = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resIncomeCargo;
      }, 0) / this.answerDataCount, 10);
      res.resIncomeFrozen = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resIncomeFrozen;
      }, 0) / this.answerDataCount, 10);
      res.resChargeWorkTotal = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resChargeWorkTotal;
      }, 0) / this.answerDataCount, 10);
      res.resChargeWork = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resChargeWork;
      }, 0) / this.answerDataCount, 10);
      res.resChargeVolume = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resChargeVolume;
      }, 0) / this.answerDataCount, 10);
      res.resChargeTotal = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resChargeTotal;
      }, 0) / this.answerDataCount, 10);
      res.resChargeGeneral = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resChargeGeneral;
      }, 0) / this.answerDataCount, 10);
      res.resChargeMeasure = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resChargeMeasure;
      }, 0) / this.answerDataCount, 10);
      res.resChargeProcessing = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resChargeProcessing;
      }, 0) / this.answerDataCount, 10);
      res.resChargeTransport = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resChargeTransport;
      }, 0) / this.answerDataCount, 10);
      res.resIncomeOtherTotal = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resIncomeOtherTotal;
      }, 0) / this.answerDataCount, 10);
      res.resIncomeTotal = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resIncomeTotal;
      }, 0) / this.answerDataCount, 10);
      res.resIncomeStorageFacility = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resIncomeStorageFacility;
      }, 0) / this.answerDataCount, 10);
      res.resStorageNum = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resStorageNum;
      }, 0) / this.answerDataCount, 10);
      res.resIncomeCargoFacility = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resIncomeCargoFacility;
      }, 0) / this.answerDataCount, 10);
      res.resCargoNum = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resCargoNum;
      }, 0) / this.answerDataCount, 10);
      res.resIncomeFacility = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resIncomeFacility;
      }, 0) / this.answerDataCount, 10);
      res.resWorkTotal = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resWorkTotal;
      }, 0) / this.answerDataCount, 100);
      res.resWorkEmployee = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resWorkEmployee;
      }, 0) / this.answerDataCount, 100);
      res.resWorkConsign = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resWorkConsign;
      }, 0) / this.answerDataCount, 100);
      res.resClericalTotal = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resClericalTotal;
      }, 0) / this.answerDataCount, 100);
      res.resClericalEmployee = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resClericalEmployee;
      }, 0) / this.answerDataCount, 100);
      res.resClericalConsign = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resClericalConsign;
      }, 0) / this.answerDataCount, 100);
      res.resManageEmployee = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resManageEmployee;
      }, 0) / this.answerDataCount, 100);
      res.resWorkerTotal = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resWorkerTotal;
      }, 0) / this.answerDataCount, 100);
      res.resEmployeeTotal = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resEmployeeTotal;
      }, 0) / this.answerDataCount, 100);
      res.resConsignTotal = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resConsignTotal;
      }, 0) / this.answerDataCount, 100);
      res.resSalesPerson = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resSalesPerson;
      }, 0) / this.answerDataCount, 1);
      res.resSalesFacility = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resSalesFacility;
      }, 0) / this.answerDataCount, 1);
      res.resTonPerson = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resTonPerson;
      }, 0) / this.answerDataCount, 1);
      res.resTonFacilityPerson = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resTonFacilityPerson;
      }, 0) / this.answerDataCount, 1);
      res.resTonWork1 = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resTonWork1;
      }, 0) / this.answerDataCount, 1);
      res.resTonWork2 = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resTonWork2;
      }, 0) / this.answerDataCount, 1);
      res.resFacilityWork1 = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resFacilityWork1;
      }, 0) / this.answerDataCount, 1);
      res.resContractElectricityFacility = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resContractElectricityFacility;
      }, 0) / this.answerDataCount, 10);
      res.resUsageElectricityFacility = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resUsageElectricityFacility;
      }, 0) / this.answerDataCount, 1);
      res.resRateElectricity = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resRateElectricity;
      }, 0) / this.answerDataCount, 10);
      res.resRateElectricityFacility = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resRateElectricityFacility;
      }, 0) / this.answerDataCount, 10);
      res.resPaymentElectricityFacility = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resPaymentElectricityFacility;
      }, 0) / this.answerDataCount, 1);
      res.resUnitPriceElectricity = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resUnitPriceElectricity;
      }, 0) / this.answerDataCount, 10);
      res.resUnitPriceElectricityFacility = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resUnitPriceElectricityFacility;
      }, 0) / this.answerDataCount, 10);
      res.resProfitAndLossFacility = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resProfitAndLossFacility;
      }, 0) / this.answerDataCount, 1);
      res.resWeightPerPiece = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resWeightPerPiece;
      }, 0) / this.answerDataCount, 10);
      res.resStowageCapacity = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resStowageCapacity;
      }, 0) / this.answerDataCount, 10);
      res.resWeight = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resWeight;
      }, 0) / this.answerDataCount, 100);
      res.resNumDockFacility = Util.orgRound(this.answerDataList.reduce((sum, e) => {
        return sum + e.resNumDockFacility;
      }, 0) / this.answerDataCount, 10);

      return res;
    },


    rowEdit(memberNo) {
        if (this.editMode) {
            this.select_member_no = memberNo;
        } else {
            this.select_member_no = '';
        }
    },

  },

  watch: {
    answerDataList: {
      handler(val, oldVal) {
        Debug.log('answerDataList changed');
        Debug.log(val);
        Debug.log(oldVal);
        val.forEach((answer, index) => {
          // 各自動計算を実行(computedをテーブルに出力するとソートされないので、methodで計算してデータ設定する)
          this.answerDataList[index].resOtherValueTotal = this.calcOtherValueTotal(index);                // その他収入合計
          this.answerDataList[index].resOtherValueGeneral = this.calcOtherValueGeneral(index);            // その他収入一般
          this.answerDataList[index].resOtherValueMeasure = this.calcOtherValueMeasure(index);            // その他収入容積
          this.answerDataList[index].resIncomeValueTotal = this.calcIncomeValueTotal(index);              // 収入合計
          this.answerDataList[index].resIncomeValueGeneral = this.calcIncomeValueGeneral(index);          // 収入合計一般
          this.answerDataList[index].resIncomeValueMeasure = this.calcIncomeValueMeasure(index)           // 収入合計容積
          this.answerDataList[index].resVolumeFacility = this.calcVolumeFacility(index);                  // 所管容積設備㌧
          this.answerDataList[index].resMeasureFacility = this.calcMeasureFacility(index);                // 除容積建設備能力
          this.answerDataList[index].resInFacility = this.calcInFacility(index);                          // 年間入庫量1000設備㌧
          this.answerDataList[index].resOutFacility = this.calcOutFacility(index);                        // 年間出庫量1000設備㌧
          this.answerDataList[index].resPrevFacility = this.calcPrevFacility(index);                      // 前月繰越数量1000設備㌧
          this.answerDataList[index].resInterFacility = this.calcInterFacility(index);                    // 中間繰越数量1000設備㌧
          this.answerDataList[index].resRateStockYearAve = this.calcRateStockYearAve(index);              // 在庫率年平均%
          this.answerDataList[index].resStockSimpleFacilityAve = this.calcStockSimpleFacilityAve(index);  // 単純設備㌧平均在庫
          this.answerDataList[index].resTurnoverYear = this.calcTurnoverYear(index);                      // 回転数年間(回)
          this.answerDataList[index].resStockPrevInterAve = this.calcStockPrevInterAve(index);            // (前月+中間)/2平均在庫
          this.answerDataList[index].resStockFacilityAve = this.calcStockFacilityAve(index);              // 平均在庫1000設備㌧
          this.answerDataList[index].resIncomeStorage = this.calcIncomeStorage(index);                    // 保管料収入(￥/設備㌧)
          this.answerDataList[index].resIncomeCargo = this.calcIncomeCargo(index);                        // 荷役料収入(￥/設備㌧)
          this.answerDataList[index].resIncomeFrozen = this.calcIncomeFrozen(index);                      // 凍結料収入(￥/設備㌧)
          this.answerDataList[index].resChargeWorkTotal = this.calcChargeWorkTotal(index);                // 他作業料計(￥/設備㌧)
          this.answerDataList[index].resChargeWork = this.calcChargeWork(index);                          // 他作業料一(￥/設備㌧)
          this.answerDataList[index].resChargeVolume = this.calcChargeVolume(index);                      // 他作業容積(￥/設備㌧)
          this.answerDataList[index].resChargeTotal = this.calcChargeTotal(index);                        // 他手数料計(￥/設備㌧)
          this.answerDataList[index].resChargeGeneral = this.calcChargeGeneral(index);                    // 他手数一般(￥/設備㌧)
          this.answerDataList[index].resChargeMeasure = this.calcChargeMeasure(index);                    // 他手数容積(￥/設備㌧)
          this.answerDataList[index].resChargeProcessing = this.calcChargeProcessing(index);              // 流通加工(￥/設備㌧)
          this.answerDataList[index].resChargeTransport = this.calcChargeTransport(index);                // 利用運送(￥/設備㌧)
          this.answerDataList[index].resIncomeOtherTotal = this.calcIncomeOtherTotal(index);              // 他収入合計(￥/設備㌧)
          this.answerDataList[index].resIncomeTotal = this.calcIncomeTotal(index);                        // 収入合計(￥/設備㌧)
          this.answerDataList[index].resIncomeStorageFacility = this.calcIncomeStorageFacility(index);    // 保管収入㌧1000設備㌧
          this.answerDataList[index].resStorageNum = this.calcStorageNum(index);                          // 保管積数(kg/設備㌧)
          this.answerDataList[index].resIncomeCargoFacility = this.calcIncomeCargoFacility(index);        // 荷役収入㌧1000設備㌧
          this.answerDataList[index].resCargoNum = this.calcCargoNum(index);                              // 荷役積数(kg/設備㌧)
          this.answerDataList[index].resIncomeFacility = this.calcIncomeFacility(index);                  // 設備㌧収入小計(￥/設備㌧)
          this.answerDataList[index].resWorkTotal = this.calcWorkTotal(index);                            // 現場従業員1000設備㌧
          this.answerDataList[index].resWorkEmployee = this.calcWorkEmployee(index);                      // (現場社員)1000設備㌧
          this.answerDataList[index].resWorkConsign = this.calcWorkConsign(index);                        // (現場委託)1000設備㌧
          this.answerDataList[index].resClericalTotal = this.calcClericalTotal(index);                    // 事務所従業員1000設備㌧
          this.answerDataList[index].resClericalEmployee = this.calcClericalEmployee(index);              // (事務社員)1000設備㌧
          this.answerDataList[index].resClericalConsign = this.calcClericalConsign(index);                // (事務委託)1000設備㌧
          this.answerDataList[index].resManageEmployee = this.calcManageEmployee(index);                  // 社員管理者1000設備㌧
          this.answerDataList[index].resWorkerTotal = this.calcWorkerTotal(index);                        // 従業員合計1000設備㌧
          this.answerDataList[index].resEmployeeTotal = this.calcEmployeeTotal(index);                    // (社員計)1000設備㌧
          this.answerDataList[index].resConsignTotal = this.calcConsignTotal(index);                      // (外部委託計)1000設備㌧
          this.answerDataList[index].resSalesPerson = this.calcSalesPerson(index);                        // 一人当り売上(千円/人)
          this.answerDataList[index].resSalesFacility = this.calcSalesFacility(index);                    // 一人当り売上1000設備㌧
          this.answerDataList[index].resTonPerson = this.calcTonPerson(index);                            // 一人当り扱量(t/年人)
          this.answerDataList[index].resTonFacilityPerson = this.calcTonFacilityPerson(index);            // 一人当り扱量1000設備㌧
          this.answerDataList[index].resTonWork1 = this.calcTonWork1(index);                              // 現場従業員一人当り扱量1
          this.answerDataList[index].resTonWork2 = this.calcTonWork2(index);                              // 現場従業員一人当り扱量2
          this.answerDataList[index].resFacilityWork1 = this.calcFacilityWork1(index);                    // 1従業員当り設備㌧
          this.answerDataList[index].resContractElectricityFacility = this.calcContractElectricityFacility(index);  // 契約電力設備㌧
          this.answerDataList[index].resUsageElectricityFacility = this.calcUsageElectricityFacility(index);        // 電力使用量設備㌧
          this.answerDataList[index].resRateElectricity = this.calcRateElectricity(index);                          // 契約電力有効率
          this.answerDataList[index].resRateElectricityFacility = this.calcRateElectricityFacility(index);          // 電力有効率設備㌧
          this.answerDataList[index].resPaymentElectricityFacility = this.calcPaymentElectricityFacility(index);    // 電力支払額設備㌧
          this.answerDataList[index].resUnitPriceElectricity = this.calcUnitPriceElectricity(index);                // KWH単価
          this.answerDataList[index].resUnitPriceElectricityFacility = this.calcUnitPriceElectricityFacility(index);// KWH単価設備㌧
          this.answerDataList[index].resProfitAndLossFacility = this.calcProfitAndLossFacility(index);              // 業務損益設備㌧
          this.answerDataList[index].resWeightPerPiece = this.calcWeightPerPiece(index);                            // 1P当重量kg
          this.answerDataList[index].resStowageCapacity = this.calcStowageCapacity(index);                        // P積付容積
          this.answerDataList[index].resWeight = this.calcWeight(index);                                            // 貨物比重
          this.answerDataList[index].resNumDockFacility = this.calcNumDockFacility(index);                          // バース数1000設備㌧
          this.answerDataList[index].resScale = this.calcScale(index);                                              // 規模
          this.answerDataList[index].resTurnover = this.calcTurnover(index);                                        // 回転
          this.answerDataList[index].resEmployee = this.calcEmployee(index);                                        // 従業員
          this.answerDataList[index].resStockTurnover = this.calcStockTurnover(index);                              // 在庫回転
          this.answerDataList[index].resElectricityError = this.calcElectricityError(index);                        // 電力異常
          this.answerDataList[index].resSales = this.calcSales(index);                                              // 売上
          this.answerDataList[index].resPallet = this.calcPallet(index);                                            // パレット
          this.answerDataList[index].resCompanyScale = this.calcCompanyScale(index);                                // 大企業中小企業
          this.answerDataList[index].resTonIncomeStorage = this.calcTonIncomeStorage(index);                        // 保管収入㌧
          this.answerDataList[index].resTonIncomeCargo = this.calcTonIncomeCargo(index);                            // 荷役収入㌧

          // NG判定件数を計算
          Debug.log(answer);
          this.$nextTick(() => {
            this.calcCheckCount(index);
          });
        });

        // 合計の計算
        this.resTotal = this.calcTotal();
        // 平均の計算
        this.resAve = this.calcAve();
      },
      deep: true
    },
  },

  computed :{
    /** 1000設備㌧当り */
    calcFacility() {
      let res = {
        inValueTotal: 0, inValueGeneral: 0, inValueMeasure: 0, outValueTotal: 0, outValueGeneral: 0, outValueMeasure: 0, prevValueGeneral: 0, interValueGeneral: 0,
        storageValueTotal: 0, storageValueGeneral: 0, storageValueMeasure: 0, cargoValueTotal: 0, cargoValueGeneral: 0, cargoValueMeasure: 0, frozenValueGeneral: 0, workValueTotal: 0, workValueGeneral: 0, workValueMeasure: 0, chargeValueTotal: 0, chargeValueGeneral: 0, chargeValueMeasure: 0, processingValueTotal: 0, transportValueTotal: 0, resOtherValueTotal: 0, resOtherValueGeneral: 0, resOtherValueMeasure: 0, resIncomeValueTotal: 0, resIncomeValueGeneral: 0, resIncomeValueMeasure: 0,
        workPartNum: 0, workNum: 0, clericalPartNum: 0, clericalNum: 0, manageNum: 0, employeeTotalNum: 0, workPartAveIncome: 0, workAveIncome: 0, clericalPartAveIncome: 0, clericalAveIncome: 0, manageAveIncome: 0, workAveAge: 0, clericalAveAge: 0, workAveContinuous: 0, clericalAveContinuous: 0, consignWorkNum: 0, consignClericalNum: 0, consignWorkAveIncome: 0, consignClericalAveIncome: 0,
        contractElectricity: 0, usageElectricity: 0, paymentElectricity: 0, profitAndLoss: 0, numDock: 0, numClient: 0, numNewClient: 0, usagePallet: 0, stockPallet: 0,
        resInFacility: 0, resOutFacility: 0, resPrevFacility: 0, resInterFacility: 0, resRateStockYearAve: 0, resTurnoverYear: 0, resStockPrevInterAve: 0,
        resChargeWork: 0, resChargeGeneral: 0, resChargeProcessing: 0, resChargeTransport: 0,
        resStorageNum: 0, resCargoNum: 0, resIncomeFacility: 0,
        resWorkTotal:0, resWorkEmployee: 0, resWorkConsign: 0, resClericalTotal: 0, resClericalEmployee: 0, resClericalConsign: 0, resManageEmployee: 0, resWorkerTotal: 0, resEmployeeTotal: 0, resConsignTotal: 0,
        resSalesPerson: 0, resTonPerson: 0, resTonWork1: 0, resFacilityWork1: 0, resContractElectricityFacility: 0, resUsageElectricityFacility: 0, resRateElectricity: 0, resPaymentElectricityFacility: 0, resUnitPriceElectricity: 0, resProfitAndLossFacility: 0, resWeightPerPiece: 0, resWeight: 0, resNumDockFacility: 0,
      };

      // 回答が1件もない場合初期データを返す
      if(this.answerDataCount <= 0) return res;

      // 年間入庫 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // 年間入庫 合計÷除容積建設備能力 合計×1000
        res.inValueTotal = Util.orgRound(this.resTotal.inValueTotal / this.resTotal.resMeasureFacility * 1000, 1);
      }
      // 入一般 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // 入一般 合計÷除容積建設備能力 合計×1000
        res.inValueGeneral = Util.orgRound(this.resTotal.inValueGeneral / this.resTotal.resMeasureFacility * 1000, 1);
      }
      // 入容積建 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // 入容積建 合計÷除容積建設備能力 合計×1000
        res.inValueMeasure = Util.orgRound(this.resTotal.inValueMeasure / this.resTotal.resMeasureFacility * 1000, 1);
      }
      // 年間出庫 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // 年間出庫 合計÷除容積建設備能力 合計×1000
        res.outValueTotal = Util.orgRound(this.resTotal.outValueTotal / this.resTotal.resMeasureFacility * 1000, 1);
      }
      // 出一般 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // 出一般 合計÷除容積建設備能力 合計×1000
        res.outValueGeneral = Util.orgRound(this.resTotal.outValueGeneral / this.resTotal.resMeasureFacility * 1000, 1);
      }
      // 出容積 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // 出容積 合計÷除容積建設備能力 合計×1000
        res.outValueMeasure = Util.orgRound(this.resTotal.outValueMeasure / this.resTotal.resMeasureFacility * 1000, 1);
      }
      // 前月繰越 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // 前月繰越 合計÷除容積建設備能力 合計×1000
        res.prevValueGeneral = Util.orgRound(this.resTotal.prevValueGeneral / this.resTotal.resMeasureFacility * 1000, 1);
      }
      // 中間繰越 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // 中間繰越 合計÷除容積建設備能力 合計×1000
        res.interValueGeneral = Util.orgRound(this.resTotal.interValueGeneral / this.resTotal.resMeasureFacility * 1000, 1);
      }
      // 保管料収入 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // 保管料収入 合計÷除容積建設備能力 合計×1000
        res.storageValueTotal = Util.orgRound(this.resTotal.storageValueTotal / this.resTotal.resMeasureFacility * 1000, 1);
      }
      // 保管料一般 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // 保管料一般 合計÷除容積建設備能力 合計×1000
        res.storageValueGeneral = Util.orgRound(this.resTotal.storageValueGeneral / this.resTotal.resMeasureFacility * 1000, 1);
      }
      // 保管料容積 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // 保管料容積 合計÷除容積建設備能力 合計×1000
        res.storageValueMeasure = Util.orgRound(this.resTotal.storageValueMeasure / this.resTotal.resMeasureFacility * 1000, 1);
      }
      // 荷役料収入 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // 荷役料収入 合計÷除容積建設備能力 合計×1000
        res.cargoValueTotal = Util.orgRound(this.resTotal.cargoValueTotal / this.resTotal.resMeasureFacility * 1000, 1);
      }
      // 荷役料一般 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // 荷役料一般 合計÷除容積建設備能力 合計×1000
        res.cargoValueGeneral = Util.orgRound(this.resTotal.cargoValueGeneral / this.resTotal.resMeasureFacility * 1000, 1);
      }
      // 荷役料容積 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // 荷役料容積 合計÷除容積建設備能力 合計×1000
        res.cargoValueMeasure = Util.orgRound(this.resTotal.cargoValueMeasure / this.resTotal.resMeasureFacility * 1000, 1);
      }
      // 凍結料 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // 凍結料 合計÷除容積建設備能力 合計×1000
        res.frozenValueGeneral = Util.orgRound(this.resTotal.frozenValueGeneral / this.resTotal.resMeasureFacility * 1000, 1);
      }
      // その他作業料合計 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // その他作業料合計 合計÷除容積建設備能力 合計×1000
        res.workValueTotal = Util.orgRound(this.resTotal.workValueTotal / this.resTotal.resMeasureFacility * 1000, 1);
      }
      // その他作業料一般 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // その他作業料一般 合計÷除容積建設備能力 合計×1000
        res.workValueGeneral = Util.orgRound(this.resTotal.workValueGeneral / this.resTotal.resMeasureFacility * 1000, 1);
      }
      // その他作業料容積 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // その他作業料容積 合計÷除容積建設備能力 合計×1000
        res.workValueMeasure = Util.orgRound(this.resTotal.workValueMeasure / this.resTotal.resMeasureFacility * 1000, 1);
      }
      // その他手数料合計 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // その他手数料合計 合計÷除容積建設備能力 合計×1000
        res.chargeValueTotal = Util.orgRound(this.resTotal.chargeValueTotal / this.resTotal.resMeasureFacility * 1000, 1);
      }
      // その他手数料一般 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // その他手数料一般 合計÷除容積建設備能力 合計×1000
        res.chargeValueGeneral = Util.orgRound(this.resTotal.chargeValueGeneral / this.resTotal.resMeasureFacility * 1000, 1);
      }
      // その他手数料容積 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // その他手数料容積 合計÷除容積建設備能力 合計×1000
        res.chargeValueMeasure = Util.orgRound(this.resTotal.chargeValueMeasure / this.resTotal.resMeasureFacility * 1000, 1);
      }
      // 流通加工 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // 流通加工 合計÷除容積建設備能力 合計×1000
        res.processingValueTotal = Util.orgRound(this.resTotal.processingValueTotal / this.resTotal.resMeasureFacility * 1000, 1);
      }
      // 利用運送 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // 利用運送 合計÷除容積建設備能力 合計×1000
        res.transportValueTotal = Util.orgRound(this.resTotal.transportValueTotal / this.resTotal.resMeasureFacility * 1000, 1);
      }
      // その他収入合計 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // その他収入合計 合計÷除容積建設備能力 合計×1000
        res.resOtherValueTotal = Util.orgRound(this.resTotal.resOtherValueTotal / this.resTotal.resMeasureFacility * 1000, 1);
      }
      // その他収入一般 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // その他収入一般 合計÷除容積建設備能力 合計×1000
        res.resOtherValueGeneral = Util.orgRound(this.resTotal.resOtherValueGeneral / this.resTotal.resMeasureFacility * 1000, 1);
      }
      // その他収入容積 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // その他収入容積 合計÷除容積建設備能力 合計×1000
        res.resOtherValueMeasure = Util.orgRound(this.resTotal.resOtherValueMeasure / this.resTotal.resMeasureFacility * 1000, 1);
      }
      // 収入合計 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // 収入合計 合計÷除容積建設備能力 合計×1000
        res.resIncomeValueTotal = Util.orgRound(this.resTotal.resIncomeValueTotal / this.resTotal.resMeasureFacility * 1000, 1);
      }
      // 収入合計一般 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // 収入合計一般 合計÷除容積建設備能力 合計×1000
        res.resIncomeValueGeneral = Util.orgRound(this.resTotal.resIncomeValueGeneral / this.resTotal.resMeasureFacility * 1000, 1);
      }
      // 収入合計容積 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // 収入合計容積 合計÷除容積建設備能力 合計×1000
        res.resIncomeValueMeasure = Util.orgRound(this.resTotal.resIncomeValueMeasure / this.resTotal.resMeasureFacility * 1000, 1);
      }
      // 作業パート人数 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // 作業パート人数 合計÷除容積建設備能力 合計×1000
        res.workPartNum = Util.orgRound(this.resTotal.workPartNum / this.resTotal.resMeasureFacility * 1000, 1);
      }
      // 作業専任人数 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // 作業専任人数 合計÷除容積建設備能力 合計×1000
        res.workNum = Util.orgRound(this.resTotal.workNum / this.resTotal.resMeasureFacility * 1000, 1);
      }
      // 事務パート人数 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // 事務パート人数 合計÷除容積建設備能力 合計×1000
        res.clericalPartNum = Util.orgRound(this.resTotal.clericalPartNum / this.resTotal.resMeasureFacility * 1000, 1);
      }
      // 事務人数 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // 事務人数 合計÷除容積建設備能力 合計×1000
        res.clericalNum = Util.orgRound(this.resTotal.clericalNum / this.resTotal.resMeasureFacility * 1000, 1);
      }
      // 社員管理者 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // 社員管理者 合計÷除容積建設備能力 合計×1000
        res.manageNum = Util.orgRound(this.resTotal.manageNum / this.resTotal.resMeasureFacility * 1000, 1);
      }
      // 社員合計 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // 社員合計 合計÷除容積建設備能力 合計×1000
        res.employeeTotalNum = Util.orgRound(this.resTotal.employeeTotalNum / this.resTotal.resMeasureFacility * 1000, 1);
      }
      // 作業パート賃金 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // 作業パート賃金 合計÷除容積建設備能力 合計×1000
        res.workPartAveIncome = Util.orgRound(this.resTotal.workPartAveIncome / this.resTotal.resMeasureFacility * 1000, 1);
      }
      // 作業専任賃金 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // 作業専任賃金 合計÷除容積建設備能力 合計×1000
        res.workAveIncome = Util.orgRound(this.resTotal.workAveIncome / this.resTotal.resMeasureFacility * 1000, 1);
      }
      // 事務パート賃金 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // 事務パート賃金 合計÷除容積建設備能力 合計×1000
        res.clericalPartAveIncome = Util.orgRound(this.resTotal.clericalPartAveIncome / this.resTotal.resMeasureFacility * 1000, 1);
      }
      // 事務賃金 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // 事務賃金 合計÷除容積建設備能力 合計×1000
        res.clericalAveIncome = Util.orgRound(this.resTotal.clericalAveIncome / this.resTotal.resMeasureFacility * 1000, 1);
      }
      // 社員管理者賃金 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // 社員管理者賃金 合計÷除容積建設備能力 合計×1000
        res.manageAveIncome = Util.orgRound(this.resTotal.manageAveIncome / this.resTotal.resMeasureFacility * 1000, 1);
      }
      // 作業専任平均年齢 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // 作業専任平均年齢 合計÷除容積建設備能力 合計×1000
        res.workAveAge = Util.orgRound(this.resTotal.workAveAge / this.resTotal.resMeasureFacility * 1000, 100);
      }
      // 事務平均年齢 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // 事務平均年齢 合計÷除容積建設備能力 合計×1000
        res.clericalAveAge = Util.orgRound(this.resTotal.clericalAveAge / this.resTotal.resMeasureFacility * 1000, 100);
      }
      // 作業専任平均勤続年数 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // 作業専任平均勤続年数 合計÷除容積建設備能力 合計×1000
        res.workAveContinuous = Util.orgRound(this.resTotal.workAveContinuous / this.resTotal.resMeasureFacility * 1000, 100);
      }
      // 事務平均勤続年数 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // 事務平均勤続年数 合計÷除容積建設備能力 合計×1000
        res.clericalAveContinuous = Util.orgRound(this.resTotal.clericalAveContinuous / this.resTotal.resMeasureFacility * 1000, 100);
      }
      // 下請現場人数 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // 下請現場人数 合計÷除容積建設備能力 合計×1000
        res.consignWorkNum = Util.orgRound(this.resTotal.consignWorkNum / this.resTotal.resMeasureFacility * 1000, 1);
      }
      // 下請事務人数 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // 下請事務人数 合計÷除容積建設備能力 合計×1000
        res.consignClericalNum = Util.orgRound(this.resTotal.consignClericalNum / this.resTotal.resMeasureFacility * 1000, 1);
      }
      // 下請現場賃金 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // 下請現場賃金 合計÷除容積建設備能力 合計×1000
        res.consignWorkAveIncome = Util.orgRound(this.resTotal.consignWorkAveIncome / this.resTotal.resMeasureFacility * 1000, 1);
      }
      // 下請事務賃金 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // 下請事務賃金 合計÷除容積建設備能力 合計×1000
        res.consignClericalAveIncome = Util.orgRound(this.resTotal.consignClericalAveIncome / this.resTotal.resMeasureFacility * 1000, 1);
      }
      // 契約電力 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // 契約電力 合計÷除容積建設備能力 合計×1000
        res.contractElectricity = Util.orgRound(this.resTotal.contractElectricity / this.resTotal.resMeasureFacility * 1000, 1);
      }
      // 電力使用量 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // 電力使用量 合計÷除容積建設備能力 合計×1000
        res.usageElectricity = Util.orgRound(this.resTotal.usageElectricity / this.resTotal.resMeasureFacility * 1000, 1);
      }
      // 電力支払額 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // 電力支払額 合計÷除容積建設備能力 合計×1000
        res.paymentElectricity = Util.orgRound(this.resTotal.paymentElectricity / this.resTotal.resMeasureFacility * 1000, 1);
      }
      // 業務損益 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // 業務損益 合計÷除容積建設備能力 合計×1000
        res.profitAndLoss = Util.orgRound(this.resTotal.profitAndLoss / this.resTotal.resMeasureFacility * 1000, 1);
      }
      // バース数 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // バース数 合計÷除容積建設備能力 合計×1000
        res.numDock = Util.orgRound(this.resTotal.numDock / this.resTotal.resMeasureFacility * 1000, 1);
      }
      // 取引先数 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // 取引先数 入力済み件数
        res.numClient = this.answerDataList.reduce((cnt, e) => {
          return cnt + (e.numClient != null ? 1 : 0);
        }, 0);
      }
      // 新規数 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // 新規数 入力済み件数
        res.numNewClient = this.answerDataList.reduce((cnt, e) => {
          return cnt + (e.numNewClient != null ? 1 : 0);
        }, 0);
      }
      // 使用パレット 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // 使用パレット 合計÷除容積建設備能力 合計×1000
        res.usagePallet = Util.orgRound(this.resTotal.usagePallet / this.resTotal.resMeasureFacility * 1000, 1);
      }
      // 実在庫量 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // 実在庫量 合計÷除容積建設備能力 合計×1000
        res.stockPallet = Util.orgRound(this.resTotal.stockPallet / this.resTotal.resMeasureFacility * 1000, 1);
      }
      // 年間入庫量1000設備㌧ 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // 入一般 合計÷除容積建設備能力 合計×1000
        res.resInFacility = Util.orgRound(this.resTotal.inValueGeneral / this.resTotal.resMeasureFacility * 1000, 1);
      }
      // 年間出庫量1000設備㌧ 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // 出一般 合計÷除容積建設備能力 合計×1000
        res.resOutFacility = Util.orgRound(this.resTotal.outValueGeneral / this.resTotal.resMeasureFacility * 1000, 1);
      }
      // 前月繰越数量1000設備㌧ 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // 前月繰越 合計÷除容積建設備能力 合計×1000
        res.resPrevFacility = Util.orgRound(this.resTotal.prevValueGeneral / this.resTotal.resMeasureFacility * 1000, 10);
      }
      // 中間繰越数量1000設備㌧ 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // 中間繰越 合計÷除容積建設備能力 合計×1000
        res.resInterFacility = Util.orgRound(this.resTotal.interValueGeneral / this.resTotal.resMeasureFacility * 1000, 10);
      }
      // 在庫率年平均% 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // (前月繰越 合計+中間繰越 合計)÷2÷除容積建設備能力 合計×100
        res.resRateStockYearAve = Util.orgRound((this.resTotal.prevValueGeneral + this.resTotal.interValueGeneral) / 2 / this.resTotal.resMeasureFacility * 100, 100);
      }
      // 回転数年間(回) 1000設備㌧当り
      if(this.resTotal.resStockPrevInterAve != 0) {
        // (入一般 合計+出一般 合計)÷2÷'(前月+中間)/2平均在庫 合計'
        res.resTurnoverYear = Util.orgRound((this.resTotal.inValueGeneral + this.resTotal.outValueGeneral) / 2 / this.resTotal.resStockPrevInterAve, 10);
      }
      // (前月+中間)/2平均在庫 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // '(前月+中間)/2平均在庫 合計'÷除容積建設備能力 合計×1000
        res.resStockPrevInterAve = Util.orgRound(this.resTotal.resStockPrevInterAve / this.resTotal.resMeasureFacility * 1000, 10);
      }
      // 他作業料一(￥/設備㌧) 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // その他作業料合計 合計÷除容積建設備能力 合計×1000
        res.resChargeWork = Util.orgRound(this.resTotal.workValueTotal / this.resTotal.resMeasureFacility * 1000, 1);
      }
      // 他手数一般(￥/設備㌧) 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // その他手数料一般 合計÷除容積建設備能力 合計×1000
        res.resChargeGeneral = Util.orgRound(this.resTotal.chargeValueGeneral / this.resTotal.resMeasureFacility * 1000, 1);
      }
      // 流通加工(￥/設備㌧) 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // 流通加工 合計÷除容積建設備能力 合計×1000
        res.resChargeProcessing = Util.orgRound(this.resTotal.processingValueTotal / this.resTotal.resMeasureFacility * 1000, 1);
      }
      // 利用運送(￥/設備㌧) 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // 利用運送 合計÷除容積建設備能力 合計×1000
        res.resChargeTransport = Util.orgRound(this.resTotal.transportValueTotal / this.resTotal.resMeasureFacility * 1000, 1);
      }
      // 保管積数(kg/設備㌧) 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // (入一般 合計+(前月繰越 合計+中間繰越 合計)×12)÷除容積建設備能力 合計×1000
        res.resStorageNum = Util.orgRound((this.resTotal.inValueGeneral + (this.resTotal.prevValueGeneral + this.resTotal.interValueGeneral) * 12) / this.resTotal.resMeasureFacility * 1000, 1);
      }
      // 荷役積数(kg/設備㌧) 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // 入一般 合計×2÷除容積建設備能力 合計×1000
        res.resCargoNum = Util.orgRound(this.resTotal.inValueGeneral * 2 / this.resTotal.resMeasureFacility * 1000, 1);
      }
      // 設備㌧収入小計(\/設備㌧) 1000設備㌧当り
      // 保管料一般 1000設備㌧当り+荷役料一般 1000設備㌧当り
      res.resIncomeFacility = res.storageValueGeneral + res.cargoValueGeneral;
      // 現場従業員1000設備㌧ 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // (作業パート人数 合計+作業専任人数 合計+下請現場人数 合計)÷除容積建設備能力 合計×1000
        res.resWorkTotal = Util.orgRound((this.resTotal.workPartNum + this.resTotal.workNum + this.resTotal.consignWorkNum) / this.resTotal.resMeasureFacility * 1000, 100);
      }
      // (現場社員)1000設備㌧ 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // (作業パート人数 合計+作業専任人数 合計)÷除容積建設備能力 合計×1000
        res.resWorkEmployee = Util.orgRound((this.resTotal.workPartNum + this.resTotal.workNum) / this.resTotal.resMeasureFacility * 1000, 100);
      }
      // (現場委託)1000設備㌧ 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // 下請現場人数 合計÷除容積建設備能力 合計×1000
        res.resWorkConsign = Util.orgRound(this.resTotal.consignWorkNum / this.resTotal.resMeasureFacility * 1000, 100);
      }
      // 事務所従業員1000設備㌧ 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // (事務パート人数 合計+事務人数 合計+下請事務人数 合計)÷除容積建設備能力 合計×1000
        res.resClericalTotal = Util.orgRound((this.resTotal.clericalPartNum + this.resTotal.clericalNum + this.resTotal.consignClericalNum) / this.resTotal.resMeasureFacility * 1000, 100);
      }
      // (事務社員)1000設備㌧ 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // (事務パート人数 合計+事務人数 合計)÷除容積建設備能力 合計×1000
        res.resClericalEmployee = Util.orgRound((this.resTotal.clericalPartNum + this.resTotal.clericalNum) / this.resTotal.resMeasureFacility * 1000, 100);
      }
      // (事務委託)1000設備㌧ 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // 下請事務人数 合計÷除容積建設備能力 合計×1000
        res.resClericalConsign = Util.orgRound(this.resTotal.consignClericalNum / this.resTotal.resMeasureFacility * 1000, 100);
      }
      // 社員管理者1000設備㌧ 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // 社員管理者 合計÷除容積建設備能力 合計×1000
        res.resManageEmployee = Util.orgRound(this.resTotal.manageNum / this.resTotal.resMeasureFacility * 1000, 100);
      }
      // 従業員合計1000設備㌧ 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // (社員合計 合計+下請現場人数 合計+下請事務人数 合計)÷除容積建設備能力 合計×1000
        res.resWorkerTotal = Util.orgRound((this.resTotal.employeeTotalNum + this.resTotal.consignWorkNum + this.resTotal.consignClericalNum) / this.resTotal.resMeasureFacility * 1000, 100);
      }
      // (社員計)1000設備㌧ 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // 社員合計 合計÷除容積建設備能力 合計×1000
        res.resEmployeeTotal = Util.orgRound(this.resTotal.employeeTotalNum / this.resTotal.resMeasureFacility * 1000, 100);
      }
      // (外部委託計)1000設備㌧ 1000設備㌧当り
      if(this.resTotal.resMeasureFacility != 0) {
        // (下請現場人数 合計+下請事務人数 合計)÷除容積建設備能力 合計×1000
        res.resConsignTotal = Util.orgRound((this.resTotal.consignWorkNum + this.resTotal.consignClericalNum) / this.resTotal.resMeasureFacility * 1000, 100);
      }
      // 一人当り売上(千円/人) 1000設備㌧当り
      if(res.resWorkerTotal != 0) {
        // 収入合計一般 1000設備㌧当り÷従業員合計1000設備㌧ 1000設備㌧当り
        res.resSalesPerson = Util.orgRound(res.resIncomeValueGeneral / res.resWorkerTotal, 1);
      }
      // 一人当り扱量(t/年人) 1000設備㌧当り
      if(res.resWorkerTotal != 0) {
        // (入一般 1000設備㌧当り+出一般 1000設備㌧当り)÷従業員合計1000設備㌧ 1000設備㌧当り
        res.resTonPerson = Util.orgRound((res.inValueGeneral + res.outValueGeneral) / res.resWorkerTotal, 1);
      }
      // 現場従業員一人当り扱量1 1000設備㌧当り
      if(res.resWorkTotal != 0) {
        // (入一般 1000設備㌧当り+出一般 1000設備㌧当り)÷現場従業員1000設備㌧ 1000設備㌧当り
        res.resTonWork1 = Util.orgRound((res.inValueGeneral + res.outValueGeneral) / res.resWorkTotal, 1);
      }
      // 1従業員当り設備㌧ 1000設備㌧当り
      if(this.resTotal.resWorkerTotal != 0) {
        // 1000÷従業員合計1000設備㌧ 1000設備㌧当り
        res.resFacilityWork1 = Util.orgRound(1000 / res.resWorkerTotal, 1);
      }
      // 契約電力設備㌧ 1000設備㌧当り
      if(this.resTotal.resVolumeFacility != 0) {
        // 契約電力 合計÷所管容積設備㌧ 合計×1000
        res.resContractElectricityFacility = Util.orgRound(this.resTotal.contractElectricity / this.resTotal.resVolumeFacility * 1000, 10);
      }
      // 電力使用量設備㌧ 1000設備㌧当り
      if(this.resTotal.resVolumeFacility != 0) {
        // 電力使用量 合計÷所管容積設備㌧ 合計×1000
        res.resUsageElectricityFacility = Util.orgRound(this.resTotal.usageElectricity / this.resTotal.resVolumeFacility * 1000, 1);
      }
      // 契約電力有効率 1000設備㌧当り
      if(this.resTotal.contractElectricity != 0) {
        // 電力使用量 合計÷(契約電力 合計×24×365)×100
        res.resRateElectricity = Util.orgRound(this.resTotal.usageElectricity / (this.resTotal.contractElectricity * 24 * 365) * 100, 10);
      }
      // 電力支払額設備㌧ 1000設備㌧当り
      if(this.resTotal.resVolumeFacility != 0) {
        // 電力支払額 合計÷所管容積設備㌧ 合計×1000
        res.resPaymentElectricityFacility = Util.orgRound(this.resTotal.paymentElectricity / this.resTotal.resVolumeFacility * 1000, 1);
      }
      // KWH単価 1000設備㌧当り
      if(this.resTotal.usageElectricity != 0) {
        // 電力支払額 合計×1000÷電力使用量 合計
        res.resUnitPriceElectricity = Util.orgRound(this.resTotal.paymentElectricity * 1000 / this.resTotal.usageElectricity, 10);
      }
      // 業務損益設備㌧ 1000設備㌧当り
      if(this.resTotal.resVolumeFacility != 0) {
        // 業務損益 合計÷所管容積設備㌧ 合計×1000
        res.resProfitAndLossFacility = Util.orgRound(this.resTotal.profitAndLoss / this.resTotal.resVolumeFacility * 1000, 1);
      }
      // 1P当重量kg 1000設備㌧当り
      if(this.resTotal.resVolumeFacility != 0) {
        // 1P当重量kg 入力済み件数
        res.resWeightPerPiece = this.answerDataList.reduce((cnt, e) => {
          return cnt + (e.resWeightPerPiece != null ? 1 : 0);
        }, 0);
      }
      // 貨物比重 1000設備㌧当り
      if(this.resTotal.resVolumeFacility != 0) {
        // 貨物比重 入力済み件数
        res.resWeight = this.answerDataList.reduce((cnt, e) => {
          return cnt + (e.resWeight != null ? 1 : 0);
        }, 0);
      }
      // バース数1000設備㌧ 1000設備㌧当り
      if(this.resTotal.resVolumeFacility != 0) {
        // バース数 合計÷所管容積設備㌧ 合計×1000
        res.resNumDockFacility = Util.orgRound(this.resTotal.numDock / this.resTotal.resVolumeFacility * 1000, 10);
      }

      return res;
    },
  }
};
</script>

<style scoped>

.disablecolor {
  background: #bbbbbb;
}

.value-align {
    display: grid;
    justify-items: right;
    align-content: center;
    font-size: 12pt;
}

/** テキストフィールド */
.v-text-field {
    padding-top: 6px;
}
.v-input >>> .v-input__slot {
    margin-bottom: 2px;
}

/* ホバー効果を無効化 */
.no-hover-effect >>> tr:hover {
  background-color: transparent !important;
}

/** テーブルの縦横固定 */
.v-data-table >>> th, td {
  padding-left: 10px !important;
  padding-right: 10px !important;
  overflow-wrap: break-word;
  word-break: keep-all;
}
.v-data-table >>> td {
  height: 40px !important;
}
.v-data-table >>> td > div{
  height: 60px !important;
}
.v-data-table--fixed-header >>> th:nth-child(1) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0;
    top: 0;
    z-index: 3;
}
.v-data-table--fixed-header >>> .tr-data td:nth-child(1) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0;
    z-index: 2;
}
.v-data-table--fixed-header >>> .tr-data.enable td:nth-child(1) {
    background: #ffffff;
}
.v-data-table--fixed-header >>> th:nth-child(2) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 60px;
    top: 0;
    z-index: 3;
}
.v-data-table--fixed-header >>> .tr-data td:nth-child(2) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 60px;
    z-index: 2;
}
.v-data-table--fixed-header >>> .tr-data.enable td:nth-child(2) {
    background: #ffffff;
}
.v-data-table--fixed-header >>> th:nth-child(3) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 120px;
    top: 0;
    z-index: 3;
}
.v-data-table--fixed-header >>> .tr-data td:nth-child(3) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 120px;
    z-index: 2;
}
.v-data-table--fixed-header >>> .tr-data.enable td:nth-child(3) {
    background: #ffffff;
}
.v-data-table--fixed-header >>> th:nth-child(4) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 180px;
    top: 0;
    z-index: 3;
}
.v-data-table--fixed-header >>> .tr-data td:nth-child(4) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 180px;
    z-index: 2;
}
.v-data-table--fixed-header >>> .tr-data.enable td:nth-child(4) {
    background: #ffffff;
}
.v-data-table--fixed-header >>> th:nth-child(5) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 260px;
    top: 0;
    z-index: 3;
}
.v-data-table--fixed-header >>> .tr-data td:nth-child(5) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 260px;
    z-index: 2;
}
.v-data-table--fixed-header >>> .tr-data.enable td:nth-child(5) {
    background: #ffffff;
}
.v-data-table--fixed-header >>> th:nth-child(6) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 340px;
    top: 0;
    z-index: 3;
}
.v-data-table--fixed-header >>> .tr-data td:nth-child(6) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 340px;
    z-index: 2;
}
.v-data-table--fixed-header >>> .tr-data.enable td:nth-child(6) {
    background: #ffffff;
}
.v-data-table--fixed-header >>> th:nth-child(7) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 490px;
    top: 0;
    z-index: 3;
}
.v-data-table--fixed-header >>> .tr-data td:nth-child(7) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 490px;
    z-index: 2;
}
.v-data-table--fixed-header >>> .tr-data.enable td:nth-child(7) {
    background: #ffffff;
}
/* 合計 */
.v-data-table--fixed-header >>> .tr-total td {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0;
    /* bottom: 132px; */
    bottom: 124px;
    z-index: 2;
    background: #ffffff;
    text-align: end;
}
.v-data-table--fixed-header >>> .tr-total td:nth-child(1) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0;
    z-index: 3;
}
.v-data-table--fixed-header >>> .tr-total .td-blank {
    bottom: 0;
}
/* 平均 */
.v-data-table--fixed-header >>> .tr-ave td {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0;
    /* bottom: 66px; */
    bottom: 62px;
    z-index: 2;
    background: #ffffff;
    text-align: end;
}
.v-data-table--fixed-header >>> .tr-ave td:nth-child(1) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0;
    z-index: 3;
}
/* 1000設備㌧当り */
.v-data-table--fixed-header >>> .tr-facility td {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0;
    bottom: 0;
    z-index: 2;
    background: #ffffff;
    text-align: end;
}
.v-data-table--fixed-header >>> .tr-facility td:nth-child(1) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0;
    z-index: 3;
}

</style>
